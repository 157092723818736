import { Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import * as React from 'react'
import { turnFirstLetterToUpperCase } from '../../utils/utils'

export const Tag = ({
  tag,
  handleRemoveTag,
}: {
  tag: string
  handleRemoveTag?: (tag: string) => void
}) => {
  return (
    <Button variant="roundedInfo" style={{ padding: '4px 8px' }}>
      {turnFirstLetterToUpperCase(tag)}
      {handleRemoveTag && (
        <CloseIcon
          onClick={(e) => {
            const tag =
              e.currentTarget.attributes.getNamedItem('custom-tag')?.value
            if (tag) {
              handleRemoveTag(tag)
            }
          }}
          style={{ cursor: 'pointer' }}
          custom-tag={tag}
          fontSize="small"
        />
      )}
    </Button>
  )
}
