import { Button, CircularProgress, Tab, Tabs, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { Entry } from 'contentful'
import * as React from 'react'
import { useMutation } from '@tanstack/react-query'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import { queryClient } from '../..'
import { sendRejection, setMailSent } from '../../api/api'
import { IKandidatFields } from '../../schema/generated/contentful'
import TagMenuComponent from '../styledMisc/TagMenuComponent'
import Curriculum from '../utlysning/application/Application'
import AddNewNote from './notes/AddNewNote'
import NotesView from './notes/NotesView'
import HistoryView from './history/HistoryView'
import SendMailDialog, {
  SEND_MAIL_DLG_CONTEXT,
} from '../utlysning/sendMail/SendMailDialog'
import { AlertContext } from '../Alert/AlertContext'
import AlertAction, { AlertTypes } from '../Alert/AlertAction'
import { TagsView } from '../styledMisc/TagsView'
import { deleteCandidate } from '../../api/candidate'
import { CandidateInformationView } from './CandidateInformationView'
import { CandidateProfileModalActions } from './CandidateProfileModalActions'

/**
 * CandidateProfileModal props
 */
interface CandidateProfileModalProps {
  // The candidate who's profile is being displayed
  candidate: Entry<IKandidatFields>
  // Closing function
  handleClose: (open: boolean) => void
  // Tags from job application
  jobApplicationTags: string[]
  applicationTitle: string
  applicationStages: string[]
  utlysningId: string
}

/**
 *
 * @param props - The component props, such as the candidate to display information of, whether or not the component is open and a function for closing it
 * @returns A profile component displaying different information from and about the applicant
 */
const CandidateProfileModal: React.FunctionComponent<CandidateProfileModalProps> =
  ({
    candidate,
    handleClose,
    jobApplicationTags,
    applicationTitle,
    applicationStages,
    utlysningId,
  }: CandidateProfileModalProps) => {
    const [, dispatch] = React.useContext(AlertContext)
    const [toggleMailDialog, setToggleMailDialog] = React.useState(false)
    const [openDeleteCandidateModal, setOpenDeleteCandidateModal] =
      React.useState(false)
    const [tabValue, setTabValue] = React.useState(0)
    const [mailDlgContext, setMailDlgContext] = React.useState(
      SEND_MAIL_DLG_CONTEXT.REJECTION
    )

    /**
     * Mutates the candidate, set email sent
     */
    const emailSentMutation = useMutation(
      (candidateID: string) => setMailSent(candidateID),
      {
        onMutate: async () => {
          await queryClient.cancelQueries(['utlysning', utlysningId])
        },
        onSuccess: async () => {
          await queryClient.invalidateQueries(['utlysning', utlysningId])
        },
      }
    )

    /**
     * Mutates the candidate to the 'rejection' stage
     */
    const rejectionMutation = useMutation(
      (candidateID: string) => sendRejection(candidateID),
      {
        onError: () => {
          dispatch(
            AlertAction.showAlert(
              'Det oppstod en feil under sending av avslag',
              AlertTypes.error
            )
          )
        },
        onSuccess: async () => {
          await queryClient.invalidateQueries(['utlysning', utlysningId])
          dispatch(
            AlertAction.showAlert(
              'Kandidaten har blitt sendt til avslag',
              AlertTypes.success
            )
          )
        },
      }
    )

    /**
     * Deletes the candidate from contentful
     */
    const deleteCandidateMutation = useMutation(
      (candidate: Entry<IKandidatFields>) =>
        deleteCandidate(candidate.sys.id, utlysningId),
      {
        onError: (error, variables) => {
          dispatch(
            AlertAction.showAlert(
              `Det oppstod en feil ved sletting av "${variables.fields.name}", prøv igjen.`,
              AlertTypes.error
            )
          )
        },
        onSuccess: async (data, variables) => {
          await queryClient.invalidateQueries(['utlysning', utlysningId])
          dispatch(
            AlertAction.showAlert(
              `Kandidaten "${variables.fields.name}" har blitt slettet`,
              AlertTypes.success
            )
          )
        },
      }
    )

    /**
     * Sends the applicant to the 'rejection' stage.
     * Sets loading to be true, and after the muation has settled, changes the loading to false again
     */
    const applyRejection = () => {
      // TODO: Add loading handling when this fires off
      rejectionMutation.mutateAsync(candidate.sys.id)
    }

    const handleSendMailClose = (
      res?:
        | undefined
        | {
            name: string
            candidateId: string
            emailSent: boolean
            email: string
          }[]
    ) => {
      setToggleMailDialog(false)
      if (res) {
        const mailSent$ = res
          ?.filter((r) => r.emailSent === true)
          .map((c) => {
            return emailSentMutation.mutateAsync(c.candidateId)
          })
        if (mailSent$) {
          Promise.all(mailSent$).then(() => {
            if (mailDlgContext === SEND_MAIL_DLG_CONTEXT.REJECTION) {
              applyRejection()
            }
          })
        }
      }
    }

    const handleSaveChangesAndClose = async () => {
      handleClose(false)
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue)
    }

    function getTabValue() {
      switch (tabValue) {
        case 0:
          return (
            <Curriculum
              candidate={candidate}
              applicationTitle={applicationTitle}
            />
          )
        case 1:
          return <HistoryView candidate={candidate} />
        case 2:
          return <NotesView candidate={candidate} utlysningId={utlysningId} />
        default:
          return <div>Ikke implementert</div>
      }
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleCloseMoreMenu = () => {
      setAnchorEl(null)
    }

    return (
      <div>
        <Modal
          open
          onClose={handleSaveChangesAndClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              height: '100vh',
              width: '80%',
              bgcolor: '#EAEAEA',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            }}
          >
            <Box
              sx={{
                bgcolor: '#F9F9F9',
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'space-between',
                boxSizing: 'border-box',
                padding: '20px',
              }}
            >
              <Typography
                sx={{
                  color: '#33373D',
                  fontSize: '24px',
                  fontWeight: 'bold',
                }}
              >
                {candidate.fields.name}
              </Typography>
              <CandidateInformationView candidate={candidate} />

              <CandidateProfileModalActions
                candidate={candidate}
                handleClick={handleClick}
                open={open}
                setMailDlgContext={setMailDlgContext}
                setToggleMailDialog={setToggleMailDialog}
                anchorEl={anchorEl}
                handleCloseMoreMenu={handleCloseMoreMenu}
                applicationStages={applicationStages}
                setOpenDeleteCandidateModal={setOpenDeleteCandidateModal}
              />
              <Box sx={{ display: 'flex' }}>
                <TagsView candidate={candidate} utlysningId={utlysningId} />
                <TagMenuComponent
                  jobApplicationTags={jobApplicationTags}
                  utlysningId={utlysningId}
                  candidate={candidate}
                />
              </Box>
            </Box>
            <Box
              sx={{
                background: '#F9F9F9',
                height: '80vh',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{
                  background: 'transparent',
                  right: 0,
                  position: 'absolute',
                  marginTop: '-48px',
                }}
                indicatorColor="primary"
              >
                <Tab label="Søknad" />
                <Tab label="Historikk" />
                <Tab label="Notater" />
              </Tabs>
              <Box
                sx={{
                  height: '100%',
                  position: 'relative',
                }}
              >
                <Box
                  style={{
                    boxSizing: 'border-box',
                    padding: '32px',
                    background: '#eaeaea',
                    height: '100%',
                    minHeight: '900px',
                    overflowY: 'scroll',
                  }}
                >
                  {tabValue > 0 && (
                    <>
                      <Typography variant="h5">
                        {['Søknad', 'Historikk', 'Notater'][tabValue]}
                      </Typography>
                    </>
                  )}
                  {getTabValue()}
                </Box>
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <AddNewNote candidate={candidate} utlysningId={utlysningId} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
        <SendMailDialog
          open={toggleMailDialog}
          candidatesIds={[candidate.sys.id]}
          handleSendMailClose={handleSendMailClose}
          context={mailDlgContext}
        />
        <Modal
          open={openDeleteCandidateModal}
          onClose={() => setOpenDeleteCandidateModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute' as const,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxHeight: '80vh',
              width: '50vw',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              p: 4,
              overflow: 'scroll',
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Er du sikker på at du vil slette denne kandidaten?
            </Typography>
            <Box
              sx={{
                width: '100%',
                border: '2px solid grey',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                margin: 1,
                boxSizing: 'border-box',
              }}
            >
              <Typography id="modal-modal-description">
                {candidate.fields.name}
              </Typography>
              {!candidate.fields.preservePersonalDataAllowed && (
                <Tooltip title="Denne brukeren ønsker ikke at vi lagrer hens data lengre enn nødvendig. Slett kandidaten i contentful etter kandidaten er ferdig behandlet.">
                  <PrivacyTipIcon
                    sx={{
                      cursor: 'default',
                      color: '#ff9800',
                    }}
                  />
                </Tooltip>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                gap: 5,
                py: 2,
              }}
            >
              <Button
                variant="contained"
                onClick={async () => {
                  await deleteCandidateMutation.mutateAsync(candidate)
                  setOpenDeleteCandidateModal(false)
                  handleClose(false)
                }}
                color="error"
                disabled={deleteCandidateMutation.isLoading}
              >
                {deleteCandidateMutation.isLoading ? (
                  <CircularProgress size={12} />
                ) : (
                  <>Slett kandidaten</>
                )}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenDeleteCandidateModal(false)
                }}
                color="secondary"
              >
                Gå tilbake
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    )
  }

export default CandidateProfileModal
