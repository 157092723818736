import * as React from 'react'
import { styled } from '@mui/material/styles'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'

const Icon = styled('span')(() => ({
  borderRadius: 4,
  width: 14,
  height: 14,
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#F8F9FA',
  'input:hover ~ &': {
    backgroundColor: '#AAB2BD',
  },
}))

const CheckedIcon = styled(Icon)({
  backgroundColor: '#5D719D',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 14,
    height: 14,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#5D719D',
  },
})

/**
 *
 * @param props - Checkbox props imported from MUI
 * @returns A custom styled checkbox
 */
const CustomCheckbox: React.FunctionComponent<CheckboxProps> = (
  props: CheckboxProps
) => {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      color="default"
      checkedIcon={<CheckedIcon />}
      icon={<Icon />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

export default CustomCheckbox
