/* eslint-disable no-param-reassign */
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import client from '../client'
import googleAuthToken from '../interfaces/googleAuthToken'
import { writableClient } from '../writableClient'
import { BASE_URL } from './api'

/**
 * Gets all employees from Contentful
 * @returns An array of all employees
 */
export const getAllEmployees = async () => {
  const employees = await client.getEntries<any>({
    content_type: 'employee',
  })
  const modifiedEmployees = employees.items.map((employee: any) => {
    return {
      name: employee.fields.name,
      accessToJobApplications: employee.fields.accessToJobApplications,
      profilePicture: employee.fields.profilePicture,
      id: employee.sys.id,
    }
  })
  return modifiedEmployees
}

/**
 * Change the access to job applications for an employee
 * @param employeeID - the id of the employee
 */
export const changeEmployeAccessToApplication = async (employeeID: string) => {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(employeeID))
    .then((entry) => {
      if (!entry.fields.accessToJobApplications) {
        entry.fields.accessToJobApplications = {}
        entry.fields.accessToJobApplications['en-US'] = false
      }
      entry.fields.accessToJobApplications['en-US'] =
        !entry.fields.accessToJobApplications['en-US']
      return entry.update()
    })
    .then((entry) => entry.publish())
}

/**
 * Gets the history of the candidate
 * @param id - The id of the candidate
 * @returns The candidate history log
 */
export const getResponsiblePersons = async (): Promise<
  { name: string; email: string }[]
> => {
  const { data } = await axios.get(
    `${BASE_URL}/entries?access_token=${process.env.REACT_APP_ACCESS_TOKEN}&content_type=employee`
  )

  return data.items.map((i: { fields: { name: string; email: string } }) => {
    return {
      label: i.fields.name,
      name: i.fields.name,
      email: i.fields.email,
    }
  })
}

/**
 * Updates the password of a employee
 * @param id - the Id of the employee
 * @param password - the password
 */
const updatePasswordOnEmployee = async (id: string, password: string) => {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(id))
    .then((entry) => {
      if (!entry.fields.passwordForJobApplication) {
        entry.fields.passwordForJobApplication = {}
      }
      entry.fields.passwordForJobApplication['en-US'] = password
      return entry.update()
    })
    .then((entry) => entry.publish())
}

/**
 * Gets a specific candidate from the API using the candidate's id
 * @param id - The id of the candidate
 * @returns A candidate that matches the param id
 */
export const updateEmployeeAppPwd = async (password: string) => {
  const token = window.localStorage.getItem('GoogleAuth') || ''
  const payload = jwtDecode<googleAuthToken>(token)
  const loggedInUserEmail = payload.email
  const res = await axios.get(
    `${BASE_URL}/entries?access_token=${process.env.REACT_APP_ACCESS_TOKEN}&fields.email=${loggedInUserEmail}&content_type=employee`
  )
  const { id } = res.data.items[0].sys
  updatePasswordOnEmployee(id, password)
}
