import { Box, Typography } from '@mui/material'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { getEmailTemplates } from '../api/email'
import { headCellsTemplates } from '../components/styledMisc/table/HeadCells'
import TemplateTable from '../components/styledMisc/table/TemplateTable'
import { LoadingOrError } from '../components/LoadingOrError'

/**
 *
 * @returns The email templates page
 */
const Maler: React.FunctionComponent = () => {
  const {
    data: templates,
    isLoading,
    isError,
  } = useQuery(['templates'], getEmailTemplates)

  return (
    <LoadingOrError isLoading={isLoading} isError={isError}>
      <main>
        <Typography
          sx={{ display: { xs: 'none', sm: 'block' } }}
          style={{
            fontWeight: 900,
            fontSize: '24px',
            padding: '1rem 0',
            color: '#33373D',
            margin: '0 auto',
            width: '80%',
          }}
        >
          Maler
        </Typography>
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
            padding: '1rem',
            margin: '0 auto',
            width: '80%',
          }}
        >
          {templates?.items && (
            <TemplateTable
              templates={templates.items}
              headCells={headCellsTemplates}
            />
          )}
        </Box>
      </main>
    </LoadingOrError>
  )
}

export default Maler
