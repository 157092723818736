import React from 'react'
import { Entry } from 'contentful'
import { makeStyles } from '@mui/styles'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Link, useNavigate } from 'react-router-dom'
import Box from '@mui/system/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Typography } from '@mui/material'
import ApplicantBox from './ApplicantBox'
import DuplicateBox from './DuplicateBox'
import EndBox from './EndBox'
import { ReactComponent as OpenNewLink } from '../../assets/icons/openNewLink.svg'
import JobPostingInformation from './JobPostingInformation'
import { IJobapplicationContentFields } from '../../schema/generated/contentful'

// This method was used in Material UI v4 and should be updated to v5
const useStyles = makeStyles({
  mainboxSize: {
    width: '100%',
    height: 204,
    backgroundColor: '#FFFFFF',
    padding: 24,
    borderRadius: 4,
  },

  maintitle: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '18px',
    marginBottom: 20,
    color: '#111C4B',
    float: 'left',
  },

  tabbar: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },

  iconButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 5,
  },

  activeFont: {
    width: 64,
    height: 25,
    textAlign: 'center',
    lineHeight: '25px',
    backgroundColor: '#DDEDD7',
    color: '#366127',
    borderRadius: '4px',
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: '18px',
    marginBottom: 20,
  },

  popupEditContentful: {
    width: 230,
    height: 54,
  },

  popuptext: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    color: '#33373D',
  },

  threedots: {
    position: 'relative',
    borderRadius: '30px!important',
    height: 28,
  },
})

/**
 * OverviewTabbar props
 */
interface OverviewTabbarProps {
  // Job application
  utlysning: Entry<IJobapplicationContentFields>
}

/**
 * An overview of the job posting
 * @param props - Which job application is being displayed an overview of
 * @returns A component displaying information about a job posting and doubles as a link to the job posting's applicants
 */
const OverviewTabbar = ({ utlysning }: OverviewTabbarProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const isActive = utlysning.fields.shown ?? true

  /**
   * Opens the 'Rediger i Contentful' menu
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Closes the 'Rediger i Contentful' menu
   */
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getStyle = (stage: string) => {
    let widthPercentage =
      ((utlysning.fields.applicants?.filter((c) => c.fields.stage === stage)
        .length || 0) /
        (utlysning.fields.applicants?.length || 1)) *
      100

    let background = 'rgb(225 228 252)'
    let border = '1px solid #c9cffd'
    let color = '#2e2f3d'
    if (widthPercentage === 0) {
      widthPercentage = 100
      background = 'none'
      border = 'none'
      color = '#ccc'
    }
    return {
      width: `${widthPercentage.toString()}%`,
      background,
      border,
      color,
    }
  }

  return (
    <>
      <Card
        sx={{ display: { xs: 'block', sm: 'none' } }}
        onClick={() =>
          navigate(`/utlysninger/${utlysning.sys.id}`, {
            replace: true,
          })
        }
      >
        <CardContent>
          <Typography variant="h2">{utlysning.fields.title}</Typography>

          <div
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: 'inhrit',
              fontSize: '.9em',
              color: 'rgb(0,0,0,.5)',
              textOverflow: 'ellipsis',
              maxWidth: '350px',
              marginBottom: '16px',
              marginTop: '8px',
            }}
          >
            {utlysning.fields.description}
          </div>
          <div
            style={{
              marginTop: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '12px',
              lineHeight: '18px',
            }}
          >
            <div>
              Aktive søkere
              <br />
              <b style={{ fontSize: '1.2em' }}>
                {' '}
                {utlysning.fields.applicants?.length
                  ? utlysning.fields.applicants.filter(
                      (cand) => cand.fields.stage !== 'Avslag'
                    ).length
                  : 0}
              </b>
            </div>
            <div>
              Total ant. søkere
              <br />
              <b style={{ fontSize: '1.2em' }}>
                {utlysning.fields.applicants?.length
                  ? utlysning.fields.applicants.length
                  : 0}
              </b>
            </div>

            <div>
              Frist
              <br />
              <b
                style={{
                  fontSize: '1.2em',
                }}
              >
                {utlysning.fields.deadline
                  ? new Date(utlysning.fields.deadline).toLocaleDateString()
                  : 'N/A'}
              </b>
            </div>
          </div>
          <br />
          <div>
            {utlysning &&
              utlysning?.fields.stages &&
              utlysning?.fields.stages.map((stage) => {
                return (
                  <div
                    key={stage}
                    style={{
                      background: '#f1f1f1',
                      borderRadius: '4px',
                    }}
                  >
                    <div
                      style={{
                        padding: '4px',
                        marginTop: '8px',
                        background: getStyle(stage).background,
                        border: getStyle(stage).border,
                        width: getStyle(stage).width,
                        color: getStyle(stage).color,
                        borderRadius: '4px',
                        fontSize: '.9em',
                      }}
                    >
                      {stage}
                    </div>
                  </div>
                )
              })}
          </div>
        </CardContent>
      </Card>

      <Box
        className={classes.mainboxSize}
        sx={{ display: { xs: 'none', sm: 'block' } }}
      >
        <p className={classes.maintitle}>{utlysning.fields.title}</p>
        <div className={classes.iconButton}>
          <div
            className={`jobApplicationStatusIndicator ${
              isActive ? 'active' : 'inactive'
            }`}
          >
            {isActive ? 'Aktiv' : 'Innaktiv'}
          </div>
          <Box display="flex">
            <IconButton
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              className={classes.threedots}
            >
              <MoreHorizIcon fontSize="large" />
            </IconButton>
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <a
              href={`https://app.contentful.com/spaces/${process.env.REACT_APP_SPACE_ID}/entries/${utlysning.sys.id}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <MenuItem
                className={classes.popupEditContentful}
                onClick={handleClose}
              >
                <p className={classes.popuptext}>
                  Rediger i contentful &nbsp; &nbsp; <OpenNewLink />
                </p>
              </MenuItem>
            </a>
          </Menu>
        </div>
        <Link
          style={{
            textDecoration: 'none',
          }}
          to={`/utlysninger/${utlysning.sys.id}`}
          key={utlysning.sys.id}
        >
          <div className={classes.tabbar}>
            {utlysning &&
              utlysning?.fields.stages &&
              utlysning?.fields.stages.map((stage, index: number) => {
                if (index === 0)
                  return (
                    <ApplicantBox
                      name={stage}
                      amount={
                        utlysning.fields.applicants
                          ? utlysning.fields.applicants?.filter(
                              (cand) => cand.fields.stage === stage
                            ).length
                          : 0
                      }
                      subdetails=""
                      key={stage}
                    />
                  )
                return stage === 'Avslag' ? (
                  <EndBox
                    name={stage}
                    amount={
                      utlysning.fields.applicants
                        ? utlysning.fields.applicants?.filter(
                            (cand) => cand.fields.stage === stage
                          ).length
                        : 0
                    }
                    subdetails="x takket nei"
                    key={stage}
                  />
                ) : (
                  <DuplicateBox
                    name={stage}
                    amount={
                      utlysning.fields.applicants
                        ? utlysning.fields.applicants?.filter(
                            (cand) => cand.fields.stage === stage
                          ).length
                        : 0
                    }
                    subdetails=""
                    key={stage}
                  />
                )
              })}
          </div>
        </Link>
        <JobPostingInformation
          applicants={utlysning.fields.applicants}
          deadlineDate={utlysning.fields.deadline}
        />
      </Box>
    </>
  )
}

export default OverviewTabbar
