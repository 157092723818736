import * as React from 'react'
import Box from '@mui/material/Box'
import { Avatar, CircularProgress, IconButton } from '@mui/material'
import { Entry } from 'contentful'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useMutation } from '@tanstack/react-query'
import EditIcon from '@mui/icons-material/Edit'
import { ContentState, Editor, EditorState } from 'draft-js'
import SaveIcon from '@mui/icons-material/Save'
import { INote } from '../../../interfaces/note.interface'
import { getLoggedInUser } from '../../../api/api'
import { delay, humanizeTimestamp } from '../../../utils/utils'
import { queryClient } from '../../..'
import AlertAction, { AlertTypes } from '../../Alert/AlertAction'
import { AlertContext } from '../../Alert/AlertContext'
import { IKandidatFields } from '../../../schema/generated/contentful'
import { editCandidateNote, removeCandidateNote } from '../../../api/note'

const NoteView = ({
  note,
  candidate,
  utlysningId,
}: {
  note: INote
  candidate: Entry<IKandidatFields>
  utlysningId: string
}) => {
  const [, dispatch] = React.useContext(AlertContext)
  const [isEditing, setIsEditing] = React.useState(false)
  const editor = React.useRef<Editor>(null)
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(ContentState.createFromText(note.content))
  )
  React.useEffect(() => {
    const state = note.content
      ? EditorState.createWithContent(ContentState.createFromText(note.content))
      : EditorState.createEmpty()
    setEditorState(state)
  }, [note])

  function focusEditor() {
    if (editor.current !== null) editor.current.focus()
  }
  const removeNoteMutation = useMutation(
    (newNote: INote) => removeCandidateNote(candidate.sys.id, newNote),
    {
      onSuccess: async () => {
        delay(300).then(async () => {
          await queryClient.invalidateQueries(['utlysning', utlysningId])
        })
        dispatch(
          AlertAction.showAlert(
            `Notat fjernet fra "${candidate.fields.name}".`,
            AlertTypes.success
          )
        )
      },
      onError: async () => {
        dispatch(
          AlertAction.showAlert(
            'En feil oppstod, prøv igjen.',
            AlertTypes.error
          )
        )
      },
    }
  )
  const editNoteMutation = useMutation(
    ({ newNote, oldNote }: { newNote: INote; oldNote: INote }) =>
      editCandidateNote(candidate.sys.id, newNote, oldNote),
    {
      onSuccess: async () => {
        delay(300).then(async () => {
          await queryClient.invalidateQueries(['utlysning', utlysningId])
        })
        dispatch(
          AlertAction.showAlert(`Notatet er endret.`, AlertTypes.success)
        )
      },
      onError: async () => {
        dispatch(
          AlertAction.showAlert(
            'En feil oppstod, prøv igjen.',
            AlertTypes.error
          )
        )
      },
    }
  )
  const noChangesToNote =
    note.content === editorState.getCurrentContent().getPlainText()
  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        paddingX: '32px',
        paddingY: '16px',
        overflow: 'scroll',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', boxSizing: 'border-box' }}>
        <Avatar
          sx={{ height: '56px', width: '56px' }}
          alt={note.userFullname}
          src={note.userPictureURL}
        />
        <Box style={{ paddingLeft: '8px', boxSizing: 'border-box' }}>
          <Box>
            <span
              style={{
                fontWeight: 600,
                fontSize: '14px',
              }}
            >
              {note.userId === getLoggedInUser().id ? 'Deg' : note.userFullname}
            </span>
            &nbsp;
            <span
              style={{
                fontWeight: 400,
                fontSize: '12px',
                color: 'rgba(0,0,0,.7)',
              }}
            >
              {humanizeTimestamp(new Date(note.timestamp))}
            </span>
          </Box>
          {isEditing ? (
            <Box
              onClick={() => focusEditor()}
              sx={{
                marginTop: '8px',
                padding: '8px 16px 8px 16px',
                borderRadius: ' 4px 16px 16px 16px',
                background: '#fff',
                color: 'black',
                border: '1px solid blue',
                whiteSpace: 'pre-line',
                boxSizing: 'border-box',
              }}
            >
              <Editor
                ref={editor}
                editorState={editorState}
                onChange={setEditorState}
                placeholder="Skriv et notat her!"
              />
            </Box>
          ) : (
            <Box
              sx={{
                marginTop: '8px',
                padding: '8px 16px 8px 16px',
                borderRadius: ' 4px 16px 16px 16px',
                background: '#fff',
                color: 'black',
                border: '1px solid #dadada',
                whiteSpace: 'pre-line',
                boxSizing: 'border-box',
              }}
            >
              {note.content}
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        {note.userId === getLoggedInUser().id &&
          (isEditing ? (
            <IconButton
              sx={{ height: '40px', width: '40px' }}
              onClick={async () => {
                if (!noChangesToNote) {
                  await editNoteMutation.mutateAsync({
                    newNote: {
                      ...note,
                      content: editorState.getCurrentContent().getPlainText(),
                    },
                    oldNote: note,
                  })
                } else {
                  dispatch(
                    AlertAction.showAlert(
                      'Ingen endringer ble gjort.',
                      AlertTypes.info
                    )
                  )
                }
                setIsEditing(!isEditing)
              }}
              disabled={editNoteMutation.isLoading}
              color="primary"
            >
              {editNoteMutation.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <SaveIcon />
              )}
            </IconButton>
          ) : (
            <IconButton
              sx={{ height: '40px', width: '40px' }}
              onClick={() => {
                setIsEditing(!isEditing)
                focusEditor()
              }}
              color="secondary"
            >
              <EditIcon />
            </IconButton>
          ))}
        {note.userId === getLoggedInUser().id && (
          <IconButton
            sx={{ height: '40px', width: '40px' }}
            onClick={() => {
              removeNoteMutation.mutateAsync(note)
            }}
            disabled={removeNoteMutation.isLoading}
            color="error"
          >
            {removeNoteMutation.isLoading &&
            removeNoteMutation.variables === note ? (
              <CircularProgress size={24} />
            ) : (
              <DeleteForeverIcon />
            )}
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default NoteView
