export enum AlertTypes {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export default class AlertAction {
  static SHOW_ALERT = 'SHOW_ALERT'

  static showAlert = (message: string, type: AlertTypes) => {
    return {
      type: AlertAction.SHOW_ALERT,
      payload: { message, type },
    }
  }
}
