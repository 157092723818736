import {
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useMutation } from '@tanstack/react-query'
import { Entry } from 'contentful'
import React from 'react'
import { IKandidatFields } from '../../schema/generated/contentful'
import { sendCandidateToStage } from '../../api/api'
import { queryClient } from '../..'

import { AlertContext } from '../Alert/AlertContext'
import AlertAction, { AlertTypes } from '../Alert/AlertAction'
import { delay } from '../../utils/utils'

interface SendToStageMenuProps {
  // The candidate who's profile is being displayed
  candidate: Entry<IKandidatFields>
  applicationStages: string[]
}

const SendToStageMenu: React.FunctionComponent<SendToStageMenuProps> = (
  props: SendToStageMenuProps
) => {
  const { candidate, applicationStages } = props
  const [, dispatch] = React.useContext(AlertContext)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [loadingStage, setLoadingStage] = React.useState(false)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const sendToStageMutation = useMutation(
    (newCandidate: Entry<IKandidatFields>) =>
      sendCandidateToStage(candidate.sys.id, newCandidate.fields.stage),
    {
      onMutate: async (newCandidate: Entry<IKandidatFields>) => {
        await queryClient.cancelQueries(['candidates', newCandidate.sys.id])

        const previousCandidate = queryClient.getQueryData<
          Entry<IKandidatFields>
        >(['candidates', newCandidate.sys.id])
        if (previousCandidate) {
          queryClient.setQueryData(
            ['candidates', newCandidate.sys.id],
            newCandidate
          )
        }
        return { previousCandidate, newCandidate }
      },
      onError: (err, variables, context) => {
        if (context?.previousCandidate) {
          queryClient.setQueryData<Entry<IKandidatFields>>(
            ['candidates', context.newCandidate.sys.id],
            context.previousCandidate
          )
        }
        dispatch(
          AlertAction.showAlert(
            'Det oppstod en feil ved flytting av kandidaten.',
            AlertTypes.error
          )
        )
      },
      onSettled: () => {
        delay(300).then(() => {
          queryClient.invalidateQueries(['candidates']).then(() => {
            dispatch(
              AlertAction.showAlert(
                'Kandidaten ble flyttet.',
                AlertTypes.success
              )
            )
          })
        })
      },
    }
  )

  /**
   * Sends the applicant to a specific stage.
   * Sets loading to be true, and after the muation has settled, changes the loading to false again
   */
  const sendToStage = async (stage: string) => {
    setLoadingStage(true)

    candidate.fields.stage = stage
    await sendToStageMutation.mutateAsync(candidate).then(() => {
      setLoadingStage(false)
    })
  }

  return (
    <>
      <Button
        variant="darkBlueRounded"
        sx={{
          minWidth: 'fit-content',
          '@media (min-width: 1200px)': {
            marginRight: '1rem',
          },
          '@media (max-width: 1200px)': {
            marginBottom: '0.5rem',
          },
        }}
        onClick={handleClick}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <ArrowForwardIcon
          fontSize="small"
          sx={{
            paddingRight: '0.5rem',
          }}
        />
        <Typography sx={{ paddingRight: '0.5rem' }}>Send til steg</Typography>

        {loadingStage ? (
          <CircularProgress size={15} sx={{ color: 'white' }} />
        ) : (
          <KeyboardArrowDownIcon fontSize="small" />
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {applicationStages &&
          applicationStages
            .filter(
              (stage) => stage !== candidate.fields.stage && stage !== 'Avslag'
            )
            .map((stage) => {
              return (
                <MenuItem key={stage} onClick={() => sendToStage(stage)}>
                  {stage}
                </MenuItem>
              )
            })}
      </Menu>
    </>
  )
}

export default SendToStageMenu
