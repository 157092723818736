import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Entry, Asset } from 'contentful'
import React, { useState } from 'react'
import { getAssetFromAPI } from '../../api/api'
import emptyState from '../../assets/misc/emptyState.png'
import { IKandidatFields } from '../../schema/generated/contentful'
import { Tag } from '../tag/Tag'

function CandidateListMobile(props: { candidates: Entry<IKandidatFields>[] }) {
  const { candidates } = props
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const [selectedCandidate, setSelectedCandidate] =
    React.useState<Entry<IKandidatFields>>()

  const [selectedAttachmentForDownload, setSelectedAttachmentForDownload] =
    useState<string>('cv')

  const handleDownload = () => {
    setDialogOpen(false)
    let f$: Promise<Asset | null> = new Promise((r) => r(null))
    const attachmentWindow = window.open()
    if (selectedAttachmentForDownload === 'cv' && selectedCandidate) {
      f$ = getAssetFromAPI(selectedCandidate?.fields.cv.sys.id)
    }

    if (selectedAttachmentForDownload === 'appLetter' && selectedCandidate) {
      f$ = getAssetFromAPI(selectedCandidate?.fields.applicationLetter.sys.id)
    }

    f$.then((res) => {
      if (res && attachmentWindow !== null) {
        attachmentWindow.location = res?.fields.file.url
      }
    })
  }

  const handleDialogOpen = (c: Entry<IKandidatFields>) => {
    setSelectedAttachmentForDownload('cv')
    setSelectedCandidate(c)
    setDialogOpen(true)
  }

  const dialog = (
    <Dialog open={dialogOpen}>
      <DialogTitle sx={{ margin: 0, padding: '16px', fontSize: '16px' }}>
        {selectedCandidate && selectedCandidate.fields.name}
      </DialogTitle>
      <DialogContent
        sx={{
          margin: 0,
          padding: '128px 64px 8px 16px',
          fontSize: '14px',
        }}
      >
        <RadioGroup
          onChange={(ev) => {
            setSelectedAttachmentForDownload(ev.target.value)
          }}
          aria-labelledby="attachment-rd-group-label"
          defaultValue="cv"
          name="attachment-rd-group"
        >
          {selectedCandidate && selectedCandidate.fields.cv?.sys.id && (
            <FormControlLabel
              value="cv"
              control={<Radio />}
              label="Curriculum vitae"
            />
          )}

          {selectedCandidate &&
            selectedCandidate.fields.applicationLetter?.sys.id && (
              <FormControlLabel
                value="appLetter"
                control={<Radio />}
                label="Søknadsbrev"
              />
            )}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            margin: 0,
            padding: '8px',
            fontSize: '12px',
            height: '32px',
            lineHeight: '32px',
          }}
          variant="greyDefault"
          onClick={() => setDialogOpen(false)}
        >
          Avbryt
        </Button>
        <Button
          onClick={handleDownload}
          sx={{
            margin: 0,
            padding: '8px',
            fontSize: '12px',
            height: '32px',
            lineHeight: '32px',
          }}
          variant="darkBlueRounded"
        >
          Last ned
        </Button>
      </DialogActions>
    </Dialog>
  )
  if (!candidates.length) {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          background: `url(${emptyState})`,
          backgroundColor: '#FF69B4',
          backgroundSize: '42px',
        }}
      >
        <div
          style={{
            background: '#AAFF00',
            color: 'black',
            marginTop: '256px',
            boxSizing: 'border-box',
            padding: '64px',
            transform: 'rotate(-16deg)',
          }}
        >
          Ingen fremtidige Finkere her enda :/
        </div>
      </div>
    )
  }
  return (
    <>
      {dialog}
      <List>
        {candidates &&
          candidates
            .sort((x, y) =>
              new Date(x.fields.applicationDate).getTime() >
              new Date(y.fields.applicationDate).getTime()
                ? -1
                : 1
            )
            .map((candidate) => (
              <Box key={candidate.sys.id}>
                {true && console.log(candidate)}
                {true && console.log(candidate.sys.id)}
                <ListItem
                  onClick={() => handleDialogOpen(candidate)}
                  style={{
                    background: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <ListItemText
                    style={{ fontSize: '.8em', marginBottom: '8px' }}
                    primary={
                      <>
                        <b>{candidate.fields.name}</b>
                      </>
                    }
                  />
                  <ListItemText
                    style={{ fontSize: '.8em', marginBottom: '8px' }}
                    primary={
                      <>
                        {candidate.fields.name?.split(' ')[0]} har{' '}
                        {candidate.fields.yearsExperience} års erfaring og ble
                        uteksaminert i {candidate.fields.graduationYear}.
                      </>
                    }
                  />
                  <ListItemText
                    style={{ fontSize: '.8em', marginBottom: '8px' }}
                    primary={
                      <>
                        <span
                          style={{
                            color: '#f0885d',
                          }}
                        >
                          {candidate.fields.stage}
                        </span>
                        &nbsp;&rarr; Søkte{' '}
                        {new Date(
                          candidate.fields.applicationDate
                        ).toLocaleDateString('se-sv')}
                      </>
                    }
                  />
                  <ListItemText
                    primary={
                      <>
                        Saksbehandler:{' '}
                        {candidate.fields.responsiblePerson ?? <em>Ingen</em>}
                      </>
                    }
                  />
                  <div>
                    {candidate.fields.tags?.map((tag: string) => {
                      return <Tag tag={tag} key={`${tag}`} />
                    })}
                  </div>
                </ListItem>
                <Divider />
              </Box>
            ))}
      </List>
    </>
  )
}

export default CandidateListMobile
