import React from 'react'
import jwtDecode from 'jwt-decode' // eslint-disable-line no-eval
import { Box } from '@mui/system'
import LoginStylePage from './LoginStylePage'

declare global {
  interface Window {
    // eslint-disable-next-line
    HandleCredentialResponse: any
    DecodeJwtResponse: void
  }
}
/* eslint-disable camelcase */
interface googleAuthToken {
  aud: string
  azp: string
  email: string
  email_verified: boolean
  exp: number
  family_name: string
  given_name: string
  hd: string
  iat: number
  iss: string
  jti: string
  name: string
  nbf: number
  picture: string
  sub: string
}

function DecodeJwtResponse(credential: string): googleAuthToken {
  const token = credential
  const payload = jwtDecode<googleAuthToken>(token)
  const { given_name, family_name } = payload
  window.localStorage.setItem('loggedInUser', `${given_name} ${family_name}`)
  window.localStorage.setItem('GoogleAuth', credential)
  return payload
}

class LoginPage extends React.Component {
  constructor(props: string) {
    super(props)
    window.HandleCredentialResponse = this.HandleCredentialResponse
  }

  // eslint-disable-next-line
  HandleCredentialResponse(response: { credential: string }) {
    const responsePayload = DecodeJwtResponse(response.credential)
    const { hd } = responsePayload
    const emailVerified = responsePayload.email_verified
    if (hd === 'fink.no' && emailVerified) {
      window.location.reload()
    }
  }

  render() {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
          height: '100vh',
          backgroundColor: '#FDF1EB',
          flexDirection: 'column',
        }}
      >
        <LoginStylePage />
      </Box>
    )
  }
}

export default LoginPage
