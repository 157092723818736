import AddIcon from '@mui/icons-material/Add'
import { Button, CircularProgress, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import { useMutation } from '@tanstack/react-query'
import { Entry } from 'contentful'
import * as React from 'react'
import { queryClient } from '../..'
import { addTagToCandidate } from '../../api/candidate'
import { IKandidatFields } from '../../schema/generated/contentful'
import theme from '../../theme'
import { turnFirstLetterToUpperCase } from '../../utils/utils'
import AlertAction, { AlertTypes } from '../Alert/AlertAction'
import { AlertContext } from '../Alert/AlertContext'

const ITEM_HEIGHT = 48

/**
 *
 * @returns A component displaying all tags in a menu
 */
const TagMenuComponent = ({
  jobApplicationTags,
  utlysningId,
  candidate,
}: {
  jobApplicationTags: string[]
  utlysningId: string
  candidate: Entry<IKandidatFields>
}) => {
  const candidateTags = candidate.fields.tags ?? []
  const availableTags = [
    ...jobApplicationTags.filter((t) => candidateTags.indexOf(t) === -1),
  ]
  const [searchInput, setSearchInput] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [, dispatch] = React.useContext(AlertContext)

  /**
   * Mutates the candidate to the 'rejection' stage
   */
  const addTagMutation = useMutation(
    ({ tag }: { tag: string }) => addTagToCandidate(candidate.sys.id, tag),
    {
      onError: async () => {
        await queryClient.invalidateQueries(['utlysning', utlysningId])
        dispatch(
          AlertAction.showAlert(
            'Klarte ikke å oppdatere tags',
            AlertTypes.error
          )
        )
      },
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(['utlysning', utlysningId])
        dispatch(
          AlertAction.showAlert(
            `Taggen "${variables.tag}" er lagt til på kandidaten: ${candidate.fields.name}`,
            AlertTypes.success
          )
        )
      },
    }
  )

  /**
   * Closes the menu
   */
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleTagClicked = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const tag = e.currentTarget.attributes.getNamedItem('custom-tag')?.value
    if (tag === undefined) return
    await addTagMutation.mutateAsync({
      tag,
    })
  }

  if (!availableTags && jobApplicationTags.length) {
    return (
      <Box sx={{ fontSize: '12px', color: 'gray' }}>
        Ingen tags definert. Tags settes opp i contentful.
      </Box>
    )
  }
  if (!availableTags || availableTags.length === 0) {
    // No tags to add, all in use on this candidate
    return <></>
  }

  return (
    <>
      <Button
        variant="roundedInfo"
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        sx={{
          background: theme.palette.success.light,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        style={{
          cursor: 'pointer',
          padding: '3px',
          paddingLeft: '8px',
          paddingRight: '12px',
          textAlign: 'center',
          boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        }}
      >
        <AddIcon fontSize="small" /> Legg til
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '35ch',
          },
        }}
      >
        <Box
          onKeyDown={(e) => e.stopPropagation()}
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '2px solid #EAEAEA;',
            padding: '9px',
          }}
        >
          <TextField
            color="secondary"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            focused
            sx={{
              width: '100%',
              borderRadius: '4px',
            }}
            size="small"
            label="Skriv tag..."
          />
        </Box>
        {availableTags
          .filter((tag: string) =>
            tag.toLowerCase().includes(searchInput.toLowerCase())
          )
          .map((tag: string, index: number) => (
            <Button
              // eslint-disable-next-line react/no-array-index-key
              key={`${tag}-${index}`}
              onClick={(e) => {
                handleTagClicked(e)
              }}
              custom-tag={tag}
              variant="roundedInfo"
              disabled={addTagMutation.isLoading}
              sx={{
                margin: '4px',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              {turnFirstLetterToUpperCase(tag)}
              {addTagMutation.variables?.tag === tag &&
                addTagMutation.isLoading && <CircularProgress size="20px" />}
            </Button>
          ))}
      </Menu>
    </>
  )
}

export default TagMenuComponent
