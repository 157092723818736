import { Entry } from 'contentful'
import jwtDecode from 'jwt-decode'
import { getLoggedInUser, newCandidateHistoryLog } from '../../../api/api'
import { getCandidate } from '../../../api/candidate'
import { sendMail as APISendMail } from '../../../api/email'
import { IHistory } from '../../../interfaces/history.interface'
import googleAuthToken from '../../../interfaces/googleAuthToken'
import { IKandidatFields } from '../../../schema/generated/contentful'
import { emailSignature } from './EmailSignature'

/* We should get logged in user from contentfull employee list in order to get position and phone. */
const token = window.localStorage.getItem('GoogleAuth')
let payload = {
  name: '',
  email: '',
}
if (token) {
  payload = jwtDecode<googleAuthToken>(token || '')
}

const loggedInUserName = payload.name
const loggedInUserPhone = payload.email
const loggedInUserPosition = ''

const mailSignature = emailSignature(
  loggedInUserName,
  loggedInUserPhone,
  loggedInUserPosition
)

const placeholders = [
  'Navn',
  'Epost',
  'Telefon',
  'Søknadsdato',
  'Eksamineringsår',
  'AntallÅrsErfaring',
]

function replaceTokens(
  candidate: Entry<IKandidatFields>,
  content: string
): string {
  let res = `<html><head>
	</head><body><div style="padding-left:32px">`
  res += content
  res = res.replace(/\n/g, '<br/>')
  res = res.replace(/{Navn}/g, candidate.fields.name)
  res = res.replace(/{Epost}/g, candidate.fields.email)
  res = res.replace(
    /{Søknadsdato}/g,
    new Date(candidate.fields.applicationDate).toLocaleDateString() ||
      'Mangler Søknadsdato'
  )
  res = res.replace(
    /{Telefon}/g,
    candidate.fields.telephoneNumber?.toString() || 'Mangler Telefon'
  )
  res = res.replace(
    /{Eksamineringsår}/g,
    candidate.fields.graduationYear?.toString() || 'Mangler Eksamineringsår'
  )
  res = res.replace(
    /{AntallÅrsErfaring}/g,
    candidate.fields.yearsExperience?.toString() || 'Mangler AntallÅrsErfaring'
  )
  res += `<br/><br/>${mailSignature}`
  res += `</div></body></html>`

  return res
}

function generateEmailBody(
  candidate: Entry<IKandidatFields>,
  subject: string,
  content: string
): {
  candidate: Entry<IKandidatFields>
  mailTo: string
  subject: string
  body: string
} {
  return {
    candidate,
    mailTo: candidate.fields.email,
    subject,
    body: replaceTokens(candidate, content),
  }
}

function generateEmailContent(
  candidateIdsList: string[],
  subject: string,
  content: string
): Promise<
  {
    candidate: Entry<IKandidatFields>
    mailTo: string
    subject: string
    body: string
  }[]
> {
  return new Promise((resolve) => {
    const candidates$ = candidateIdsList.map((c) => {
      return getCandidate(c)
    })
    Promise.all(candidates$).then((candidates) => {
      const res = candidates.map((c) => {
        return generateEmailBody(c, subject, content)
      })
      resolve(res)
    })
  })
}

function sendMail(
  candidatesIds: string[],
  subject: string,
  content: string
): Promise<
  { name: string; email: string; emailSent: boolean; candidateId: string }[]
> {
  return new Promise((resolve) => {
    generateEmailContent(candidatesIds, subject, content).then((result) => {
      const s$ = result.map((mailContent) => {
        return new Promise((resolve) => {
          APISendMail({
            mailTo: mailContent.mailTo,
            subject: mailContent.subject || '',
            body: mailContent.body || '',
          })
            .then((response) => {
              resolve({
                response,
                candidate: mailContent.candidate,
              })
            })
            .then(async () => {
              const oldLog: IHistory[] = mailContent.candidate.fields.history

              const logAction: IHistory = {
                timestamp: new Date(),
                userEmail: getLoggedInUser().email,
                userFullname: getLoggedInUser().name,
                userId: getLoggedInUser().id,
                userPictureURL: getLoggedInUser().profilePicture?.url,
                title: 'Epost sendt',
                subContent: `Emne: ${mailContent.subject}`,
                content: `Innhold:  ${content}`,
              }

              await newCandidateHistoryLog(
                mailContent.candidate.sys.id,
                oldLog,
                logAction
              )
            })
            .catch(() => {
              resolve({
                response: { status: 500 },
                candidate: mailContent.candidate,
              })
            })
        })
      })
      Promise.all(s$).then((res) => {
        const emailReceipt = res.map((r) => {
          const curr = r as {
            response: { status: number; data: { envelope: { to: string } } }
            candidate: Entry<IKandidatFields>
          }
          if (curr.response.status === 200) {
            return {
              name: curr.candidate.fields.name,
              email: curr.response.data.envelope.to,
              emailSent: true,
              candidateId: curr.candidate.sys.id,
            }
          }
          return {
            name: curr.candidate.fields.name,
            email: curr.candidate.fields.email,
            emailSent: false,
            candidateId: curr.candidate.sys.id,
          }
        })
        resolve(emailReceipt)
      })
    })
  })
}

export const SendMailHandler = {
  sendMail,
  placeholders,
}
