import * as React from 'react'
import { Link } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import SettingsOutlined from '@mui/icons-material/SettingsOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Entry } from 'contentful'
import { useQueryClient } from '@tanstack/react-query'
import { CircularProgress } from '@mui/material'
import { IEmailTemplateFields } from '../../schema/generated/contentful'
import { deleteEmailTemplate } from '../../api/email'

/**
 * TemplateOptions props
 */
interface TemplateOptionsProps {
  template: Entry<IEmailTemplateFields>
}

/**
 * Dropdown menu component with actions for a template
 * @param props - The component props, in this case which template is currently being displayed options for
 * @returns A dropdown menu at the end of each template row, displaying buttons for editing and deleting a template
 */
const TemplateOptions: React.FunctionComponent<TemplateOptionsProps> = (
  props: TemplateOptionsProps
) => {
  const { template } = props
  const queryClient = useQueryClient()

  const [loadingDelete, setLoadingDelete] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  /**
   * Opens the dropdown menu once the three dots have been clicked
   * @param event - A mouseclick event
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Closes the dropdown menu
   */
  const handleClose = () => {
    setAnchorEl(null)
  }

  // TODO: Either refetch or invalidate queries are redundant, remove one of them, can also be turned into a QueryMutation
  /**
   * Deletes a template in Contentful
   */
  const deleteTemplate = async () => {
    setLoadingDelete(true)
    await deleteEmailTemplate(template?.sys.id)
      .then(() => queryClient.refetchQueries(['templates']))
      .then(() => queryClient.invalidateQueries(['templates']))
      .then(() => setLoadingDelete(false))
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2, display: 'flex', justifyContent: 'center' }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {loadingDelete ? (
          <CircularProgress
            size={12}
            sx={{
              color: 'inherit',
            }}
          />
        ) : (
          <MoreHorizIcon sx={{ width: 32, height: 32 }}>M</MoreHorizIcon>
        )}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiMoreHorizIcon-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Link
          style={{
            textDecoration: 'none',
          }}
          to={`/maler/${template.sys.id}`}
          key={template.sys.id}
        >
          <MenuItem
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#454D60',
            }}
          >
            Rediger mal
            <ListItemIcon
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: '#454D60',
              }}
            >
              <SettingsOutlined fontSize="small" color="inherit" />
            </ListItemIcon>
          </MenuItem>
        </Link>

        <MenuItem
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            color: '#8B271D',
          }}
          onClick={deleteTemplate}
        >
          Slett mal
          <ListItemIcon
            sx={{
              display: 'flex',
              justifyContent: 'center',
              color: '#8B271D',
            }}
          >
            <DeleteOutlineOutlinedIcon fontSize="small" color="inherit" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </>
  )
}

export default TemplateOptions
