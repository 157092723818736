/* eslint-disable react/jsx-props-no-spreading, no-return-assign */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  FormControlLabel,
  Modal,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Entry } from 'contentful'
import * as React from 'react'
import { useMutation } from '@tanstack/react-query'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import {
  IJobapplicationContentFields,
  IKandidatFields,
} from '../../schema/generated/contentful'
import theme from '../../theme'
import CandTable from '../styledMisc/table/CandTable'
import { headCellsUtlysninger } from '../styledMisc/table/HeadCells'
import './UtlysningView.css'
import SearchBar from './searchbar/SearchBar'
import emptyStateImg from '../../assets/misc/emptyState.png'
import CandidateListMobile from '../candidateListMobile/CandidateListMobile'
import { AlertContext } from '../Alert/AlertContext'
import AlertAction, { AlertTypes } from '../Alert/AlertAction'
import {
  changeShownStatusOnApplication,
  removeTagFromList,
  updateTagList,
} from '../../api/utlysning'
import { queryClient } from '../..'
import { ChrisTab } from '../styledMisc/table/ChrisTable'
import { deleteAllCandidatesFromUtlysnignWithPersonalDataNotAllowed } from '../../api/candidate'
import { delay } from '../../utils/utils'

const checkIfUtlysningHasApplicantsWhosShouldBeDeletedBecauseOfGDPR = (
  applicants: Entry<IKandidatFields>[]
) => {
  return applicants.filter(
    (applicant) => !applicant.fields.preservePersonalDataAllowed
  ).length
}
const getAllApplicantsWhosShouldBeDeletedBecauseOfGDPR = (
  applicants: Entry<IKandidatFields>[]
) => {
  return applicants.filter(
    (applicant) => !applicant.fields.preservePersonalDataAllowed
  )
}

/**
 * TabPanel props
 */
interface TabPanelProps {
  children?: React.ReactNode
  // The current index of the selected tab panel
  index: number
  // Value of the selected tab panel
  value: number
}

export interface ISearchFilter {
  searchValue: string
  tags: string[]
  minimumYearsExperience: number
}

/**
 *
 * @param props - TabPanel props
 * @returns A tab panel component which displays the component children if the tab is clicked on, and hides all other content from other tabs
 */
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const filterCandidatesOnActiveFilters = (
  candidates: Entry<IKandidatFields>[],
  filter: ISearchFilter
) => {
  return candidates
    .filter((c) => {
      return (
        c.fields.name &&
        c.fields.name.toUpperCase().includes(filter.searchValue.toUpperCase())
      )
    })
    .filter((c) => {
      const match = filter.tags.filter((t) => c.fields.tags?.indexOf(t) > -1)
      return match.length === filter.tags.length
    })
    .filter((c) => {
      return Number(c.fields.yearsExperience) >= filter.minimumYearsExperience
    })
}

/**
 * @param index
 * @returns
 */
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

/**
 * UtlysningView props
 */
interface UtlysningViewProps {
  utlysning: Entry<IJobapplicationContentFields>
}

/**
 * @param props - Which stages from which job posting to show as different tabs
 * @returns A component with different job posting stages displayed as tabs
 */
const UtlysningView: React.FC<UtlysningViewProps> = ({
  utlysning,
}: UtlysningViewProps) => {
  // The different stages of the job posting, which will be displayed as different tabs
  const tabStages = utlysning.fields.stages
  const candidates = utlysning.fields.applicants ?? []
  const jobIsActive = utlysning.fields.shown ?? true
  const deadLine = utlysning.fields.deadline
    ? new Date(utlysning.fields.deadline)
    : undefined
  const [, dispatch] = React.useContext(AlertContext)
  const [currentStage, setActiveStage] = React.useState<string>(
    tabStages[0] ?? ''
  )
  const [tag, setTag] = React.useState<string | undefined>('')
  const [filter, setFilter] = React.useState<ISearchFilter>({
    searchValue: '',
    tags: [],
    minimumYearsExperience: 0,
  })
  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const handleCloseModal = () => setOpenModal(false)
  const activeTabStageIndex = tabStages.findIndex(
    (stage) => stage === currentStage
  )
  const filteredCandidates = filterCandidatesOnActiveFilters(candidates, filter)

  const handleFilter = (f: {
    tags: string[]
    minimumYearsExperience: number
    searchValue: string
  }) => {
    setFilter({
      ...f,
    })
  }

  /**
   * Mutation for adding a tag to the job posting
   */
  const addTagMutation = useMutation(
    (tag: string) => updateTagList(utlysning.sys.id, tag),
    {
      onSettled: (data, variables, context) => {
        delay(300).then(async () => {
          await queryClient.invalidateQueries(['utlysning', utlysning.sys.id])
        })

        dispatch(
          AlertAction.showAlert(
            `La til taggen: "${context}" på utlysningen "${utlysning.fields.title}"`,
            AlertTypes.success
          )
        )
      },
    }
  )

  /**
   * Mutation for adding a tag to the job posting
   */
  const removeTagMutation = useMutation(
    (tag: string) => removeTagFromList(utlysning.sys.id, tag),
    {
      onSuccess: async (data, variables) => {
        delay(300).then(async () => {
          await queryClient.invalidateQueries(['utlysning', utlysning.sys.id])
        })
        dispatch(
          AlertAction.showAlert(
            `Taggen: "${variables}" er fjernet fra utlysningen "${utlysning.fields.title}"`,
            AlertTypes.success
          )
        )
      },
      onError: async (data, variables) => {
        await queryClient.invalidateQueries(['utlysning', utlysning.sys.id])
        dispatch(
          AlertAction.showAlert(
            `Kunne ikke fjerne: "${variables}" fra "${utlysning.fields.title}"`,
            AlertTypes.error
          )
        )
      },
    }
  )

  /**
   * Mutation for changing the shown status of the job posting
   */
  const jobShownMutation = useMutation(
    () => changeShownStatusOnApplication(utlysning.sys.id),
    {
      onSuccess: async () => {
        delay(300).then(async () => {
          await queryClient.invalidateQueries(['utlysning', utlysning.sys.id])
        })
        dispatch(
          AlertAction.showAlert(
            jobIsActive
              ? `${utlysning.fields.title} er nå fjernet fra fink.no`
              : `${utlysning.fields.title} er nå aktiv på fink.no"`,
            AlertTypes.success
          )
        )
      },
      onError: async () => {
        await queryClient.invalidateQueries(['utlysning', utlysning.sys.id])
        dispatch(
          AlertAction.showAlert(
            'Det oppstod en feil, prøv på nytt',
            AlertTypes.error
          )
        )
      },
    }
  )

  /**
   * Delete all applicants from the job posting who has not allowed us to store their data for 5 years
   */
  const deleteAllApplicantsWithNotAllowPersonalDataMutation = useMutation(
    (applicantsToDelete: Entry<IKandidatFields>[]) =>
      deleteAllCandidatesFromUtlysnignWithPersonalDataNotAllowed(
        applicantsToDelete,
        utlysning.sys.id
      ),
    {
      onSuccess: async () => {
        delay(300).then(async () => {
          await queryClient.invalidateQueries(['utlysning', utlysning.sys.id])
        })
        dispatch(
          AlertAction.showAlert(
            `Slettet alle kandidatene som ikke har sagt ja til at vi lagrer dataen deres i 5 år fra "${utlysning.fields.title}"`,
            AlertTypes.success
          )
        )
      },
      onError: async () => {
        await queryClient.invalidateQueries(['utlysning', utlysning.sys.id])
        dispatch(
          AlertAction.showAlert(
            `En feil oppstod i slettingen av kandidatene fra "${utlysning.fields.title}"`,
            AlertTypes.error
          )
        )
      },
    }
  )

  const numberOfPeopleToDelete =
    checkIfUtlysningHasApplicantsWhosShouldBeDeletedBecauseOfGDPR(
      utlysning.fields.applicants ?? []
    )

  const gdprPeopleToDelete =
    getAllApplicantsWhosShouldBeDeletedBecauseOfGDPR(candidates)

  /**
   * Changes the active stage to the one clicked on
   * @param event - Clicking a new tab will change the content of the child component
   * @param newValue - The new index value of the clicked tab
   */
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveStage(tabStages[newValue])
  }
  return (
    <>
      <Box sx={{ width: '100%', display: { xs: 'block', sm: 'none' } }}>
        {candidates && <CandidateListMobile candidates={candidates} />}
      </Box>
      <Box sx={{ width: '100%', display: { xs: 'none', sm: 'block' } }}>
        <Box>
          <div style={{ marginTop: '-64px' }}>
            <div className="jobApplicationStatusIndicatorWrapper">
              <FormControlLabel
                label={jobIsActive ? 'Skjul utlysning' : 'Vis utlysning'}
                control={
                  <Switch
                    checked={jobIsActive}
                    onChange={async () => {
                      await jobShownMutation.mutateAsync()
                    }}
                    disabled={jobShownMutation.isLoading}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
              />
              <div
                className={`jobApplicationStatusIndicator ${
                  jobIsActive ? 'active' : 'inactive'
                }`}
              >
                {jobIsActive ? 'Aktiv' : 'Innaktiv'}
              </div>
            </div>
            <div className="infoLine">
              <div>
                Viser <strong>{filteredCandidates.length}</strong> av{' '}
                <strong>{candidates?.length}</strong> søkere
              </div>
              {numberOfPeopleToDelete > 0 && (
                <div>
                  Antall personer som må slettes grunnet GDPR:{' '}
                  <strong>{numberOfPeopleToDelete}</strong>
                </div>
              )}
              <div>
                Søknadsfrist{' '}
                <strong>{deadLine?.toLocaleDateString() || 'N/A'}</strong>
              </div>
            </div>
            <SearchBar
              utlysning={utlysning}
              handleFilter={handleFilter}
              filter={filter}
            />
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="change-job-posting"
                id="change-job-posting"
              >
                <Typography>Gjør endringer på utlysningen</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" justifyContent="end" flexWrap="wrap">
                  <Box display="flex" width="100%" justifyContent="start">
                    {utlysning.fields.tags?.map((tag, index) => {
                      return (
                        <Button
                          key={`${tag}_${index.toString}`}
                          variant="roundedInfo"
                          style={{
                            padding: '4px 8px',
                            height: '30px',
                          }}
                          disabled={
                            removeTagMutation.variables === tag &&
                            removeTagMutation.isLoading
                          }
                        >
                          {tag}
                          {removeTagMutation.variables === tag &&
                          removeTagMutation.isLoading ? (
                            <CircularProgress size="20px" />
                          ) : (
                            <DeleteIcon
                              onClick={() => {
                                removeTagMutation.mutateAsync(tag)
                              }}
                              style={{
                                cursor: 'pointer',
                              }}
                              fontSize="small"
                            />
                          )}
                        </Button>
                      )
                    })}
                  </Box>
                  <TextField
                    id="standard-basic"
                    label="Tagg"
                    variant="standard"
                    value={tag}
                    onChange={(e) => {
                      setTag(e.target.value)
                    }}
                  />
                  <Button
                    component="span"
                    variant="outlined"
                    disabled={!tag}
                    onClick={() => {
                      if (!tag) return
                      addTagMutation.mutateAsync(tag)
                      setTag('')
                    }}
                  >
                    Legg til tagg
                  </Button>
                </Box>
                <Button
                  component="span"
                  variant="contained"
                  color="error"
                  onClick={async () => {
                    setOpenModal(true)
                  }}
                >
                  Slett alle brukerne som ikke har sagt ja til å lagre data
                </Button>
              </AccordionDetails>
            </Accordion>
          </div>
          <Tabs
            variant="fullWidth"
            value={activeTabStageIndex}
            onChange={handleChange}
            aria-label="Tabs for job application stages"
            sx={{
              borderRadius: '8px 8px 0 0',
              border: '1px solid #E8EAEE',
            }}
          >
            {tabStages.map((label, index) => {
              return (
                <Tab
                  key={label}
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderRight: '1px solid #E8EAEE',
                    borderLeft: '1px solid #E8EAEE',
                    fontWeight: 400,
                    height: '40px',
                    '&.Mui-selected': {
                      backgroundColor: '#99A6C31A',
                      borderBottom: '1px solid #7B8AB0',
                      fontWeight: 700,
                    },
                  }}
                  icon={
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: 'inherit',
                        color: theme.palette.primary.main,
                        textTransform: 'none',
                      }}
                    >
                      {label}
                      <Button
                        component="span"
                        variant={
                          /* eslint-disable no-nested-ternary
                           */
                          label === 'Avslag'
                            ? 'squaredError'
                            : filteredCandidates.filter(
                                (c) => c.fields.stage === tabStages[index]
                              ).length > 0
                            ? 'squaredSuccess'
                            : 'text'
                        }
                      >
                        {filteredCandidates.filter(
                          (c) => c.fields.stage === tabStages[index]
                        ).length || (
                          <span
                            style={{
                              fontSize: '.8em',
                            }}
                          >
                            0
                          </span>
                        )}
                      </Button>
                    </Box>
                  }
                  {...a11yProps(0)}
                />
              )
            })}
          </Tabs>
        </Box>
        {tabStages.map((label) => (
          <TabPanel
            value={activeTabStageIndex}
            index={tabStages.indexOf(label)}
            key={tabStages.indexOf(label)}
          >
            {candidates && filteredCandidates.length > 0 && (
              <CandTable
                candidates={filteredCandidates.filter(
                  (c) => c.fields.stage === tabStages[activeTabStageIndex]
                )}
                utlysning={utlysning}
                type="utlysning"
                headCells={headCellsUtlysninger}
                isSummerJob={!!utlysning.fields.tittelForSommerjobb}
              />
            )}
            {candidates && filteredCandidates.length === 0 && (
              <ChrisTab emptyStateImg={emptyStateImg} />
            )}
          </TabPanel>
        ))}
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '80vh',
            width: '50vw',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            p: 4,
            overflow: 'scroll',
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Alle som har svart nei til å lagre personopplysninger
          </Typography>
          {gdprPeopleToDelete.map((candidate) => (
            <Box
              sx={{
                width: '100%',
                border: '2px solid grey',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                margin: 1,
                boxSizing: 'border-box',
              }}
            >
              <Typography id="modal-modal-description">
                {candidate.fields.name}
              </Typography>
              {!candidate.fields.preservePersonalDataAllowed && (
                <Tooltip title="Denne brukeren ønsker ikke at vi lagrer hens data lengre enn nødvendig. Slett kandidaten i contentful etter kandidaten er ferdig behandlet.">
                  <PrivacyTipIcon
                    sx={{
                      cursor: 'default',
                      color: '#ff9800',
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          ))}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '100%',
              gap: 5,
              py: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={async () => {
                await deleteAllApplicantsWithNotAllowPersonalDataMutation.mutateAsync(
                  gdprPeopleToDelete
                )
                setOpenModal(false)
              }}
              color="error"
              disabled={
                deleteAllApplicantsWithNotAllowPersonalDataMutation.isLoading
              }
            >
              {deleteAllApplicantsWithNotAllowPersonalDataMutation.isLoading ? (
                <CircularProgress size={12} />
              ) : (
                <>
                  {numberOfPeopleToDelete > 1
                    ? 'Slett alle kandidatene'
                    : 'Slett kandidaten'}
                </>
              )}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpenModal(false)
              }}
              color="secondary"
            >
              Gå tilbake
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default UtlysningView
