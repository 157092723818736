import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export const LoadingOrError = ({
  isLoading,
  isError,
  children,
}: {
  isLoading: boolean
  isError: boolean
  children: JSX.Element
}) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          height: 'calc(100vh - 90px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>Loading...</p>
        <CircularProgress />
      </Box>
    )
  }
  if (isError) {
    return (
      <Box
        sx={{
          height: 'calc(100vh - 90px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>Det har skjedd en feil</p>
      </Box>
    )
  }
  return <>{children}</>
}
