import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { EntryCollection } from 'contentful'
import * as React from 'react'
import { useState } from 'react'
import { IEmailTemplateFields } from '../../../schema/generated/contentful'
import SendMailTemplateInput from './SendMailTemplateInput'

function SelectTemplate(props: {
  feedbackOption: string
  templates: EntryCollection<IEmailTemplateFields> | null
  setSelectedTemplates: React.Dispatch<
    React.SetStateAction<IEmailTemplateFields | null>
  >
  selectedTemplate: IEmailTemplateFields | null
  contentUpdated: (o: { subject: string; content: string }) => void
}) {
  const { feedbackOption } = props
  const { templates } = props
  const { setSelectedTemplates } = props
  const { selectedTemplate } = props

  const [subject, setSubject] = useState('')
  const [content, setContent] = useState('')

  React.useEffect(() => {
    if (selectedTemplate) {
      setSubject(selectedTemplate.subject)
      setContent(selectedTemplate.content)
    }
  }, [selectedTemplate])

  function handleContentUpdated() {
    const { contentUpdated } = props
    contentUpdated({ subject, content })
  }

  /**
   * @returns
   */
  if (!templates) {
    return <div>Loading...</div>
  }
  /**
   * @returns
   */
  return (
    <div>
      {feedbackOption === 'sendMail' && (
        <>
          <div
            style={{
              fontWeight: 700,
              paddingTop: '16px',
            }}
          >
            Velg Mal
          </div>
          <Box
            sx={{
              paddingTop: '32px',
              minWidth: 120,
              width: '60%',
            }}
          >
            {templates && templates.items && (
              <FormControl fullWidth>
                <InputLabel id="selectTemplate">Maler</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="maler"
                  defaultValue=""
                  onChange={(event: SelectChangeEvent<string>) => {
                    const s = templates?.items?.find(
                      (t: { sys: { id: string } }) =>
                        t.sys.id === event.target.value
                    )
                    setSelectedTemplates(s?.fields ?? null)
                  }}
                >
                  {templates.items
                    .filter((template) => template)
                    .map((template) => (
                      <MenuItem value={template.sys.id} key={template.sys.id}>
                        {template.fields.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </Box>
          {selectedTemplate && (
            <div style={{ width: '100%' }}>
              <div
                style={{
                  fontWeight: 700,
                  paddingTop: '32px',
                  paddingBottom: '16px',
                }}
              >
                {`Valgt Mal "${selectedTemplate.name}"`}
              </div>
              <TextField
                style={{
                  width: '100%',
                  marginBottom: '16px',
                }}
                id="filled-basic"
                label="Emne"
                variant="filled"
                onChange={(e) => {
                  setSubject(e.target.value)
                  handleContentUpdated()
                }}
                value={subject}
              />
              <SendMailTemplateInput
                content={content}
                contentUpdated={(c: string) => {
                  setContent(c)
                  handleContentUpdated()
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default SelectTemplate
