import React, { useEffect, useState } from 'react'
import { chkAccessToJobApplications } from './api/api'
import NavBar from './components/NavBar'
import LoginPage from './pages/LoginPage'

/**
 *
 * @returns The root component where Navbar is always displayed no matter which route is active
 */
const App: React.FunctionComponent = () => {
  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false)
  const [userHasAccess, setUserHasAccess] = useState(false)

  useEffect(() => {
    const isAuth = window.localStorage.getItem('GoogleAuth')
    fetch(`https://oauth2.googleapis.com/tokeninfo?id_token=${isAuth}`).then(
      (res) => {
        let userIsLoggedIn = false
        if (res.status === 200) {
          userIsLoggedIn = true
        }
        setUserIsAuthenticated(userIsLoggedIn)
        chkAccessToJobApplications().then((res) => {
          setUserHasAccess(res)
        })
      }
    )
  }, [])

  if (userIsAuthenticated && userHasAccess) {
    return <NavBar />
  }
  if (userIsAuthenticated && !userHasAccess) {
    return (
      <div
        style={{
          margin: '64px',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <section>
          <h2>Manglende tilganger.</h2>
          <br />
          <p>
            Du har ikke tilgang til å se og håndtere stillingsutlysninger og
            kandidater.
            <br /> For å få tilgang til tjenesten kan du kontakte Christian
            Vinterly.
          </p>
        </section>
      </div>
    )
  }
  return <LoginPage />
}

export default App
