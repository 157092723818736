import { Document } from '@contentful/rich-text-types'
import { Button, Tooltip, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { Asset, Entry } from 'contentful'
import * as React from 'react'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import { getLoggedInUser, newCandidateHistoryLog } from '../../../api/api'
import { sendMail as APISendMail } from '../../../api/email'
import { IHistory } from '../../../interfaces/history.interface'
import { INote } from '../../../interfaces/note.interface'
import { HeadCell } from '../../../interfaces/table.interface'
import {
  IJobapplicationContentFields,
  IKandidatFields,
} from '../../../schema/generated/contentful'
import theme from '../../../theme'
import { Order } from '../../../interfaces/types'
import CandidateProfileModal from '../../candidateProfile/CandidateProfileModal'
import { ListActions } from '../../utlysning/ListActions'
import ResponsiblePersonDlg from '../../utlysning/responsiblePersonDlg/ResponsiblePerson'
import CustomCheckbox from '../FinkCheckbox'
import './CandTable.css'
import EnhancedTableHead from './EnhancedTableHead'
import AlertAction, { AlertTypes } from '../../Alert/AlertAction'
import { AlertContext } from '../../Alert/AlertContext'
import {
  humanizeTimestamp,
  turnFirstLetterToUpperCase,
} from '../../../utils/utils'
import TagMenuComponent from '../TagMenuComponent'
import { TagsView } from '../TagsView'
import { updateResponsiblePersonForCandidate } from '../../../api/candidate'

/**
 * Compares two values
 * @param a - The first value to compare
 * @param b - The second value being compared
 * @param orderBy - Which type to order by
 * @returns An int indicating the order to sort the two elements a and b
 */
export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

/**
 * Sorts two values by order
 * @param order - The order to sort by, either desc or asc
 * @param orderBy - Which type to order by
 * @returns Elements in descending or ascending order
 */
function getComparator<Key extends keyof never>(
  order: Order,
  orderBy: Key
): (
  a: {
    fields: {
      [key in Key]:
        | number
        | string
        | string[]
        | Asset
        | Document
        | Entry<IJobapplicationContentFields>
        | boolean
        | JSON
        | INote[]
    }
  },
  b: {
    fields: {
      [key in Key]:
        | number
        | string
        | string[]
        | Asset
        | Document
        | Entry<IJobapplicationContentFields>
        | boolean
        | JSON
        | INote[]
    }
  }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a.fields, b.fields, orderBy)
    : (a, b) => -descendingComparator(a.fields, b.fields, orderBy)
}

/**
 * The ApplicantsTable props
 */
interface ApplicantsTableProps {
  // The table's header cells
  headCells: HeadCell[]
  // The candidates which will populate each table row
  candidates: Entry<IKandidatFields>[]
  // Whether the table is being used in the job postings page or the candidates page
  type: 'utlysning' | 'kandidat'
  // Which job posting, if any (if the table is used in the candidates page, no job posting will be needed)
  utlysning?: Entry<IJobapplicationContentFields>
  isSummerJob?: boolean
}

/**
 * A table component which can be utilized in all tables where the contents are candidates (IKandidatFields)
 * @param props - The component props, in this case which header cells should be displayed and the content rows
 * @returns A table component displaying candidates
 */
const CandTable: React.FunctionComponent<ApplicantsTableProps> = (
  props: ApplicantsTableProps
) => {
  const { headCells, candidates, type, utlysning, isSummerJob } = props
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] =
    React.useState<keyof IKandidatFields>('applicationDate')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(50)
  const [, dispatch] = React.useContext(AlertContext)

  // Candidateprofile modal
  const [openCandidateProfileModal, setOpenCandidateProfileModal] =
    React.useState(false)
  const [candidateProfile, setCandidateProfile] = React.useState(
    candidates[0]?.sys.id
  )
  const [responsiblePersonDlgCandidate, setResponsiblePersonDlgCandidate] =
    React.useState<Entry<IKandidatFields> | null>(null)

  const [hoveredRowID, setHoveredRowID] = React.useState('')
  /**
   * Opens the profile of a candidate
   * @param candidate - The candidate's who's profile is displayed
   */
  const handleOpen = (candidate: Entry<IKandidatFields> | undefined) => {
    if (candidate) {
      setCandidateProfile(candidate.sys.id)
      setOpenCandidateProfileModal(true)
    }
  }
  /**
   * Sorts the table rows
   * @param event - Clicking on a header will change the sort order
   * @param property - Which header/column is being sorted
   */
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IKandidatFields
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  /**
   * Clicking the 'select all' checkbox will select all table rows and check each checkbox
   * @param event - Clicking the checkbox will trigger the function
   */
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = candidates.map((n) => n.sys.id)
      setSelected(newSelecteds)
    } else {
      setSelected([])
    }
  }

  /**
   * Selects a row/candidate
   * @param event - Clicking the row checkbox will trigger the function
   * @param id - The id of the row/candidate that has been selected
   */
  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  /**
   * Switches to a new page
   * @param event - The event initializing change of page, i.e. mouseclick
   * @param newPage - The new page to change to
   */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  /**
   * Sets how many rows the page should display
   * @param event - The event initializing change of page, i.e. mouseclick
   */
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  /**
   * Function to check whether or not the candidate is selected (leftmost checkbox of row is checked)
   * @param id - Id of the candidate
   * @returns True if the id is the id of the selected candidate
   */
  const isSelected = (id: string) => selected.indexOf(id) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - candidates.length) : 0

  const listActionsTop =
    selected.length > 0 && utlysning ? (
      <ListActions
        disabled={false}
        utlysning={utlysning}
        candidateIDs={selected.slice()}
        setSelected={setSelected}
      />
    ) : (
      <></>
    )
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={candidates.length}
              headCells={headCells.filter((h) => {
                if (isSummerJob && h.id === 'yearsExperience') {
                  return false
                }
                return true
              })}
              type={type}
              actions={listActionsTop}
            />
            <TableBody>
              {candidates
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((candidate, index) => {
                  const isItemSelected = isSelected(candidate.sys.id)
                  let tags
                  if (candidate.fields.tags && candidate.fields.tags.length) {
                    tags = candidate.fields.tags?.map((tag: string) => {
                      return (
                        <Button key={tag} variant="roundedInfo">
                          {turnFirstLetterToUpperCase(tag)}
                        </Button>
                      )
                    })
                  }
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={candidate.sys.id}
                      selected={isItemSelected}
                      onMouseEnter={() => {
                        setHoveredRowID(index.toString())
                      }}
                      onMouseLeave={() => {
                        setHoveredRowID('')
                      }}
                    >
                      <TableCell padding="checkbox" align="center">
                        <CustomCheckbox
                          onClick={(event) =>
                            handleClick(event, candidate.sys.id)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        onClick={() => handleOpen(candidate)}
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{
                          fontWeight: 700,
                          fontSize: '14px',
                          color: theme.palette.text.primary,
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      >
                        {candidate.fields.name}{' '}
                        {candidate.fields.preservePersonalDataAllowed ===
                        true ? null : (
                          <Tooltip title="Denne brukeren ønsker ikke at vi lagrer hens data lengre enn nødvendig. Slett kandidaten i contentful etter kandidaten er ferdig behandlet.">
                            <PrivacyTipIcon
                              sx={{
                                cursor: 'default',
                                color: '#ff9800',
                              }}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 400,
                          fontSize: '14px',
                          color: theme.palette.text.primary,
                        }}
                      >
                        {type === 'utlysning' ? (
                          <>
                            {humanizeTimestamp(
                              new Date(candidate.fields.applicationDate)
                            )}
                          </>
                        ) : (
                          <>{candidate.fields.yearsExperience}</>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 400,
                          fontSize: '14px',
                          color: theme.palette.text.primary,
                        }}
                      >
                        {candidate.fields.graduationYear || 'N/A'}
                      </TableCell>
                      {!isSummerJob && (
                        <TableCell
                          align="left"
                          sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: theme.palette.text.primary,
                          }}
                        >
                          {type === 'utlysning' ? (
                            <>{candidate.fields.yearsExperience || 'N/A'}</>
                          ) : (
                            tags
                          )}
                        </TableCell>
                      )}
                      {type === 'utlysning' && (
                        <TableCell align="left">
                          <TagsView
                            candidate={candidate}
                            utlysningId={utlysning?.sys.id ?? ''}
                          />
                          <TagMenuComponent
                            jobApplicationTags={utlysning?.fields.tags || []}
                            utlysningId={utlysning?.sys.id ?? ''}
                            candidate={candidate}
                          />
                        </TableCell>
                      )}
                      <TableCell align="left">
                        {type === 'utlysning' ? (
                          <>
                            {candidate.fields.receivedEmail ? (
                              <>
                                <Box
                                  className="cstmIcon"
                                  sx={{
                                    color: theme.palette.success.contrastText,
                                    background: theme.palette.success.light,
                                  }}
                                >
                                  &#x2713;
                                </Box>
                                Epost sendt
                              </>
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  alignContent: 'center',
                                }}
                              >
                                {candidate.fields.stage.toUpperCase() ===
                                  'AVSLAG' && (
                                  <>
                                    <Box
                                      className="cstmIcon"
                                      sx={{
                                        color:
                                          theme.palette.warning.contrastText,
                                        background: theme.palette.warning.light,
                                      }}
                                    >
                                      !
                                    </Box>
                                    <span>Kandidat ikke varslet</span>
                                  </>
                                )}
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSie: '14px',
                                color: '#33373D',
                              }}
                            >
                              {candidate.fields.utlysning?.fields?.title}
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSie: '12px',
                                color: '#6A707F',
                              }}
                            >
                              Søkte for{' '}
                              {humanizeTimestamp(
                                new Date(candidate.fields.applicationDate)
                              )}
                            </Typography>
                          </div>
                        )}
                      </TableCell>
                      {type === 'utlysning' && utlysning && (
                        <TableCell
                          onClick={() => {
                            setResponsiblePersonDlgCandidate(candidate)
                          }}
                          sx={{
                            fontWeight: 700,
                            fontSize: '14px',
                            color: theme.palette.text.primary,
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                        >
                          {candidate.fields.responsiblePerson || (
                            <Tooltip title="Klikk for å sette saksbehandler.">
                              <span>Ingen saksbehandler</span>
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                      {type === 'utlysning' && utlysning && (
                        <TableCell>
                          <ListActions
                            disabled={hoveredRowID !== index.toString()}
                            utlysning={utlysning}
                            candidateIDs={[candidate.sys.id]}
                            setSelected={setSelected}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={candidates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {candidateProfile && openCandidateProfileModal && (
        <CandidateProfileModal
          applicationTitle={utlysning?.fields.title || 'Ingen tittel'}
          jobApplicationTags={utlysning?.fields.tags || []}
          candidate={
            candidates.filter(
              (candidate) => candidate.sys.id === candidateProfile
            )[0]
          }
          applicationStages={utlysning?.fields.stages || []}
          handleClose={() => setOpenCandidateProfileModal(false)}
          utlysningId={utlysning?.sys.id || ''}
        />
      )}
      {responsiblePersonDlgCandidate && (
        <ResponsiblePersonDlg
          candidate={responsiblePersonDlgCandidate}
          open
          close={() => setResponsiblePersonDlgCandidate(null)}
          save={async (resp: { name: string; email: string }) => {
            const currentRespPerson =
              responsiblePersonDlgCandidate.fields.responsiblePerson
            const candidateID = responsiblePersonDlgCandidate.sys.id
            const currentHistory =
              responsiblePersonDlgCandidate.fields?.history || ''
            responsiblePersonDlgCandidate.fields.responsiblePerson = resp.name
            setResponsiblePersonDlgCandidate(null)
            await updateResponsiblePersonForCandidate(candidateID, resp.name)
              .catch(() => {
                dispatch(
                  AlertAction.showAlert(
                    'Aisan.. Klarte ikke å oppdatere saksbehandler.',
                    AlertTypes.error
                  )
                )
              })
              .then(async () => {
                let title = 'Saksbehandler satt'
                let content = `Saksbehandler satt til ${resp.name}`
                if (currentRespPerson) {
                  title = 'Saksbehandler endret'
                  content = `Saksbehandler endret fra ${currentRespPerson} til ${resp.name}`
                }
                const oldLog: IHistory[] = currentHistory
                const logAction: IHistory = {
                  timestamp: new Date(),
                  userEmail: getLoggedInUser().email,
                  userFullname: getLoggedInUser().name,
                  userId: getLoggedInUser().id,
                  userPictureURL: getLoggedInUser().profilePicture?.url,
                  title,
                  content,
                }
                await newCandidateHistoryLog(candidateID, oldLog, logAction)
                if (resp.email) {
                  console.log('New responsible person notified via email.')
                  dispatch(
                    AlertAction.showAlert(
                      'Den ansvarlige har blitt varslet via epost.',
                      AlertTypes.success
                    )
                  )
                  APISendMail({
                    mailTo: resp.email,
                    subject: 'Fink jobbsøknad varsling',
                    body: `
                    Dette er en automatisk generert varsling fra Fink jobbsøkesystem.
                    <br/>
                    <p>
                        Melding:
                        <br/>
                            <em>${
                              getLoggedInUser().name
                            } har satt deg som saksbehandler for kandidat 
                                <b>${
                                  responsiblePersonDlgCandidate.fields.name
                                }</b> for stilling 
                                <b>${
                                  utlysning?.fields.title ||
                                  utlysning?.fields.tittelForSommerjobb
                                }</b>.
                            </em>
                        </p>
                    <p>
                        <a href='${window.location.href}'>Se utlysning</a>
                    </p>
                    <p>
                        Ha ein fortsatt flott dag! 😎
                    </p>`,
                  })
                }
              })
          }}
        />
      )}
    </Box>
  )
}

export default CandTable
