import create from 'zustand'

interface IStoreState {
  currentStage: string
  setActiveStage: (stage: string) => void
}

// TODO: Do we need a store for holding which stage is active?
export const useStore = create<IStoreState>((set) => ({
  currentStage: 'Screening',
  setActiveStage: (stage: string) => set(() => set({ currentStage: stage })),
}))
