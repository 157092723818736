import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import image1 from '../../assets/jobApplicationsOverview/duplicatebox.png'
import BoxType from '../../interfaces/box.interface'

// This method was used in Material UI v4 and should be updated to v5
const useStyles = makeStyles({
  boxSize: {
    width: 123,
    height: 82,
    backgroundImage: `url(${image1})`,
    marign: 0,
    padding: 0,
  },

  maintitle: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    color: '#33373D',
    paddingLeft: 15,
    paddingTop: 12,
    margin: 0,
  },

  amount: {
    display: 'inline-flex',
    flexDirection: 'row',
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#33373D',
    float: 'left',
    paddingLeft: 15,
  },
})

/**
 * Each job posting stage displayed in a styled box
 * @param props - The component props, in this case the name of the stage, how many applicant are currently at this stage, and any other subdetails
 * @returns A component displaying one of the job posting stages and information about the stage and it's applicants
 */
const DuplicateBox: React.FunctionComponent<BoxType> = (props: BoxType) => {
  const classes = useStyles()

  const { name, amount, subdetails } = props

  return (
    <Box className={classes.boxSize}>
      <p className={classes.maintitle}>{name}</p>
      <p className={classes.amount}>{amount}</p>
      <p className={classes.amount}>{subdetails}</p>
    </Box>
  )
}

export default DuplicateBox
