import React, { createContext, useReducer } from 'react'
import { AlertTypes } from './AlertAction'
import reducer from './AlertReducer'

export interface IAlertState {
  alertShower?: {
    type: AlertTypes
    message: string
  }
}

const initialState: IAlertState = {
  alertShower: {
    type: AlertTypes.success,
    message: 'Hei hei! Velkommen! 🐦',
  },
}

export const AlertContext = createContext<any>(initialState)

export const AlertProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const value: any = [state, dispatch]

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
}
