import { ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import App from './App'
import AlertPopup from './components/Alert/Alert'
import { AlertProvider } from './components/Alert/AlertContext'
import EditTemplate from './components/emailTemplates/EditTemplate'
import Utlysning from './pages/Utlysning'
import './index.css'
import Admin from './pages/Admin'
import Kandidater from './pages/Kandidater'
import Maler from './pages/Maler'
import Utlysninger from './pages/Utlysninger'
import reportWebVitals from './reportWebVitals'
import theme from './theme'

export const queryClient = new QueryClient({})
ReactDOM.render(
  <React.StrictMode>
    <>
      <QueryClientProvider client={queryClient}>
        <AlertProvider>
          <>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<App />}>
                    <Route path="" element={<Navigate to="utlysninger" />} />
                    <Route path="kandidater" element={<Kandidater />} />
                    <Route path="maler" element={<Maler />} />
                    <Route path="maler/:id" element={<EditTemplate />} />
                    <Route path="utlysninger" element={<Utlysninger />} />
                    <Route path="utlysninger/:id" element={<Utlysning />} />
                    <Route path="admin" element={<Admin />} />
                    <Route
                      path="*"
                      element={
                        <main style={{ padding: '1rem' }}>
                          <p>Nothing here</p>
                        </main>
                      }
                    />
                  </Route>
                </Routes>
              </BrowserRouter>
            </ThemeProvider>
            <AlertPopup />
            <ReactQueryDevtools />
          </>
        </AlertProvider>
      </QueryClientProvider>
    </>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
