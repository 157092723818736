import TextField from '@mui/material/TextField'
import * as React from 'react'
import './SendMailTemplateInput.css'
import { SendMailHandler } from './SendMailHandler'

function SendMailTemplateInput(props: {
  content: string
  contentUpdated: (s: string) => void
}) {
  const { placeholders } = SendMailHandler
  const [togglePlaceholders, setTogglePlaceholders] = React.useState(false)
  const [openPosition, setOpenPosition] = React.useState(0)
  const [textValue, setTextValue] = React.useState('')
  const { contentUpdated } = props

  React.useEffect(() => {
    const { content } = props
    setTextValue({ content }.content)
  }, [props])

  function handleToken(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (openPosition > -1) {
      const token = e.currentTarget.value
      const leftStr = textValue.slice(0, openPosition + 1)
      const rightStr = textValue.slice(openPosition + 1, textValue.length)
      const newValue = `${leftStr + token}}${rightStr}`
      setTogglePlaceholders(false)
      setTextValue(newValue.toString())
      contentUpdated(newValue.toString())
      document.getElementById('templateTextArea')?.focus()
    }
  }

  function handleOnChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const currentString = e.currentTarget.value
    const currentValue = currentString.split('')
    const start = currentValue.filter((t) => t === '{')
    const end = currentValue.filter((t) => t === '}')
    if (end.length === start.length - 1) {
      for (let i = 0; i < currentString.length; i += 1) {
        if (currentString[i] === '{') {
          const restOfString = currentString.slice(i + 1, currentString.length)
          if (!restOfString.length) {
            setTogglePlaceholders(true)
            setOpenPosition(i)
          }
          const foundOpeningTag = false
          if (
            textValue.indexOf('{') === -1 ||
            restOfString.indexOf('}') === -1
          ) {
            setTogglePlaceholders(true)
            setOpenPosition(i)
          }
          for (let a = 0; a < restOfString.length; a += 1) {
            if (restOfString[a] === '{') {
              setTogglePlaceholders(true)
              setOpenPosition(i)
              break
            }
            if (restOfString[a] === '}' && !foundOpeningTag) {
              break
            }
          }
        }
      }
    } else {
      setTogglePlaceholders(false)
      setOpenPosition(-1)
    }
    setTextValue(currentString)
    contentUpdated(currentString)
  }
  return (
    <>
      <div style={{ position: 'relative' }}>
        {togglePlaceholders && (
          <div className="contentTokenContainer">
            <span>Tilgjengelig tokens</span>
            <br />
            {placeholders.map((i) => (
              <button
                key={i}
                type="button"
                className="contentTokenBtn"
                onClick={handleToken}
                value={i}
              >
                {i}
              </button>
            ))}
          </div>
        )}
        <TextField
          style={{ width: '100%' }}
          id="templateTextArea"
          label="Innhold"
          multiline
          variant="filled"
          value={textValue}
          onChange={(e) => handleOnChange(e)}
        />
      </div>
    </>
  )
}

export default SendMailTemplateInput
