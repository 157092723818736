/* eslint-disable consistent-return */

import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Asset, Entry } from 'contentful'
import { Document } from '@contentful/rich-text-types'
import theme from '../../../theme'
import { Order } from '../../../interfaces/types'
import { HeadCellTemplates } from '../../../interfaces/table.interface'
import { IEmailTemplateFields } from '../../../schema/generated/contentful'
import TableHeadTemplates from './TableHeadTemplates'
import TemplateOptions from '../../emailTemplates/TemplateOptions'
import { descendingComparator } from './CandTable'

/**
 * Sorts two values by order
 * @param order - The order to sort by, either desc or asc
 * @param orderBy - Which type to order by
 * @returns Elements in descending or ascending order
 */
function getComparator<Key extends keyof never>(
  order: Order,
  orderBy: Key
): (
  a: {
    fields: {
      [key in Key]: number | string | string[] | Asset | Document
    }
  },
  b: {
    fields: {
      [key in Key]: number | string | string[] | Asset | Document
    }
  }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a.fields, b.fields, orderBy)
    : (a, b) => -descendingComparator(a.fields, b.fields, orderBy)
}

/**
 * The TemplateTable props
 */
interface ApplicantsTableProps {
  // Which header cells should be displayed and sortable
  headCells: HeadCellTemplates[]
  // Content of the table, each row is mapped from this field
  templates: Entry<IEmailTemplateFields>[]
}

/**
 * A table component which can be utilized in all tables where the contents are email templates (IEmailTemplateFields)
 * @param props - The component props, in this case which header cells should be displayed and the content rows
 * @returns A table component displaying templates and template settings such as editing and deletion
 */
const TemplateTable: React.FunctionComponent<ApplicantsTableProps> = (
  props: ApplicantsTableProps
) => {
  const { headCells, templates } = props

  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] =
    React.useState<keyof IEmailTemplateFields>('name')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  /**
   * Clicking on a header cell changes the sort direction
   * @param event - On click (mouseclick)
   * @param property - Which property's sort is being changed (header cell)
   */
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IEmailTemplateFields
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  /**
   * Creates a date object from a string and formats it to the 'dd.mm.yyyy' format
   * @param date - A date string
   * @returns A corecctly formatted date
   */
  const getFormattedDate = (date: string | undefined) => {
    if (date) {
      const dateObj = new Date(date)
      const yyyy = dateObj.getFullYear()
      let mm: string | number = dateObj.getMonth() + 1 // Months start at 0!
      let dd: string | number = dateObj.getDate()

      if (dd < 10) dd = `0${dd}`
      if (mm < 10) mm = `0${mm}`

      return `${dd}.${mm}.${yyyy}`
    }
  }

  /**
   * Switches to a new page
   * @param event - The event initializing change of page, i.e. mouseclick
   * @param newPage - The new page to change to
   */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  /**
   * Sets how many rows the page should display
   * @param event - The event initializing change of page, i.e. mouseclick
   */
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - templates.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <TableHeadTemplates
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {templates
                .slice()
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((template, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow hover tabIndex={-1} key={template.sys.id}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                        sx={{
                          fontWeight: 700,
                          fontSize: '14px',
                          color: theme.palette.text.primary,
                        }}
                      >
                        {template.fields.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 400,
                          fontSize: '14px',
                          color: theme.palette.text.primary,
                        }}
                      >
                        {template.sys.updatedAt ? (
                          <>{getFormattedDate(template.sys.updatedAt)}</>
                        ) : (
                          <>{getFormattedDate(template.sys.createdAt)}</>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 400,
                          fontSize: '14px',
                          color: theme.palette.text.primary,
                        }}
                      >
                        {template.fields.createdBy}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 400,
                          fontSize: '14px',
                          color: theme.palette.text.primary,
                        }}
                      >
                        <TemplateOptions template={template} />
                      </TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={templates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}

export default TemplateTable
