import { Button, Menu, MenuItem, Slider } from '@mui/material'
import * as React from 'react'
import './ExperienceSelector.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface IExperienceSelectorProps {
  changed(years: number): void
}
function ExperienceSelector(props: IExperienceSelectorProps) {
  const { changed } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [experience, setExperience] = React.useState<number>(0)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleExperience = (
    event: React.SyntheticEvent | Event,
    value: number | Array<number>
  ) => {
    if (typeof value === 'number') {
      setExperience(value)
      changed(value)
    }
  }
  return (
    <>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        id="experienceBtn"
      >
        {experience > 0 ? `${experience} års erfaring` : 'Ingen erfaring'}
        <KeyboardArrowDownIcon
          fontSize="small"
          sx={{ paddingLeft: '0.5rem' }}
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem style={{ width: '257px', display: 'block' }}>
          <div style={{ fontSize: '.9em' }}>Minimum års erfaring</div>
          <div>
            <Slider
              defaultValue={0}
              step={1}
              min={0}
              max={40}
              aria-label="Small"
              valueLabelDisplay="auto"
              color="secondary"
              onChange={handleExperience}
              value={experience}
            />
          </div>
        </MenuItem>
      </Menu>
    </>
  )
}

export default ExperienceSelector
