import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { queryClient } from '..'
import { changeEmployeAccessToApplication } from '../api/employees'

import Access from '../components/admin/Access'
import Password from '../components/admin/Password'
import AlertAction, { AlertTypes } from '../components/Alert/AlertAction'
import { AlertContext } from '../components/Alert/AlertContext'
import { delay } from '../utils/utils'

/**
 * @returns Admin page
 */
const Admin: React.FunctionComponent = () => {
  const [, dispatch] = React.useContext(AlertContext)
  /**
   * Mutation for changing access to job applications
   */
  const setAccessMutation = useMutation(
    (employee: {
      name: string
      id: string
      accessToJobApplications: boolean
    }) => changeEmployeAccessToApplication(employee.id),
    {
      onSuccess: async (data, variables) => {
        delay(300).then(async () => {
          await queryClient.invalidateQueries(['employees'])
        })
        dispatch(
          AlertAction.showAlert(
            variables.accessToJobApplications
              ? `Fjernet tilgangen til ${variables.name}`
              : `Gav tilgang til ${variables.name}`,
            AlertTypes.success
          )
        )
      },
      onError: async (data, variables) => {
        await queryClient.invalidateQueries(['employees'])
        dispatch(
          AlertAction.showAlert(
            `En feil oppstod ved fjerning av tilgangen til personen med id: "${variables}", prøv igjen senere.`,
            AlertTypes.error
          )
        )
      },
    }
  )

  return (
    <div
      style={{
        display: 'flex',
        lineHeight: '24px',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        minWidth: '300px',
      }}
    >
      <Password />
      <Access setAccessMutation={setAccessMutation} />
    </div>
  )
}

export default Admin
