import CloseIcon from '@mui/icons-material/Close'
import { IconButton, LinearProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { EntryCollection } from 'contentful'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { checkIfAppPasswordSet } from '../../../api/api'
import { getCandidate } from '../../../api/candidate'
import { getEmailTemplates } from '../../../api/email'
import { IEmailTemplateFields } from '../../../schema/generated/contentful'
import './SendMailDialog.css'
import { SendMailHandler } from './SendMailHandler'
import SelectTemplate from './TemplateSelector'
import { queryClient } from '../../..'

export enum SEND_MAIL_DLG_CONTEXT {
  REJECTION,
  SIMPLE,
}
export interface DialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose } = props
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} style={{ borderBottom: '1px solid gray' }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

function SendMailDialog({
  open,
  candidatesIds,
  handleSendMailClose,
  context,
}: {
  open: boolean
  handleSendMailClose: (
    res?:
      | undefined
      | {
          name: string
          candidateId: string
          emailSent: boolean
          email: string
        }[]
  ) => void
  candidatesIds: string[]
  context: SEND_MAIL_DLG_CONTEXT
}) {
  const [templates, setTemplates] =
    useState<null | EntryCollection<IEmailTemplateFields>>(null)

  const [selectedTemplate, setSelectedTemplates] =
    useState<null | IEmailTemplateFields>(null)

  const [feedbackOption, setFeedbackOption] = React.useState('sendMail')
  const [emailReceipt, setEmailReceipt] = useState<
    | null
    | {
        name: string
        email: string
        emailSent: boolean
        manuallyAlerted?: boolean
        candidateId: string
      }[]
  >()
  const [sendingEmail, setSendingEmail] = useState(false)
  const handleOptionSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTemplates(null)
    setFeedbackOption((event.target as HTMLInputElement).value)
  }

  const { data, isLoading } = useQuery(['templates'], getEmailTemplates)
  const [isEmailPasswordSet, setIsEmailPasswordSet] = useState(false)
  useEffect(() => {
    checkIfAppPasswordSet().then((res) => {
      setIsEmailPasswordSet(res)
    })

    if (!isLoading && data) {
      setTemplates(data)
    }
  }, [data, isLoading, isEmailPasswordSet])

  if (isLoading) {
    return <>Laster...</>
  }

  function clearState() {
    setSendingEmail(false)
    setEmailReceipt(null)
    setSelectedTemplates(null)
    setFeedbackOption('sendMail')
  }

  const closeDlg = () => {
    clearState()
    const receipt = emailReceipt?.slice()
    handleSendMailClose(receipt)
  }

  let emailContent = ''
  let emailSubject = ''
  function contentUpdated(args: { subject: string; content: string }) {
    emailContent = args.content
    emailSubject = args.subject
  }

  const handleSendMail = () => {
    setSendingEmail(true)
    setEmailReceipt([])
    if (feedbackOption === 'sendMail') {
      const subject = emailSubject || selectedTemplate?.subject || ''
      const content = emailContent || selectedTemplate?.content || ''
      SendMailHandler.sendMail(candidatesIds, subject, content).then(
        (emailReceipt) => {
          setSendingEmail(false)
          setEmailReceipt(emailReceipt)
        }
      )
      queryClient.invalidateQueries(['utlysning'])
    } else {
      const getCandidates$ = candidatesIds.map((candidateId: string) => {
        return getCandidate(candidateId)
      })
      Promise.all(getCandidates$).then((result) => {
        let emailSent = false
        if (feedbackOption === 'manuallyAlerted') {
          // The responsible person has sent email manually.
          // Hence we flag candidate with email sent.
          emailSent = true
        }
        const receipt = result.map((r) => {
          return {
            name: r.fields.name,
            email: r.fields.email,
            emailSent,
            manuallyAlerted: feedbackOption === 'manuallyAlerted',
            candidateId: r.sys.id,
          }
        })
        setEmailReceipt(receipt)
        setSendingEmail(false)
      })
      queryClient.invalidateQueries(['utlysning'])
    }
  }

  const receiptInfo = emailReceipt?.map((r) => {
    return (
      <tr key={r.candidateId}>
        <td>{r.name}</td>
        <td>{r.email}</td>
        <td>
          {r.emailSent && !r.manuallyAlerted
            ? 'Epost sendt'
            : 'Ingen mail sent'}
          {r.manuallyAlerted ? ' (varslet manuelt)' : ''}
        </td>
      </tr>
    )
  })

  if (!isEmailPasswordSet) {
    return (
      <Dialog
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={() => closeDlg()}
        maxWidth="lg"
      >
        <DialogTitle>Manglende konfigurasjon</DialogTitle>

        <DialogContent>
          <br />
          Din bruker er ikke konfigurert for å sende epost. Konktakt
          systemadministrator.
        </DialogContent>
      </Dialog>
    )
  }

  if (sendingEmail) {
    return (
      <Dialog open>
        <DialogTitle>Sender varsling...</DialogTitle>
        <DialogContent>
          <br />
          <div style={{ width: '256px' }}>
            <LinearProgress color="secondary" />
          </div>
        </DialogContent>
      </Dialog>
    )
  }
  if (emailReceipt?.length) {
    return (
      <Dialog
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={() => closeDlg()}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          // id="sendMailDlg-title"
          onClose={handleSendMailClose}
        >
          <div className="header">
            <strong>Utført</strong>
          </div>
        </BootstrapDialogTitle>
        <DialogContent>
          <br />
          <table className="receiptTable">
            <thead>
              <tr>
                <th>Navn</th>
                <th>Epost</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>{receiptInfo}</tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <div style={{ paddingBottom: '16px' }}>
            <Button
              variant="darkBlueRounded"
              onClick={() => closeDlg()}
              sx={{ marginRight: '1rem' }}
            >
              Lukk
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  if (context === SEND_MAIL_DLG_CONTEXT.REJECTION) {
    return (
      <Dialog
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={() => closeDlg()}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          // id="sendMailDlg - title"
          onClose={handleSendMailClose}
        >
          <div className="header">
            <strong>
              Avvis <span style={{ fontSize: '1.1em' }} />
              {candidatesIds.length > 1
                ? `${candidatesIds.length} kandidater`
                : 'kandidat'}
            </strong>
          </div>
        </BootstrapDialogTitle>
        <DialogContent>
          <div style={{ paddingTop: '16px' }}>
            <p>
              Ønsker du å sende avslag på e-post med en gang, eller gi beskjed
              til kandidaten senere?
            </p>
            <div style={{ paddingLeft: '8px', paddingTop: '8px' }}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="sendMail"
                  name="radio-buttons-group"
                  onChange={handleOptionSelected}
                >
                  <FormControlLabel
                    value="sendMail"
                    control={<Radio />}
                    label="Send varsling via e-post"
                  />
                  {feedbackOption === 'sendMail' && (
                    <div
                      style={{
                        background: '#F1F5FC',
                        padding: '8px 16px 16px 16px',
                        borderRadius: '8px',
                        fontSize: '13px',
                        marginLeft: '32px',
                        lineHeight: '21px',
                      }}
                    >
                      Velg mal fra listeboksen under. Din epost signatur blir
                      lagt på mailen automatisk ved utsendelse. <br />
                      Tips! Trykk krøllparantes (Option + Shif + 8) for å få opp
                      replacement tokens <br />
                      <strong>
                        Kandidaten(e) vil bli market som som varslet med avslag
                        i systemet.
                      </strong>
                    </div>
                  )}
                  <FormControlLabel
                    value="later"
                    control={<Radio />}
                    label="Gi beskjed senere."
                  />
                  <FormControlLabel
                    value="manuallyAlerted"
                    control={<Radio />}
                    label="Kandidat(er) er varslet med avslag manuelt."
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <SelectTemplate
                feedbackOption={feedbackOption}
                templates={templates}
                setSelectedTemplates={setSelectedTemplates}
                selectedTemplate={selectedTemplate}
                contentUpdated={(args: { subject: string; content: string }) =>
                  contentUpdated(args)
                }
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ paddingBottom: '16px' }}>
            <Button
              variant="greyDefault"
              onClick={() => closeDlg()}
              sx={{ marginRight: '1rem' }}
            >
              Avbryt
            </Button>
            <Button
              variant="darkBlueRounded"
              onClick={handleSendMail}
              sx={{ marginRight: '1rem' }}
              disabled={feedbackOption === 'sendMail' && !selectedTemplate}
            >
              Send avvisning
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
  return (
    <Dialog
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={open}
      onClose={() => closeDlg()}
      fullWidth
      maxWidth="lg"
    >
      <BootstrapDialogTitle
        // id="sendMailDlg - title"
        onClose={handleSendMailClose}
      >
        <div className="header">Send e-post</div>
      </BootstrapDialogTitle>
      <DialogContent>
        <div style={{ paddingTop: '16px' }}>
          Skriv en e-post til kandidaten.
          <br />
          <p>
            Din epost signatur blir lagt på mailen automatisk ved utsendelse.
          </p>
          Tips! Trykk krøllparantes (Option + Shif + 8) for å få opp replacement
          tokens <br />
          <div>
            <SelectTemplate
              feedbackOption={feedbackOption}
              templates={templates}
              setSelectedTemplates={setSelectedTemplates}
              selectedTemplate={selectedTemplate}
              contentUpdated={(args: { subject: string; content: string }) =>
                contentUpdated(args)
              }
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ paddingBottom: '16px' }}>
          <Button
            variant="greyDefault"
            onClick={() => closeDlg()}
            sx={{ marginRight: '1rem' }}
          >
            Avbryt
          </Button>
          <Button
            variant="darkBlueRounded"
            onClick={handleSendMail}
            sx={{ marginRight: '1rem' }}
            disabled={feedbackOption === 'sendMail' && !selectedTemplate}
          >
            Send
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default SendMailDialog
