import React from 'react'

export const ChrisTab = ({ emptyStateImg }: { emptyStateImg: string }) => {
  return (
    <div id="emptyTable">
      <img src={emptyStateImg} alt="Bilde av Christian" />
      <div>
        <span
          style={{
            fontSize: '1.6em',
            fontWeight: '600',
          }}
        >
          Ingen fremtidige Finkere funnet :/
        </span>
        <br />
        <span
          style={{
            fontSize: '1.2em',
            fontWeight: '600',
          }}
        >
          Prøv å endre søket ditt.
        </span>
        <br />
      </div>
      <img
        src={emptyStateImg}
        alt="Bilde av Christian"
        style={{ transform: 'scaleX(-1)' }}
      />
    </div>
  )
}
