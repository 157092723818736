import * as React from 'react'
import Box from '@mui/material/Box'
import { Button, CircularProgress } from '@mui/material'
import { Entry } from 'contentful'
import { useMutation } from '@tanstack/react-query'
import { Editor, EditorState } from 'draft-js'
import { IKandidatFields } from '../../../schema/generated/contentful'
import { queryClient } from '../../..'
import { getLoggedInUser } from '../../../api/api'
import { INote } from '../../../interfaces/note.interface'
import { AlertContext } from '../../Alert/AlertContext'
import AlertAction, { AlertTypes } from '../../Alert/AlertAction'
import { delay } from '../../../utils/utils'
import { newCandidateNote } from '../../../api/note'

const AddNewNote = ({
  candidate,
  utlysningId,
}: {
  candidate: Entry<IKandidatFields>
  utlysningId: string
}) => {
  const [, dispatch] = React.useContext(AlertContext)
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  )

  const newNoteMutation = useMutation(
    (newNote: INote) => newCandidateNote(candidate.sys.id, newNote),
    {
      onSuccess: async () => {
        delay(300).then(async () => {
          await queryClient.invalidateQueries(['utlysning', utlysningId])
        })
        dispatch(AlertAction.showAlert('Notat lagret.', AlertTypes.success))
      },
      onError: async () => {
        await queryClient.invalidateQueries(['utlysning', utlysningId])
        dispatch(
          AlertAction.showAlert(
            'En feil oppstod, prøv igjen.',
            AlertTypes.error
          )
        )
      },
    }
  )

  const handleNewNote = async () => {
    const newNote: INote = {
      timestamp: new Date().toISOString(),
      userFullname: getLoggedInUser().name,
      userEmail: getLoggedInUser().email,
      userId: getLoggedInUser().id,
      userPictureURL: getLoggedInUser().profilePicture?.url,
      content: editorState.getCurrentContent().getPlainText(),
    }

    await newNoteMutation.mutateAsync(newNote)
    setEditorState(EditorState.createEmpty())
  }

  const editor = React.useRef<Editor>(null)
  function focusEditor() {
    if (editor.current !== null) editor.current.focus()
  }

  return (
    <Box
      sx={{
        borderTop: '2px solid #DADADA',
        background: ' #F4F4F4',
        padding: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '170px',
        overflow: 'scroll',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          borderTop: '2px solid #DADADA',
          padding: '16px',
          height: '100%',
          width: '100%',
          overflow: 'scroll',
          backgroundColor: 'white',
          boxSizing: 'border-box',
        }}
        onClick={() => focusEditor()}
      >
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={setEditorState}
          placeholder="Skriv et notat her!"
        />
      </Box>
      <Button
        variant="darkBlueRounded"
        sx={{
          minWidth: 'fit-content',
          alignSelf: 'center',
        }}
        onClick={handleNewNote}
        disabled={
          newNoteMutation.isLoading ||
          editorState.getCurrentContent().getPlainText().length === 0
        }
      >
        {newNoteMutation.isLoading ? (
          <CircularProgress size={12} />
        ) : (
          <>Lagre notat</>
        )}
      </Button>
    </Box>
  )
}

export default AddNewNote
