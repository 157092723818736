import { LinearProgress } from '@mui/material'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import React, { useEffect, useState } from 'react'
import { checkIfAppPasswordSet } from '../../api/api'
import { updateEmployeeAppPwd } from '../../api/employees'

/**
 *
 * @returns The job postings page
 */

const Password: React.FunctionComponent = () => {
  const [password, setPassword] = useState<string>('')
  const [canSave, setCanSave] = useState(false)
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)

  const checkPwdSet = () => {
    checkIfAppPasswordSet().then((passwordIsSet: boolean) => {
      if (passwordIsSet) {
        setPassword('PasswordIsSet')
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    checkPwdSet()
  }, [])

  const handleSave = () => {
    setSaving(true)
    updateEmployeeAppPwd(password)
      .then(() => {
        setTimeout(() => {
          setSaving(false)
          setCanSave(false)
        }, 1200)
      })
      .catch(() => {
        setSaving(false)
        setCanSave(false)
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        lineHeight: '24px',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <div style={{ marginTop: '64px', padding: '16px' }}>
        <div>
          <h3>Applikasjons passord</h3>
          For å kunne sende e-post fra din Fink gmail bruker må du opprette et
          applikasjonspassord.
          <br />
          <a
            href="https://support.google.com/mail/answer/185833?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            Følg denne oppskriften
          </a>
        </div>
        <br />
        {saving && <LinearProgress />}
        {!loading && (
          <TextField
            style={{ width: '100%' }}
            id="filled-basic"
            label="Password"
            variant="filled"
            defaultValue={password}
            type="password"
            onChange={(e) => {
              const val = e.currentTarget.value
              setPassword(val)
              setCanSave(true)
            }}
          />
        )}
        <div
          style={{
            marginTop: '16px',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Button
            onClick={handleSave}
            sx={{ marginRight: '1rem' }}
            disabled={!canSave || password.length === 0}
            variant="darkBlueRounded"
          >
            Lagre
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Password
