import * as React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

/**
 * A custom styled tooltip
 */
const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2E3F71',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#2E3F71',
    color: '#F1F5FC',
  },
}))

export default DarkTooltip
