import { createTheme } from '@mui/material'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    navbar: true
    greyDefault: true
    roundedInfo: true
    squaredSuccess: true
    squaredError: true
    listActionSuccess: true
    listActionInfo: true
    listActionError: true
    darkBlueRounded: true
    darkRedRounded: true
  }
}

const font = "'Nunito Sans', 'Roboto', 'Helvetica', 'Arial'"

const theme = createTheme({
  palette: {
    primary: {
      main: '#454D60',
      dark: '#33373D',
      light: '#8F949F',
    },
    secondary: {
      main: '#B7C3DB',
    },
    success: {
      main: '#DDEDD7',
      contrastText: '#366127',
    },
    warning: {
      main: '#FFEBE2',
      contrastText: '#CE6343',
    },
    error: {
      main: '#7A153A',
      contrastText: '#FFEBE2',
    },
    info: {
      main: '#F1F5FC',
      contrastText: '#5D719D',
    },
    background: {
      default: '#F8F9FA',
    },
    text: {
      primary: '#33373D',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: font,
    h1: {
      fontWeight: 900,
      fontSize: 36,
    },
    h2: {
      fontSize: 18,
      fontWeight: 800,
    },
    button: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: font,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: '200',
          padding: '0px 10px',
          width: 'fit-content',
          minWidth: 0,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#8F949F',
            cursor: 'pointer',
          },
        },
      },
      variants: [
        {
          // knappene i navbaren
          props: { variant: 'navbar' },
          style: {
            backgroundColor: '#454D60',
            borderRadius: '30px',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#8F949F',
            },
            '&:active': {
              backgroundColor: '#33373D',
            },
          },
        },
        {
          // firkantede tags success
          props: { variant: 'squaredSuccess' },
          style: {
            backgroundColor: '#DDEDD7',
            color: '#366127',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: 400,
            padding: '0 5px 0 5px',
            '&:hover': {
              backgroundColor: '#DDEDD7',
              cursor: 'default',
            },
          },
        },
        {
          // firkantede tags error
          props: { variant: 'squaredError' },
          style: {
            backgroundColor: '#FFEBE2',
            color: '#7A153A',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: 400,
            padding: '0 5px 0 5px',
            '&:hover': {
              backgroundColor: '#FFEBE2',
              cursor: 'default',
            },
          },
        },
        {
          // sirkulære tags info
          props: { variant: 'roundedInfo' },
          style: {
            backgroundColor: '#E3ECF9',
            border: '1px solid #bfd6f8',
            color: '#2E3F71',
            fontSize: '13px',
            fontWeight: 400,
            borderRadius: '4px',
            margin: '2px',
            '&:hover': {
              backgroundColor: '#E3ECF9',
              cursor: 'default',
            },
          },
        },
        {
          // grå default knapp (brukt i searchbar, "ny stilling" osv.)
          props: { variant: 'greyDefault' },
          style: {
            borderRadius: '4px',
            backgroundColor: '#E8EAEE',
            color: '#33373D',
            border: '1px solid #bbb',
            fontSize: '16px',
            padding: '9px 18px',
            height: 'fit-content',
            maxHeight: '42px',
          },
        },
        {
          // mørkeblå profil rounded
          props: { variant: 'darkBlueRounded' },
          style: {
            borderRadius: '4px',
            backgroundColor: '#5D719D',
            border: '1px solid #37435e',
            color: '#F1F5FC',
            fontSize: '16px',
            padding: '9px 18px',
            height: 'fit-content',
            maxHeight: '42px',
            '&:hover': {
              backgroundColor: '#4d5f87',
            },
            '&:disabled': {
              backgroundColor: '#ccc',
            },
          },
        },
        {
          // mørkerød avslag rounded
          props: { variant: 'darkRedRounded' },
          style: {
            borderRadius: '4px',
            backgroundColor: '#7A153A',
            border: '1px solid #440c21',
            color: '#FFEBE2',
            fontSize: '16px',
            padding: '9px 18px',
            height: 'fit-content',
            maxHeight: '42px',
          },
        },
        {
          // list actions success
          props: { variant: 'listActionSuccess' },
          style: {
            borderRadius: 0,
            backgroundColor: '#FFFFFF',
            color: '#366127',
            '&:hover': {
              backgroundColor: '#DDEDD7',
            },
          },
        },
        {
          // list actions info
          props: { variant: 'listActionInfo' },
          style: {
            borderRadius: 0,
            backgroundColor: '#FFFFFF',
            color: '#5D719D',
            '&:hover': {
              backgroundColor: '#F1F5FC',
            },
          },
        },
        {
          // list actions error
          props: { variant: 'listActionError' },
          style: {
            borderRadius: 0,
            backgroundColor: '#FFFFFF',
            color: '#7A153A',
            '&:hover': {
              backgroundColor: '#FFEBE2',
            },
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          // color: '#F8F9FA',
          // border: '1px solid #AAB2BD',
          // '&:hover': {
          //     color: '#d5dff0',
          // },
          color: '#AAB2BD',
          '&.Mui-checked': {
            color: '#5D719D',
          },
        },
      },
    },
    MuiTooltip: {},
    MuiDialogTitle: {
      defaultProps: {
        bgcolor: '#454D60',
        color: 'white',
        fontWeight: '600',
      },
    },
  },
})

export default theme
