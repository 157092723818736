import * as React from 'react'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import theme from '../../../theme'
import CustomCheckbox from '../FinkCheckbox'
import { Order } from '../../../interfaces/types'
import { HeadCell } from '../../../interfaces/table.interface'
import { IKandidatFields } from '../../../schema/generated/contentful'

/**
 * EnhancedTable props
 */
interface EnhancedTableProps {
  // The total number of selected rows
  numSelected: number
  // Function when clicking header cell to sort in opposite order
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IKandidatFields
  ) => void
  // Function to select all rows
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  // Which order the column should be sorted by
  order: Order
  // Which type to order by (which type the column data consists of)
  orderBy: string
  // How many rows the table consists of
  rowCount: number
  // List of header cells, the columns displayed are different for the job posting page and the candidates page
  headCells: HeadCell[]
  // Which page the table is being used on
  type: 'utlysning' | 'kandidat'

  actions: JSX.Element
}

/**
 *
 * @param props - The EnhancedTable props
 * @returns Table header cells belonging to a table displaying candidates
 */
const EnhancedTableHead: React.FunctionComponent<EnhancedTableProps> = (
  props: EnhancedTableProps
) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    headCells,
    type,
    onRequestSort,
    actions,
  } = props

  /**
   * The header cell sort handler
   * @param property - Which property to sort
   * @returns Requests sort defined in the table component
   */
  const createSortHandler =
    (property: keyof IKandidatFields) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead
      sx={{
        backgroundColor: theme.palette.background.default,
        height: '40px',
      }}
    >
      <TableRow>
        <TableCell padding="checkbox" align="center">
          <CustomCheckbox
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all applicants',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={KeyboardArrowDownIcon}
              sx={{
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '13px',
                color: theme.palette.text.secondary,
                '&.Mui-active': {
                  color: theme.palette.text.secondary,
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {type === 'utlysning' && (
          <TableCell padding="checkbox">
            <div style={{ marginLeft: '12px', width: '138px' }}>{actions}</div>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
