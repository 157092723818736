import { Alert, Snackbar } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AlertTypes } from './AlertAction'
import { AlertContext } from './AlertContext'

export default function AlertPopup() {
  const [state] = useContext(AlertContext)

  const [open, setOpen] = useState(false)
  const [alertType, setAlertType] = useState<AlertTypes | undefined>(undefined)
  const [message, setMessage] = useState<string>('Some Message')

  useEffect(() => {
    const { message, type } = state.alertShower
    setAlertType(type)
    setMessage(message)
    setOpen(true)
  }, [state.alertShower])

  const handleClose = () => setOpen(false)
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
      <Alert
        icon={false}
        severity={alertType}
        onClose={handleClose}
        sx={{
          width: '100%',
          color: '#366127',
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
