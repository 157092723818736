import * as React from 'react'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import { Entry } from 'contentful'
import { Button, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import { IEmailTemplateFields } from '../../schema/generated/contentful'
import { updateEmailTemplate } from '../../api/email'

/**
 * Form props
 */
interface State {
  name: string
  subject: string
  content: string
}

/**
 * TemplateOptions props
 */
interface TemplateOptionsProps {
  // The template that is currently being edited
  template: Entry<IEmailTemplateFields>
}

/**
 * Component for editing email templates
 * @param props - The component props, in this case which template is currently being edited
 * @returns Form to edit and submit email templates
 */
const Templateform: React.FunctionComponent<TemplateOptionsProps> = (
  props: TemplateOptionsProps
) => {
  const { template } = props

  const [loading, setLoading] = React.useState(false)
  const [values, setValues] = React.useState<State>({
    name: template.fields.name,
    subject: template.fields.subject,
    content: template.fields.content,
  })

  /**
   * Applies changes to either name, subject, or content, when their input fields are changed
   * @param prop - which field is changes, either name, subject, or content
   * @returns Updates the values state
   */
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  /**
   * Send the updated template to Contentful
   */
  const updateTemplate = async () => {
    setLoading(true)
    await updateEmailTemplate(
      template.sys.id,
      values.name,
      values.subject,
      values.content
    ).then(() => setLoading(false))
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <div style={{ width: '100%' }}>
        <FormControl sx={{ m: 1, width: '50%' }} variant="outlined">
          <FormHelperText
            id="outlined-weight-helper-text"
            sx={{ fontSize: '16px', color: '#454D60' }}
          >
            Navn på mal
          </FormHelperText>
          <OutlinedInput
            id="outlined-adornment-name"
            value={values.name}
            onChange={handleChange('name')}
            aria-describedby="outlined-name-helper-text"
            inputProps={{
              'aria-label': 'name',
            }}
            style={{
              background: '#F4F4F4',
              border: '1px solid #EAEAEA',
              borderRadius: '4px',
            }}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '50%' }} variant="outlined">
          <FormHelperText
            id="outlined-subject-helper-text"
            sx={{ fontSize: '16px', color: '#454D60' }}
          >
            Emne
          </FormHelperText>
          <OutlinedInput
            id="outlined-adornment-subject"
            value={values.subject}
            onChange={handleChange('subject')}
            aria-describedby="outlined-subject-helper-text"
            inputProps={{
              'aria-label': 'subject',
            }}
            style={{
              background: '#F4F4F4',
              border: '1px solid #EAEAEA',
              borderRadius: '4px',
            }}
          />
        </FormControl>

        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
          <FormHelperText
            id="outlined-content-helper-text"
            sx={{ fontSize: '16px', color: '#454D60' }}
          >
            Innhold
          </FormHelperText>

          <OutlinedInput
            id="outlined-adornment-content"
            multiline
            value={values.content}
            onChange={handleChange('content')}
            aria-describedby="outlined-content-helper-text"
            inputProps={{
              'aria-label': 'content',
            }}
            style={{
              background: '#F4F4F4',
              border: '1px solid #EAEAEA',
              borderRadius: '4px',
            }}
          />
        </FormControl>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          marginTop: '3rem',
        }}
      >
        <Link
          style={{
            textDecoration: 'none',
          }}
          to="/maler"
          key={template?.sys.id}
        >
          <Button variant="greyDefault" sx={{ marginRight: '1rem' }}>
            Avbryt
          </Button>
        </Link>

        <Button
          variant="darkBlueRounded"
          onClick={updateTemplate}
          style={{ minHeight: '46px', minWidth: '77px' }}
        >
          {loading ? (
            <CircularProgress
              size={12}
              sx={{
                color: 'inherit',
              }}
            />
          ) : (
            <>Lagre</>
          )}
        </Button>
      </div>
    </Box>
  )
}

export default Templateform
