import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Entry } from 'contentful'
import { IKandidatFields } from '../../schema/generated/contentful'
import { humanizeTimestamp } from '../../utils/utils'

export const CandidateInformationView = ({
  candidate,
}: {
  candidate: Entry<IKandidatFields>
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        color: '#666666',
        padding: '0.5rem 0 0.5rem 0',
        gap: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
        }}
      >
        Telefonnr.
        <br />
        <span
          style={{
            color: 'black',
            fontSize: '16px',
          }}
        >
          {candidate.fields.telephoneNumber
            ? candidate.fields.telephoneNumber
            : 'Ingen telefonnummer oppgitt'}
        </span>
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
        }}
      >
        E-post
        <br />
        <span
          style={{
            color: 'black',
            fontSize: '16px',
          }}
        >
          {candidate.fields.email}
        </span>
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          paddingRight: '1.5rem',
        }}
      >
        Års erfaring
        <br />
        <span
          style={{
            color: 'black',
            fontSize: '16px',
          }}
        >
          {candidate.fields.yearsExperience
            ? `${candidate.fields.yearsExperience} Års Erfaring`
            : 'N/A'}
        </span>
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
        }}
      >
        {candidate.fields.graduationYear > new Date().getFullYear()
          ? 'Uteksamineres'
          : 'Uteksaminert'}
        <br />
        <span
          style={{
            color: 'black',
            fontSize: '16px',
          }}
        >
          {candidate.fields.graduationYear}
        </span>
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
        }}
      >
        Søknadsdato
        <br />
        <span
          style={{
            color: 'black',
            fontSize: '16px',
          }}
        >
          {candidate.fields.applicationDate?.split('T')[0]}
        </span>
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
        }}
      >
        Søknad mottat
        <br />
        <span
          style={{
            color: 'black',
            fontSize: '16px',
          }}
        >
          {humanizeTimestamp(new Date(candidate.fields.applicationDate))}
        </span>
      </Typography>
    </Box>
  )
}
