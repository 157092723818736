import { Button, Menu, MenuItem } from '@mui/material'
import * as React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import './TagsSelector.css'
import Box from '@mui/system/Box'

interface ITagSelector {
  tags: string[]
  changed(tags: string[]): void
  selectedTags: string[]
}
function TagSelector(props: ITagSelector) {
  const { tags, changed, selectedTags } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const menuItems = (tags || []).map((t, index) => {
    return (
      <MenuItem
        onClick={() => {
          const currentTags = [...(selectedTags || []), t]
          changed(currentTags)
        }}
        disabled={selectedTags ? selectedTags?.indexOf(t) > -1 : false}
        key={`${t}_${index.toString}`}
      >
        {t}
      </MenuItem>
    )
  })
  return (
    <div>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        id="tagsBtn"
      >
        {selectedTags.length || null} Tags
        <KeyboardArrowDownIcon
          fontSize="small"
          sx={{ paddingLeft: '0.5rem' }}
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems.length ? (
          menuItems
        ) : (
          <Box
            sx={{
              fontSize: '12px',
              padding: '4px',
              color: 'gray',
            }}
          >
            Ingen tags definert for denne utlysningen.
            <br />
            Tags settes opp i contentful.
          </Box>
        )}
      </Menu>
    </div>
  )
}

export default TagSelector
