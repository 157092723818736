import * as React from 'react'
import Box from '@mui/material/Box'
import { Entry } from 'contentful'
import { v4 as uuidv4 } from 'uuid'
import { INote } from '../../../interfaces/note.interface'
import { IKandidatFields } from '../../../schema/generated/contentful'
import NoteView from './NoteView'

const sortOnTimestamp = (notes: INote[]) => {
  if (notes === undefined) return []
  return notes.sort((a, b) => {
    return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  })
}

const NotesView = ({
  candidate,
  utlysningId,
}: {
  candidate: Entry<IKandidatFields>
  utlysningId: string
}) => {
  const { notes } = candidate.fields
  const sortedNotes = sortOnTimestamp(notes)
  return (
    <Box
      sx={{
        background: '#F8F9FA',
        borderRadius: '16px 16px 0 0',
        boxSizing: 'border-box',
        overflow: 'scroll',
        height: '100%',
      }}
    >
      {(notes === undefined || notes.length === 0) && (
        <Box
          sx={{
            boxSizing: 'border-box',
            display: 'flex',
            paddingX: '32px',
            overflow: 'scroll',
            justifyContent: 'center',
          }}
        >
          Ingen notater
        </Box>
      )}
      {notes &&
        sortedNotes.map((note: INote) => {
          return (
            <NoteView
              key={`${JSON.stringify(note)} - ${uuidv4}`}
              note={note}
              candidate={candidate}
              utlysningId={utlysningId}
            />
          )
        })}
    </Box>
  )
}

export default NotesView
