import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Entry } from 'contentful'
import { Box } from '@mui/system'
import { IEmailTemplateFields } from '../../schema/generated/contentful'
import Templateform from './TemplateForm'
import { getEmailTemplate } from '../../api/email'

/**
 * Edit template page which contains the TemplateForm component
 * @returns Component for editing email templates
 */
const EditTemplate: React.FunctionComponent = () => {
  const { id } = useParams()

  const [loading, setLoading] = React.useState(false)
  const [template, setTemplate] = useState<null | Entry<IEmailTemplateFields>>(
    null
  )

  useEffect(() => {
    setLoading(true)

    if (id) {
      getEmailTemplate(id).then((res) => {
        setTemplate(res)
        setLoading(false)
      })

      sessionStorage.setItem('params', id)
    }
  }, [id])

  if (loading) {
    return <>Loading...</>
  }

  return (
    <main style={{ padding: '1rem', margin: '0 auto', width: '80%' }}>
      <Link
        style={{
          textDecoration: 'none',
        }}
        to="/maler"
        key={template?.sys.id}
      >
        <p
          style={{
            color: '#2E3F71',
            fontSize: '13px',
            marginBottom: '-1rem',
          }}
        >
          Tilbake
        </p>
      </Link>
      <h1>Rediger e-post-mal</h1>
      <Box
        sx={{
          background: '#FFFFFF',
          borderRadius: '8px',
          border: ' 1px solid #EAEAEA',
          padding: '2rem',
        }}
      >
        {template && <Templateform template={template} />}
      </Box>
    </main>
  )
}

export default EditTemplate
