import MenuIcon from '@mui/icons-material/Menu'
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import { makeStyles } from '@mui/styles'
import * as React from 'react'
import { Link, Outlet, NavLink } from 'react-router-dom'
import { ReactComponent as FinkIcon } from '../assets/icons/FinkIcon.svg'
import { ReactComponent as KandidatIcon } from '../assets/icons/Kandidat.svg'
import { ReactComponent as MalerIcon } from '../assets/icons/Maler.svg'
import { ReactComponent as UtlysningIcon } from '../assets/icons/Utlysning.svg'

const useStyles = makeStyles({
  appbar: {
    flexDirection: 'row',
  },

  primary: {
    backgroundColor: '#454D60',
    height: 90,
  },

  horizontal: {
    display: 'flex',
    flexDirection: 'row',
  },

  buttonGroup: {
    paddingTop: 5,
    width: '100%',
  },

  typography: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    color: '#FFFFFF',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left',
    paddingTop: 5,
    paddingLeft: 8,
  },

  button: {
    height: 40,
    borderRadius: '30px!important',
    padding: '10px 20px',
  },

  iconSize: {
    color: '#8F949F',
  },

  Link: {
    pointerEvents: 'none',
    textDecoration: 'none',
  },
})

/**
 *
 * @returns A navbar with buttons to navigate the different pages of the website
 */
const NavBar: React.FunctionComponent = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const container =
    window !== undefined ? () => window.document.body : undefined

  const classes = useStyles()

  const navigationItems = [
    {
      linkTo: '/utlysninger',
      icon: <UtlysningIcon className={classes.iconSize} />,
      label: 'Utlysninger',
    },
    {
      linkTo: '/kandidater',
      icon: <KandidatIcon className={classes.iconSize} />,
      label: 'Kandidater',
    },
    {
      linkTo: '/maler',
      icon: <MalerIcon className={classes.iconSize} />,
      label: 'Maler',
    },
    {
      linkTo: '/admin',
      icon: <MalerIcon className={classes.iconSize} />,
      label: 'Admin',
    },
  ]

  const desktopNav = navigationItems.map((e) => {
    return (
      <NavLink
        to={e.linkTo}
        key={e.linkTo}
        className={({ isActive }) => (isActive ? 'active-link' : '')}
        style={{ textDecoration: 'none' }}
      >
        <Button className={`${classes.button} ${e.label}`} startIcon={e.icon}>
          <p className={classes.typography}>{e.label}</p>
        </Button>
      </NavLink>
    )
  })

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box
        sx={{
          my: 2,
          background: '#454D60',
          margin: 0,
          height: '64px',
          boxSizing: 'border-box',
          lineHeight: '88px',
        }}
      >
        <FinkIcon />
      </Box>
      <Divider />
      <List>
        {navigationItems
          .filter((i) => i.label !== 'Admin' && i.label !== 'Maler')
          .map((item) => (
            <ListItem key={item.label} disablePadding>
              <Link
                to={item.linkTo}
                style={{
                  textDecoration: 'none',
                  color: 'white',
                }}
              >
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        {navigationItems
          .filter((i) => i.label === 'Admin' || i.label === 'Maler')
          .map((item) => (
            <ListItem key={item.label} disablePadding disabled>
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Box>
  )
  return (
    <Box>
      <AppBar position="static" component="nav">
        <Toolbar
          variant="dense"
          className={classes.primary}
          sx={{ height: { xs: 'auto', sm: '90px' } }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
            <Typography
              sx={{
                paddingLeft: '16px',
                fontSize: '18px',
                fontWeight: 700,
                boxSizing: 'border-box',
              }}
            >
              Fink
            </Typography>
          </IconButton>
          <div className={classes.horizontal}>
            <Box
              sx={{
                '& button': { ml: 4 },
                display: { xs: 'none', sm: 'block' },
              }}
              className={classes.buttonGroup}
            >
              <Link to="/utlysninger">
                <Button
                  style={{
                    pointerEvents: 'none',
                  }}
                >
                  <FinkIcon />
                </Button>
              </Link>
              {desktopNav}
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: 205,
              background: '#454D60',
              color: 'white',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Outlet />
    </Box>
  )
}

export default NavBar
