import {
  HeadCell,
  HeadCellTemplates,
} from '../../../interfaces/table.interface'

/**
 * The header cells in the candidate table displayed in the job posting page
 */
export const headCellsUtlysninger: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'NAVN',
  },
  {
    id: 'applicationDate',
    numeric: true,
    disablePadding: false,
    label: 'SØKT',
  },
  {
    id: 'graduationYear',
    numeric: true,
    disablePadding: false,
    label: 'UTEKSAMINERT',
  },
  {
    id: 'yearsExperience',
    numeric: true,
    disablePadding: false,
    label: 'ERFARING (ÅR)',
  },
  {
    id: 'tags',
    numeric: false,
    disablePadding: false,
    label: 'TAGS',
  },
  {
    id: 'receivedEmail',
    numeric: true,
    disablePadding: false,
    label: 'E-POST SENDT',
  },
  {
    id: 'responsiblePerson',
    numeric: false,
    disablePadding: false,
    label: 'SAKSBEHANDLER',
  },
]

/**
 * The header cells in the candidate table displayed in the candidates page
 */
export const headCellsCandidates: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'NAVN',
  },
  {
    id: 'yearsExperience',
    numeric: true,
    disablePadding: false,
    label: 'ERFARING (ÅR)',
  },
  {
    id: 'graduationYear',
    numeric: true,
    disablePadding: false,
    label: 'UTEKSAMINERINGSÅR',
  },
  {
    id: 'tags',
    numeric: false,
    disablePadding: false,
    label: 'TAGS',
  },
  {
    id: 'applicationDate',
    numeric: true,
    disablePadding: false,
    label: 'SISTE AKTIVITET',
  },
]

/**
 * The header cells of the email templates table
 */
export const headCellsTemplates: HeadCellTemplates[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAVN PÅ MAL',
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'DATO OPPDATERT',
  },
  {
    id: 'createdBy',
    numeric: false,
    disablePadding: false,
    label: 'LAGET AV',
  },
]
