import { Button, Menu, MenuItem } from '@mui/material'
import { Box } from '@mui/system'
import { Entry } from 'contentful'
import React from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import SendToStageMenu from './SendToStageMenu'
import { IKandidatFields } from '../../schema/generated/contentful'
import AlertAction, { AlertTypes } from '../Alert/AlertAction'
import { AlertContext } from '../Alert/AlertContext'
import { SEND_MAIL_DLG_CONTEXT } from '../utlysning/sendMail/SendMailDialog'

export const CandidateProfileModalActions = ({
  candidate,
  handleClick,
  open,
  setMailDlgContext,
  setToggleMailDialog,
  anchorEl,
  handleCloseMoreMenu,
  applicationStages,
  setOpenDeleteCandidateModal,
}: {
  candidate: Entry<IKandidatFields>
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  open: boolean
  setMailDlgContext: React.Dispatch<React.SetStateAction<SEND_MAIL_DLG_CONTEXT>>
  setToggleMailDialog: (open: boolean) => void
  anchorEl: HTMLElement | null
  handleCloseMoreMenu: () => void
  applicationStages: string[]
  setOpenDeleteCandidateModal: (open: boolean) => void
}) => {
  const [, dispatch] = React.useContext(AlertContext)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        minWidth: 'fit-content',
        justifyContent: 'flex-end',
        gap: '1rem',
        flexWrap: 'wrap',
      }}
    >
      <Button
        variant="greyDefault"
        sx={{
          minWidth: 'fit-content',
          '@media (min-width: 1200px)': {
            marginRight: '1rem',
          },
          '@media (max-width: 1200px)': {
            marginBottom: '0.5rem',
          },
        }}
        onClick={handleClick}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMoreMenu}
        onClick={handleCloseMoreMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        <MenuItem
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            color: '#454D60',
          }}
          onClick={() =>
            dispatch(
              AlertAction.showAlert('Ikke implementert', AlertTypes.info)
            )
          }
        >
          Eksporter søknad som pdf
          <Box sx={{ paddingLeft: '10px' }}>
            <FileDownloadOutlinedIcon />
          </Box>
        </MenuItem>
        <MenuItem
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            color: '#454D60',
          }}
          onClick={() =>
            window.open(
              `https://app.contentful.com/spaces/${process.env.REACT_APP_SPACE_ID}/entries/${candidate.sys.id}`,
              '_blank'
            )
          }
        >
          Flytt søknad til annen utlysning
          <Box sx={{ paddingLeft: '10px' }}>
            <FileUploadOutlinedIcon />
          </Box>
        </MenuItem>
        <MenuItem
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            color: '#731411',
          }}
          onClick={() => {
            setOpenDeleteCandidateModal(true)
          }}
        >
          Slett søknad
          <Box sx={{ paddingLeft: '10px' }}>
            <DeleteOutlineOutlinedIcon />
          </Box>
        </MenuItem>
      </Menu>
      <Button
        variant="greyDefault"
        sx={{
          minWidth: 'fit-content',
          '@media (min-width: 1200px)': {
            marginRight: '1rem',
          },
          '@media (max-width: 1200px)': {
            marginBottom: '0.5rem',
          },
        }}
        onClick={() => {
          setMailDlgContext(SEND_MAIL_DLG_CONTEXT.SIMPLE)
          setToggleMailDialog(true)
        }}
      >
        <MailOutlineIcon fontSize="small" sx={{ paddingRight: '0.5rem' }} />
        Send e-post
      </Button>
      <SendToStageMenu
        candidate={candidate}
        applicationStages={applicationStages}
      />
      <Button
        variant="darkRedRounded"
        onClick={() => {
          setMailDlgContext(SEND_MAIL_DLG_CONTEXT.REJECTION)
          setToggleMailDialog(true)
        }}
      >
        <MailOutlineIcon
          fontSize="small"
          sx={{
            paddingRight: '0.5rem',
          }}
        />
        Avslag
      </Button>
    </Box>
  )
}
