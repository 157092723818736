/* eslint-disable no-param-reassign */
import client from '../client'
import { IJobapplicationContentFields } from '../schema/generated/contentful'
import { writableClient } from '../writableClient'

/**
 * Set responsible person for the candidate
 * @param jobapplicationID - the id of the job application
 * @param tag - tag to be added
 */
export const removeTagFromList = async (
  jobapplicationID: string,
  tag: string
) => {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(jobapplicationID))
    .then((entry) => {
      if (!entry.fields.tags) {
        entry.fields.tags = {}
        entry.fields.tags['en-US'] = []
      }
      entry.fields.tags['en-US'] = [
        ...entry.fields.tags['en-US'].filter((t: string) => t !== tag),
      ]
      return entry.update()
    })
    .then((entry) => entry.publish())
}

/**
 * Set responsible person for the candidate
 * @param jobapplicationID - the id of the job application
 * @param tag - tag to be added
 */
export const updateTagList = async (jobapplicationID: string, tag: string) => {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(jobapplicationID))
    .then((entry) => {
      if (!entry.fields.tags) {
        entry.fields.tags = {}
        entry.fields.tags['en-US'] = []
      }
      entry.fields.tags['en-US'] = [...entry.fields.tags['en-US'], tag]
      return entry.update()
    })
    .then((entry) => entry.publish())
}

/**
 * Retrive job application by id
 * @param jobapplicationID - the id of the job application
 */
export const getJobApplicationByID = async ({
  queryKey,
}: {
  queryKey: string[]
}) => {
  const jobapplicationID = queryKey[1]
  const data = await client
    .getEntry<IJobapplicationContentFields>(jobapplicationID)
    .then((res) => {
      res.fields.applicants = res.fields.applicants?.map((applicant) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        applicant.fields.utlysning = undefined
        return applicant
      })
      return res
    })
  return data
}

/**
 * Change shown status on the job application
 * @param jobapplicationID - the id of the job application
 */
export const changeShownStatusOnApplication = async (
  jobapplicationID: string
) => {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(jobapplicationID))
    .then((entry) => {
      if (!entry.fields.shown) {
        entry.fields.shown = {}
        entry.fields.shown['en-US'] = false
      }
      entry.fields.shown['en-US'] = !entry.fields.shown['en-US']
      return entry.update()
    })
    .then((entry) => entry.publish())
}
