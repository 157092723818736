/* eslint-disable no-param-reassign */
import axios from 'axios'
import { Asset, Entry } from 'contentful'
import jwtDecode from 'jwt-decode'
import { queryClient } from '..'
import client from '../client'
import { IHistory } from '../interfaces/history.interface'
import googleAuthToken from '../interfaces/googleAuthToken'
import {
  IEmployeeFields,
  IJobapplicationContentFields,
} from '../schema/generated/contentful'
import { delay } from '../utils/utils'
import { writableClient } from '../writableClient'
import { getCandidate } from './candidate'

let loggedInUser: IEmployeeFields

export const BASE_URL = `https://cdn.contentful.com/spaces/${process.env.REACT_APP_SPACE_ID}/environments/${process.env.REACT_APP_ENVIRONMENT_ID}`

export const getLoggedInUser = () => {
  return loggedInUser
}

/**
 * Gets a specific candidate from the API using the candidate's id
 * @returns A candidate that matches the param id
 */
export const checkIfAppPasswordSet = async () => {
  const { passwordForJobApplication } = loggedInUser
  if (passwordForJobApplication) {
    return true
  }
  return false
}

/**
 * Gets a specific candidate from the API using the candidate's id
 * @returns A candidate that matches the param id
 */
export const chkAccessToJobApplications = async () => {
  const token = window.localStorage.getItem('GoogleAuth') || ''
  const payload = jwtDecode<googleAuthToken>(token)
  const loggedInUserEmail = payload.email

  const res = await axios.get(
    `${BASE_URL}/entries?access_token=${process.env.REACT_APP_ACCESS_TOKEN}&fields.email=${loggedInUserEmail}&content_type=employee`
  )
  const { accessToJobApplications } = res.data.items[0].fields
  if (accessToJobApplications) {
    return true
  }
  return false
}

/**
 *
 * @param id - The ID for the asset that is being fetched
 * @returns - An Asset
 */
export const getAssetFromAPI = async (assetID: string): Promise<Asset> => {
  const url = `${BASE_URL}/assets/${assetID}?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`
  const { data } = await axios.get(url)
  return <Asset>data
}

/**
 * Gets the history of the candidate
 * @param id - The id of the candidate
 * @returns The candidate history log
 */
export const getCandidateHistory = async (id: string): Promise<IHistory[]> => {
  const { data } = await axios.get(
    `${BASE_URL}/entries/${id}?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`
  )

  return data.fields.history
}

/**
 *
 * @param candidateID - The id of the candidate
 * @param previousCandidateHistory - The candidate history log before updating
 * @param newLog - The new log to add to the history log
 */
export async function newCandidateHistoryLog(
  candidateID: string,
  previousCandidateHistory: IHistory[],
  newLog: IHistory
): Promise<void> {
  if (previousCandidateHistory && previousCandidateHistory.length) {
    previousCandidateHistory.push(newLog)
  } else {
    previousCandidateHistory = [newLog]
  }

  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(candidateID))
    .then((entry) => {
      if (!entry.fields.history) {
        entry.fields.history = {}
      }
      entry.fields.history['en-US'] = previousCandidateHistory

      return entry.update()
    })
    .then((entry) => entry.publish())

  await delay(300).then(() => {
    queryClient.invalidateQueries(['candidateHistory', candidateID])
  })
}

/**
 * Sends a candidate to a specific stage
 * @param stage - The stage the candidate will be sent to
 * @param candidate - The candidate who's stage will be updated
 */
export async function sendCandidateToStage(
  candidateID: string,
  stage: string
): Promise<void> {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(candidateID))
    .then((entry) => {
      entry.fields.stage['en-US'] = `${stage}`

      return entry.update()
    })
    .then((entry) => entry.publish())
    .then(async () => {
      const oldLog: IHistory[] = (await getCandidate(candidateID)).fields
        .history

      const logAction: IHistory = {
        timestamp: new Date(),
        userEmail: loggedInUser.email,
        userFullname: loggedInUser.name,
        userId: loggedInUser.id,
        userPictureURL: loggedInUser.profilePicture?.url,
        title: 'Sendt til steg',
        content: `Kandidaten ble sendt til ${stage}`,
      }

      await newCandidateHistoryLog(candidateID, oldLog, logAction)
    })
}

/**
 * Sends a candidate to the next stage of the job posting
 * @param candidateID - The id of the candidate
 * @param utlysning - The job posting
 */
export async function sendCandidateToNextStage(
  candidateID: string,
  utlysning: Entry<IJobapplicationContentFields>
): Promise<void> {
  let updatedStage: string

  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(candidateID))
    .then((entry) => {
      const nextStage =
        utlysning.fields.stages[
          utlysning.fields.stages.indexOf(entry.fields.stage['en-US']) + 1
        ]

      updatedStage = nextStage

      entry.fields.stage['en-US'] = `${nextStage}`
      if (nextStage.toUpperCase() !== 'AVSLAG') {
        entry.fields.receivedEmail['en-US'] = false
      }
      return entry.update()
    })
    .then((entry) => entry.publish())
    .then(async () => {
      const oldLog: IHistory[] = (await getCandidate(candidateID)).fields
        .history

      const logAction: IHistory = {
        timestamp: new Date(),
        userEmail: loggedInUser.email,
        userFullname: loggedInUser.name,
        userId: loggedInUser.id,
        userPictureURL: loggedInUser.profilePicture?.url,
        title: 'Sendt til steg',
        content: `Kandidaten ble sendt til ${updatedStage}`,
      }

      await newCandidateHistoryLog(candidateID, oldLog, logAction)
    })
}

/**
 * Sends a candidate to the 'rejection' stage of the job posting
 * @param candidateID - The id of the candidate
 */
export async function sendRejection(candidateID: string): Promise<void> {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(candidateID))
    .then((entry) => {
      const nextStage = 'Avslag'
      entry.fields.stage['en-US'] = `${nextStage}`

      return entry.update()
    })
    .then((entry) => entry.publish())
    .then(async () => {
      const oldLog: IHistory[] = (await getCandidate(candidateID)).fields
        .history

      const logAction: IHistory = {
        timestamp: new Date(),
        userEmail: loggedInUser.email,
        userFullname: loggedInUser.name,
        userId: loggedInUser.id,
        userPictureURL: loggedInUser.profilePicture?.url,
        title: 'Sendt til steg',
        content: `Kandidaten ble sendt til avslag.`,
      }

      await newCandidateHistoryLog(candidateID, oldLog, logAction)
    })
}

/**
 * Set to mail sent for candidate
 * @param candidateID - The id of the candidate
 */
export async function setMailSent(candidateID: string): Promise<void> {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(candidateID))
    .then((entry) => {
      entry.fields.receivedEmail['en-US'] = true
      return entry.update()
    })
    .then((entry) => entry.publish())
}

/**
 * Fetches all job applications from Contentful
 */
export const fetchUtlysninger = async () => {
  const utlysninger = await client.getEntries<IJobapplicationContentFields>({
    content_type: 'jobapplicationContent',
  })
  // TODO: Dont think this does anything
  utlysninger.items.map((utlysning) => {
    return {
      ...utlysning,
      fields: {
        ...utlysning.fields,
        applicants: utlysning.fields.applicants?.filter((a) => a.fields),
      },
    }
  })
  const sommerJobs = await client.getEntries<IJobapplicationContentFields>({
    content_type: 'sommerjobbContent',
  })
  const sommerjobsModified = sommerJobs.items.map((utlysning) => {
    return {
      ...utlysning,
      fields: {
        ...utlysning.fields,
        applicants: utlysning.fields.applicants?.filter((a) => a.fields),
        title: utlysning.fields.tittelForSommerjobb,
      },
    }
  })
  utlysninger.items = [...sommerjobsModified, ...utlysninger.items]
  utlysninger.items.sort((a, b) => +b.fields.shown - +a.fields.shown)

  return utlysninger
}

/** Get logged in user */
const setLoggedInUser = async () => {
  if (loggedInUser) {
    return loggedInUser
  }
  const token = window.localStorage.getItem('GoogleAuth') || ''
  const payload = jwtDecode<googleAuthToken>(token)
  const loggedInUserEmail = payload.email

  const res = await axios.get(
    `${BASE_URL}/entries?access_token=${process.env.REACT_APP_ACCESS_TOKEN}&fields.email=${loggedInUserEmail}&content_type=employee`
  )
  const { name, email, displaySkill, passwordForJobApplication } =
    res.data.items[0].fields
  const { id } = res.data.items[0].sys

  // TODO: Refactor, this was a quick fix to remove a bug if user don't have a profile picture
  if (res.data.items[0].fields?.profilePicture) {
    const profilePictureAssetResult = await getAssetFromAPI(
      res.data.items[0].fields?.profilePicture?.sys?.id
    )

    loggedInUser = {
      name,
      email,
      displaySkill,
      passwordForJobApplication,
      id,
      profilePicture: profilePictureAssetResult?.fields?.file,
    }
  } else {
    loggedInUser = {
      name,
      email,
      displaySkill,
      passwordForJobApplication,
      id,
      profilePicture: undefined,
    }
  }

  return loggedInUser
}

function init() {
  setLoggedInUser().then()
}

init()
