import {
  Avatar,
  FormControlLabel,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import Switch from '@mui/material/Switch'
import { UseMutationResult, useQuery } from '@tanstack/react-query'
import React from 'react'
import { getAllEmployees } from '../../api/employees'

import { LoadingOrError } from '../LoadingOrError'

const Access = ({
  setAccessMutation,
}: {
  setAccessMutation: UseMutationResult<
    void,
    unknown,
    {
      name: string
      id: string
      accessToJobApplications: boolean
    },
    unknown
  >
}) => {
  const {
    data: employees,
    isLoading,
    isError,
  } = useQuery(['employees'], getAllEmployees)
  if (employees === undefined && !isLoading) return null
  return (
    <LoadingOrError isLoading={isLoading} isError={isError}>
      <div
        style={{
          display: 'flex',
          lineHeight: '24px',
          justifyContent: 'center',
          alignContent: 'center',
          flexDirection: 'column',
          width: '40%',
        }}
      >
        <Typography sx={{ marginBottom: '2rem' }}>Ansatte</Typography>
        <List style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
          {employees
            ?.sort((a) => {
              return a.accessToJobApplications ? -1 : 1
            })
            .map((employee, index: number) => (
              <ListItem
                key={employee.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '1rem',
                }}
                divider={index !== employees.length - 1}
              >
                <Avatar
                  src={
                    employee.profilePicture
                      ? `${employee.profilePicture.fields.file.url}?w=100`
                      : ''
                  }
                  alt={employee.name}
                  sx={{ height: '70px', width: '70px', borderRadius: '50%' }}
                />
                <Typography>{employee.name}</Typography>
                <FormControlLabel
                  label={
                    employee.accessToJobApplications
                      ? 'Fjern tilgang'
                      : 'Gi tilgang'
                  }
                  control={
                    <Switch
                      checked={employee.accessToJobApplications}
                      color={
                        employee.accessToJobApplications ? 'error' : 'secondary'
                      }
                      onChange={() => {
                        setAccessMutation.mutateAsync(employee)
                      }}
                      disabled={setAccessMutation.isLoading}
                    />
                  }
                />
              </ListItem>
            ))}
        </List>
      </div>
    </LoadingOrError>
  )
}

export default Access
