import { Box, Button, CircularProgress } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { Entry } from 'contentful'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { queryClient } from '../..'
import { removeTagFromCandidate } from '../../api/candidate'
import { IKandidatFields } from '../../schema/generated/contentful'
import { turnFirstLetterToUpperCase } from '../../utils/utils'
import AlertAction, { AlertTypes } from '../Alert/AlertAction'
import { AlertContext } from '../Alert/AlertContext'

export const TagsView = ({
  candidate,
  utlysningId,
}: {
  candidate: Entry<IKandidatFields>
  utlysningId: string
}) => {
  const [, dispatch] = React.useContext(AlertContext)
  const candidateTags = candidate.fields.tags ?? []
  /**
   * Mutates the candidate to the 'rejection' stage
   */
  const removeTagMutation = useMutation(
    ({ tag }: { tag: string }) => removeTagFromCandidate(candidate.sys.id, tag),
    {
      onError: async (err, variables) => {
        await queryClient.invalidateQueries(['utlysning', utlysningId])
        dispatch(
          AlertAction.showAlert(
            `Klarte ikke å sette "${variables.tag}" på kandidaten: "${candidate.fields.name}"`,
            AlertTypes.error
          )
        )
      },
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(['utlysning', utlysningId])
        dispatch(
          AlertAction.showAlert(
            `Taggen "${variables.tag}" er fjernet fra kandidaten: "${candidate.fields.name}"`,
            AlertTypes.success
          )
        )
      },
    }
  )
  const handleCandidateTagRemoved = async (tag: string) => {
    await removeTagMutation.mutateAsync({
      tag,
    })
  }
  return (
    <>
      {candidateTags?.map((tag: string) => {
        return (
          <Button
            sx={{
              marginRight: '4px',
            }}
            key={tag}
            variant="roundedInfo"
            disabled={removeTagMutation.isLoading}
          >
            <>{turnFirstLetterToUpperCase(tag)}</>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '4px',
                '&:hover': {
                  backgroundColor: '#E3ECF9',
                  cursor: 'pointer',
                },
              }}
            >
              {removeTagMutation.variables?.tag === tag &&
              removeTagMutation.isLoading ? (
                <CircularProgress size="20px" />
              ) : (
                <CloseIcon
                  custom-tag={tag}
                  onClick={() => {
                    handleCandidateTagRemoved(tag)
                  }}
                  fontSize="small"
                />
              )}
            </Box>
          </Button>
        )
      })}
    </>
  )
}
