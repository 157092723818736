import React from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { ReactComponent as FinkBirdIcon } from '../assets/icons/FinkBirdIcon.svg'
import { ReactComponent as FinkCoverIcon } from '../assets/icons/FinkCoverIcon.svg'

const LoginStylePage: React.FunctionComponent = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <FinkCoverIcon />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography
          sx={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: { xs: 30, lg: 40 },
            color: '#F0885D',
            display: 'flex',
          }}
        >
          Søknadstjeneste
        </Typography>
        <FinkBirdIcon style={{ width: '20%' }} />
      </Box>
      <Box>
        <div
          id="g_id_onload"
          data-client_id="711214443356-qs5mkvd5dgmavqduum4voq3lvesvocpd.apps.googleusercontent.com"
          data-callback="HandleCredentialResponse"
          data-auto_prompt="false"
          data-ux_mode="popup"
        />
        <div
          className="g_id_signin"
          data-type="standard"
          data-size="large"
          data-theme="filled_white"
          data-shape="rectangular"
          data-width="200"
        />
      </Box>
    </Box>
  )
}

export default LoginStylePage
