import EngineeringIcon from '@mui/icons-material/Engineering'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { getJobApplicationByID } from '../api/utlysning'
import { LoadingOrError } from '../components/LoadingOrError'
import UtlysningView from '../components/utlysning/UtlysningView'

/**
 * Job application page
 * @returns The current job application with the different candidates from each stage
 */
const Utlysning: React.FunctionComponent = () => {
  const { id } = useParams()
  const {
    data: utlysning,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['utlysning', id!],
    queryFn: getJobApplicationByID,
    cacheTime: 0,
  })

  return (
    <main>
      <LoadingOrError isLoading={isLoading} isError={isError}>
        <Box
          sx={{
            padding: { sm: '4rem 1rem 1rem 1rem', sx: 0 },
            margin: { sm: '0 auto', sx: 0 },
            width: { sm: '80%', sx: '100%' },
          }}
        >
          <Typography
            variant="h1"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            {utlysning?.fields.title || utlysning?.fields.tittelForSommerjobb}
          </Typography>
          {!utlysning?.fields.stages && (
            <div
              style={{
                width: '512px',
                background: 'white',
                borderRadius: '16px',
                padding: '16px',
                marginTop: '128px',
                textAlign: 'center',
              }}
            >
              <EngineeringIcon color="secondary" fontSize="large" />
              <h2>
                {utlysning?.fields.title ||
                  utlysning?.fields.tittelForSommerjobb}
              </h2>
              <p>
                Denne stillingsutlysningen mangler <em>søknadssteg</em>.
                <br />
                Vennligst fullfør oppsettet i contentful, og prøv på nytt.
              </p>
            </div>
          )}
          {utlysning && <UtlysningView utlysning={utlysning} />}
        </Box>
      </LoadingOrError>
    </main>
  )
}

export default Utlysning
