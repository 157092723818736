// TODO: Remove eslint-disable when this file is refactored
/* eslint-disable no-param-reassign */
import axios from 'axios'
import { Entry } from 'contentful'
import client from '../client'
import { IHistory } from '../interfaces/history.interface'
import { INote } from '../interfaces/note.interface'
import { IKandidatFields } from '../schema/generated/contentful'
import { writableClient } from '../writableClient'
import { BASE_URL, getLoggedInUser, newCandidateHistoryLog } from './api'

/**
 * Gets a specific candidate from the API using the candidate's id
 * @param id - The id of the candidate
 * @returns A candidate that matches the param id
 */
export const getCandidatesFromAPI = async (id: string) => {
  const { data } = await axios.get(
    `${BASE_URL}/entries?access_token=${process.env.REACT_APP_ACCESS_TOKEN}&content_type=${process.env.REACT_APP_CONTENT_TYPE_CANDIDATE}&limit=1000`
  )
  const ret = data.items.filter((cand: Entry<IKandidatFields>) => {
    if (cand.fields.utlysning) {
      return cand.fields.utlysning.sys.id === id
    }
    return false
  })
  return ret
}

export const getCandidateAxios = async (
  id: string
): Promise<Entry<IKandidatFields>> => {
  const { data } = await axios.get(
    `${BASE_URL}/entries/${id}?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`
  )
  return data
}

/**
 * Get a candidate based on Id
 * @param id - The candidate Id
 * @returns The candidate
 */
export async function getCandidate(
  id: string
): Promise<Entry<IKandidatFields>> {
  const res = await client
    .getEntries<IKandidatFields>({
      content_type: 'kandidat',
      limit: 1000,
    })
    .then((res) => {
      const filtered = res.items.filter((kandidat) => kandidat.sys.id === id)
      return filtered[0]
    })

  return res
}

/**
 * Gets all candidates from Contentful and filters out the ones who have not applied to the job posting matching the param id
 * @param id - The job posting id
 * @returns All candidates who have applied to a specific job posting
 */
export async function getCandidates(
  id: string
): Promise<Entry<IKandidatFields>[]> {
  const res = await client
    .getEntries<IKandidatFields>({
      content_type: 'kandidat',
    })
    .then((res) => {
      // TODO: Add failsafe for when a candidate is not connected to a utlysning, as it makes it crash
      const filtered = res.items.filter(
        (kandidat) => kandidat.fields.utlysning.sys.id === id
      )
      return filtered
    })

  return res
}

/**
 * Gets all candidates from Contentful
 * @returns All candidates
 */
export const fetchAllCandidates = async () => {
  return client.getEntries<IKandidatFields>({
    content_type: 'kandidat',
    limit: 1000,
  })
}

/**
 * Remove tag from candidate
 * @param candidateID - The id of the candidate
 * @param tag - The tag to be removed from the candidate
 */
export async function removeTagFromCandidate(
  candidateID: string,
  tag: string
): Promise<void> {
  const loggedInUser = getLoggedInUser()
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(candidateID))
    .then((entry) => {
      if (!entry.fields.tags) {
        entry.fields.tags = {}
      }
      entry.fields.tags['en-US'] = entry.fields.tags['en-US'].filter(
        (t: string) => t !== tag
      )
      return entry.update()
    })
    .then((entry) => entry.publish())
    .then(async () => {
      const oldLog: IHistory[] = (await getCandidate(candidateID)).fields
        .history
      const logAction: IHistory = {
        timestamp: new Date(),
        userEmail: loggedInUser.email,
        userFullname: loggedInUser.name,
        userId: loggedInUser.id,
        userPictureURL: loggedInUser.profilePicture?.url,
        title: 'Tags oppdatert',
        content: `Tag: "${tag}" er fjernet`,
      }

      await newCandidateHistoryLog(candidateID, oldLog, logAction)
    })
}

/**
 * Add tag to candidate tags
 * @param candidateID - The id of the candidate
 * @param tag - The tag to be added to the candidate
 */
export async function addTagToCandidate(
  candidateID: string,
  tag: string
): Promise<void> {
  const loggedInUser = getLoggedInUser()
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(candidateID))
    .then((entry) => {
      if (!entry.fields.tags) {
        entry.fields.tags = {}
      }
      const tags = entry.fields.tags['en-US'] ?? []
      entry.fields.tags['en-US'] = [...tags, tag]
      return entry.update()
    })
    .then((entry) => entry.publish())
    .then(async () => {
      const oldLog: IHistory[] = (await getCandidate(candidateID)).fields
        .history
      const logAction: IHistory = {
        timestamp: new Date(),
        userEmail: loggedInUser.email,
        userFullname: loggedInUser.name,
        userId: loggedInUser.id,
        userPictureURL: loggedInUser.profilePicture?.url,
        title: 'Tags oppdatert',
        content: `Tag: "${tag}" er lagt til`,
      }

      await newCandidateHistoryLog(candidateID, oldLog, logAction)
    })
}

/**
 * Deletes a candidate and removes them from utlysning in Contentful
 * @param candidateId - The id of the candidate who should be deleted
 * RESPECT THAT ERRORS IN THIS METHOD COULD POTENTIALLY DELETE ALL CANDIDATES FROM A UTLYSNING
 */
export async function deleteCandidate(
  candidateId: string,
  utlysningsId: string
): Promise<void> {
  // Removes candidate from utlysning
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(utlysningsId))
    .then((entry) => {
      if (!entry.fields.applicants) {
        entry.fields.applicants = {}
      }
      const allApplicants = entry.fields.applicants['en-US'] ?? []
      entry.fields.applicants['en-US'] = [...allApplicants].filter(
        (applicant) => applicant.sys.id !== candidateId
      )

      return entry.update()
    })
    .then((entry) => entry.publish())

  // Removes candidate from Contentful
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(candidateId))
    .then((entry) => entry.unpublish())
    .then((entry) => entry.delete())
    .then(() => console.log(`Entry deleted.`))
    .catch(console.error)
}

/**
 * Deletes an array of candidates and removes them from utlysningen in Contentful
 * @param allApplicantsWithPersonalDataNotAllowed - An array of all the applicants to be removed
 * RESPECT THAT ERRORS IN THIS METHOD COULD POTENTIALLY DELETE ALL CANDIDATES FROM A UTLYSNING
 * DELETE CALLS NEEDS TO BE CALLED AFTER EACHOTHER TO NOT GET VERSION MISMATCH ERROR WHICH WILL STOP THE PROCESS
 */
export async function deleteAllCandidatesFromUtlysnignWithPersonalDataNotAllowed(
  allApplicantsWithPersonalDataNotAllowed: Entry<IKandidatFields>[],
  utlysningsId: string
): Promise<void> {
  const serial = (funcs: (() => Promise<void>)[]) =>
    funcs.reduce(
      (promise: any, func: any) =>
        promise.then((result: any) =>
          func().then(Array.prototype.concat.bind(result))
        ),
      Promise.resolve([])
    )
  const funcs = allApplicantsWithPersonalDataNotAllowed.map(
    (candidate) => () => deleteCandidate(candidate.sys.id, utlysningsId)
  )
  await serial(funcs).then(console.log('Done deleting all candidates'))
}

/**
 * Set responsible person for the candidate
 * @param candidateID - candidate
 * @param responsiblePersonName - string responsible person name
 */
export const updateResponsiblePersonForCandidate = async (
  candidateID: string,
  responsiblePersonName: string
) => {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(candidateID))
    .then((entry) => {
      if (!entry.fields.responsiblePerson) {
        entry.fields.responsiblePerson = {}
      }
      entry.fields.responsiblePerson['en-US'] = responsiblePersonName
      return entry.update()
    })
    .then((entry) => entry.publish())
}
