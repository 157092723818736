export const humanizeTimestamp = (timeStamp: Date) => {
  const now = new Date()
  const yearsDifference = now.getFullYear() - timeStamp.getFullYear()
  const monthsDifference = now.getMonth() - timeStamp.getMonth()
  const daysDifference = now.getDate() - timeStamp.getDate()
  const daysDifferenceBetweenMonths = (
    (now.getTime() - timeStamp.getTime()) /
    (1000 * 60 * 60 * 24)
  ).toFixed(0)
  const hoursDifference =
    (now.getTime() - timeStamp.getTime()) / (1000 * 60 * 60)

  if (yearsDifference > 0) {
    return yearsDifference === 1
      ? 'Over 1 år siden'
      : `Over ${yearsDifference} år siden`
  }

  if (monthsDifference > 0) {
    if (monthsDifference === 1 && daysDifference < 0) {
      return `${daysDifferenceBetweenMonths} dager siden`
    }
    return monthsDifference === 1
      ? '1 måned siden'
      : `${monthsDifference} måneder siden`
  }

  if (daysDifference > 0) {
    if (daysDifference === 1 && hoursDifference > 1 && hoursDifference < 24) {
      return `Over ${Math.floor(hoursDifference)} timer siden`
    }
    if (daysDifference === 1 && hoursDifference < 1 && hoursDifference > 0.1) {
      return `${Math.floor(hoursDifference * 60)} minutter siden`
    }
    if (daysDifference === 1 && hoursDifference < 0.1) {
      return 'Et øyeblikk siden'
    }
    if (daysDifference >= 1)
      return daysDifference === 1 ? 'I går' : `${daysDifference} dager siden`
  }

  if (hoursDifference >= 1) {
    let label = 'timer siden'
    if (hoursDifference < 2) {
      label = 'time siden'
    }
    return `Over ${Math.floor(hoursDifference)} ${label}`
  }

  if (hoursDifference >= 0.1) {
    return `${Math.floor(hoursDifference * 60)} minutter siden`
  }

  return 'Et øyeblikk siden'
}

/**
 * Calculates if the applicant's application date is within the last seven days
 * @param date - The applicant's application date
 * @returns True if the date is within the last seven days, false if it is older
 */
export const lastSevenDays: (date: string) => boolean = (date: string) => {
  const today = new Date()
  const compare = new Date(date)

  const diffTime = Math.abs(today.getTime() - compare.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  return diffDays <= 7
}

/**
 * Calculates if the applicant's application date is within the last seven days
 * @param date - The applicant's application date
 * @returns True if the date is within the last seven days, false if it is older
 */
export const between7daysAnd14Days: (date: string) => boolean = (
  date: string
) => {
  const today = new Date()
  const compare = new Date(date)

  const diffTime = Math.abs(today.getTime() - compare.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  return diffDays > 7 && diffDays <= 14
}

export const turnFirstLetterToUpperCase = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const checkIfTwoObjectsAreEqual = (obj1: object, obj2: object) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export function delay(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time))
}
