import * as React from 'react'
import Box from '@mui/material/Box'
import { Avatar } from '@mui/material'
import { Entry } from 'contentful'
import { IKandidatFields } from '../../../schema/generated/contentful'
import './HistoryView.css'
import { humanizeTimestamp } from '../../../utils/utils'
import theme from '../../../theme'

interface HistoryViewProps {
  candidate: Entry<IKandidatFields>
}

const HistoryView: React.FunctionComponent<HistoryViewProps> = (
  props: HistoryViewProps
) => {
  const { candidate } = props
  const { history } = candidate.fields

  const whenWasThis = (d: Date) => {
    return humanizeTimestamp(d)
  }

  return (
    <Box
      sx={{
        background: '#F8F9FA',
        paddingTop: '32px',
        borderRadius: '16px 16px 0 0',
        boxSizing: 'border-box',
        overflowY: 'auto',
        height: '100%',
      }}
    >
      {history === undefined && <Box>Ingen historikk å vise</Box>}
      {history &&
        [...history].reverse().map((log) => {
          return (
            <Box key={history.indexOf(log)} className="logContainer">
              <Box
                className={`leftBox ${history.length > 1 ? 'leftBoxLine' : ''}`}
              >
                <Avatar
                  sx={{
                    height: '56px',
                    width: '56px',
                  }}
                  alt={log.userFullname}
                  src={log.userPictureURL || log.userFullname}
                />
              </Box>
              <Box className="rightBox">
                <div style={{ lineHeight: '24px' }}>
                  <span
                    style={{
                      textTransform: 'uppercase',
                      color: '#999',
                      fontWeight: 700,
                    }}
                  >
                    {log.title || 'N/A'}
                  </span>
                  <br />
                  <span>
                    <strong style={{ fontSize: '14px' }}>
                      {log.userFullname || 'N/A'}
                    </strong>
                  </span>
                  &nbsp;&nbsp;
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'rgba(0,0,0,.8)',
                    }}
                  >
                    {whenWasThis(new Date(log.timestamp))}
                  </span>
                </div>
                <div className="logContent">
                  {log.subContent}
                  {log.subContent && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                  {log.content || 'Innhold mangler'}
                </div>
              </Box>
            </Box>
          )
        })}
      <Box className="logContainer">
        <Box className="leftBox" style={{ width: '56px' }}>
          <Box
            className="leadingBlue"
            sx={{
              backgroundColor: theme.palette.action.active,
            }}
          />
        </Box>
        <Box className="rightBox" />
      </Box>
    </Box>
  )
}

export default HistoryView
