import { createClient } from 'contentful-management'

const spaceID = process.env.REACT_APP_SPACE_ID
if (spaceID === undefined || spaceID === null)
  throw new Error('Missing contentful space id')
const accessToken = process.env.REACT_APP_OLD_ACCESS_TOKEN
if (accessToken === undefined || accessToken === null)
  throw new Error('Missing contentful access token')

export const writableClient = createClient({
  accessToken,
})
