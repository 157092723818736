// TODO: remove eslint-disable
/* eslint-disable no-param-reassign */
import axios from 'axios'
import { Entry, EntryCollection } from 'contentful'
import client from '../client'
import { IEmailTemplateFields } from '../schema/generated/contentful'
import { writableClient } from '../writableClient'

/**
 * Sends an email to a specific email address
 * @param mailTo - The email for the recipient
 * @param subject The email subject
 * @param body - The body for email
 */
export const sendMail = async (args: {
  mailTo: string
  subject: string
  body: string
}) => {
  const accessToken = window.localStorage.getItem('GoogleAuth') || ''
  return axios.post(`${process.env.REACT_APP_MAIL_SERVICE}`, args, {
    headers: {
      Authorization: accessToken,
    },
  })
}

/**
 * Updates an email template with new name, subject and content
 * @param templateID - The id of the template
 * @param name - The name of the template
 * @param subject - The email subject
 * @param content - The email content
 */
export async function updateEmailTemplate(
  templateID: string,
  name: string,
  subject: string,
  content: string
): Promise<void> {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(templateID))
    .then((entry) => {
      entry.fields.name['en-US'] = `${name}`
      entry.fields.subject['en-US'] = `${subject}`
      entry.fields.content['en-US'] = `${content}`

      return entry.update()
    })
    .then((entry) => entry.publish())
}

/**
 * Deletes an email template from Contentful
 * @param id - The id of the email template
 */
export async function deleteEmailTemplate(id: string): Promise<void> {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(id))
    .then((entry) => entry.unpublish())
    .then((entry) => entry.delete())
}

/**
 * Gets an email template from Contentful
 * @param id - The id of the specific email template
 * @returns A specific email template
 */
export async function getEmailTemplate(
  id: string
): Promise<Entry<IEmailTemplateFields>> {
  const res = await client.getEntry<IEmailTemplateFields>(id)

  return res
}

/**
 * Gets all email templates from Contentful
 * @returns All email templates
 */
export async function getEmailTemplates(): Promise<
  EntryCollection<IEmailTemplateFields>
> {
  const res = await client.getEntries<IEmailTemplateFields>({
    content_type: 'emailTemplate',
  })

  return res
}
