import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import image1 from '../../assets/jobApplicationsOverview/endbox.png'
import BoxType from '../../interfaces/box.interface'

// This method was used in Material UI v4 and should be updated to v5
const useStyles = makeStyles({
  boxSize: {
    width: 146,
    height: 82,
    backgroundImage: `url(${image1})`,
    marign: 0,
    padding: 0,
    marginLeft: 10,
  },

  maintitle: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    color: '#666666',
    paddingLeft: 15,
    paddingTop: 12,
    margin: 0,
  },

  amount: {
    display: 'inline-flex',
    flexDirection: 'row',
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#666666',
    float: 'left',
    paddingLeft: 15,
  },

  lastseven: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    color: '#666666',
    float: 'right',
    paddingRight: 15,
    paddingTop: 10,
  },
})

/**
 * The leftmost job postings stage information
 * @param props - The component props, in this case the name of the stage, how many applicant are currently at this stage, and any other subdetails
 * @returns A component displaying the 'rejected' stage and information about its applicants
 */
const EndBox: React.FunctionComponent<BoxType> = (props: BoxType) => {
  const classes = useStyles()

  const { name, amount, subdetails } = props

  return (
    <Box className={classes.boxSize}>
      <p className={classes.maintitle}>{name}</p>
      <p className={classes.amount}>{amount}</p>
      <p className={classes.lastseven}>({subdetails})</p>
    </Box>
  )
}

export default EndBox
