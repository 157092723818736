import * as React from 'react'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import theme from '../../../theme'
import { Order } from '../../../interfaces/types'
import { HeadCellTemplates } from '../../../interfaces/table.interface'
import { IEmailTemplateFields } from '../../../schema/generated/contentful'

/**
 * EnhancedTable props
 */
interface EnhancedTableProps {
  // Function when clicking header cell to sort in opposite order
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IEmailTemplateFields
  ) => void
  // Which order the column should be sorted by
  order: Order
  // Which type to order by (which type the column data consists of)
  orderBy: string
  // List of header cells
  headCells: HeadCellTemplates[]
}

/**
 *
 * @param props - The TableHeadTemplates props
 * @returns Table header cells belonging to a table displaying email templates
 */
const TableHeadTemplates: React.FunctionComponent<EnhancedTableProps> = (
  props: EnhancedTableProps
) => {
  const { order, orderBy, headCells, onRequestSort } = props

  /**
   * The header cell sort handler
   * @param property - Which property to sort
   * @returns Requests sort defined in the table component
   */
  const createSortHandler =
    (property: keyof IEmailTemplateFields) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead
      sx={{
        backgroundColor: theme.palette.background.default,
        height: '40px',
      }}
    >
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={KeyboardArrowDownIcon}
              sx={{
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '13px',
                color: theme.palette.text.secondary,
                '&.Mui-active': {
                  color: theme.palette.text.secondary,
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="checkbox" />
      </TableRow>
    </TableHead>
  )
}

export default TableHeadTemplates
