import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { fetchAllCandidates } from '../api/candidate'
import CandidateListMobile from '../components/candidateListMobile/CandidateListMobile'
import CandTable from '../components/styledMisc/table/CandTable'
import { headCellsCandidates } from '../components/styledMisc/table/HeadCells'
import { LoadingOrError } from '../components/LoadingOrError'

/**
 * @returns The candidates page
 */
const Kandidater: React.FunctionComponent = () => {
  const {
    data: kandidater,
    isLoading,
    isError,
  } = useQuery(['kandidater'], fetchAllCandidates)

  return (
    <LoadingOrError isLoading={isLoading} isError={isError}>
      <main>
        <Typography
          sx={{ display: { xs: 'none', sm: 'block' } }}
          style={{
            fontWeight: 900,
            fontSize: '24px',
            color: '#33373D',
            padding: '1rem',
            margin: '0 auto',
            width: '80%',
          }}
        >
          Kandidater
        </Typography>
        {kandidater?.items && (
          <>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
              <CandidateListMobile candidates={kandidater.items} />
            </Box>
            <Box
              sx={{
                display: { xs: 'none', sm: 'block' },
                padding: '1rem',
                margin: '0 auto',
                width: '80%',
              }}
            >
              <CandTable
                candidates={kandidater.items}
                type="kandidat"
                headCells={headCellsCandidates}
              />
            </Box>
          </>
        )}
      </main>
    </LoadingOrError>
  )
}

export default Kandidater
