export const emailSignature = (
  lineOne: string,
  lineTwo: string,
  lineThree?: string
) => {
  return `
<!DOCTYPE html>
<html lang="en">
   <head>
      <title>Fink AS digital brevsignatur</title>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
   </head>
   <style>
      /* FONTS */
      @font-face {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: local('Nunito Sans Regular'), local('NunitoSans-Regular'), url(https://fonts.gstatic.com/s/nunitosans/v5/pe0qMImSLYBIv1o4X1M8cce9I90.woff) format('woff');
      }
      @font-face {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-display: swap;
      src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'), url(https://fonts.gstatic.com/s/nunitosans/v5/pe03MImSLYBIv1o4X1M8cc9iB85tU1c.woff) format('woff');
      }
      @font-face {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: local('Nunito Sans Bold'), local('NunitoSans-Bold'), url(https://fonts.gstatic.com/s/nunitosans/v5/pe03MImSLYBIv1o4X1M8cc8GBs5tU1c.woff) format('woff');
      }
   </style>
   <body id="body" role="Fink Email Signature" style="                font-family: 'Nunito Sans', sans-serif;                margin: 0 !important;                padding: 0 !important;">
    <div>
        <span style="font-weight: 900;font-size: 13px;line-height: 22px;color: #33373D;">${lineOne}</span><br/>
        <span style="font-size: 11px;line-height: 15px;color: #454D60;font-weight: 400;">${lineTwo}</span><br/>
        <span style="font-size: 11px;line-height: 15px;color: #454D60;font-weight: 400;">${lineThree}</span>
    </div>
    <a style="border:none;" href="https://www.fink.no" target="_blank" rel="noopener noreferer">
      <img style="height:66px" src="https://www.fink.no/images/fink_epost_v.png" alt="Fink Logo" />
    </a>
   </body>
</html>
`
}
