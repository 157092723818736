import Typography from '@mui/material/Typography'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { fetchUtlysninger } from '../api/api'
import OverviewTabbar from '../components/overviewtabbar/OverviewTabbar'
import { LoadingOrError } from '../components/LoadingOrError'

/**
 * @returns The job postings page
 */
const Utlysninger: React.FunctionComponent = () => {
  const {
    data: utlysninger,
    isLoading,
    isError,
  } = useQuery(['utlysninger'], fetchUtlysninger)

  return (
    <LoadingOrError isLoading={isLoading} isError={isError}>
      <main
        style={{
          padding: '1rem 0',
          margin: '0 auto',
          width: 'fit-content',
        }}
      >
        <Typography
          style={{
            fontWeight: 900,
            color: '#33373D',
            fontSize: '24px',
          }}
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          Utlysninger
        </Typography>
        {utlysninger?.items.map((utlysning) => (
          <div
            style={{ display: 'block', margin: '1rem 0' }}
            key={utlysning.sys.id}
          >
            {utlysning && <OverviewTabbar utlysning={utlysning} />}
          </div>
        ))}
      </main>
    </LoadingOrError>
  )
}

export default Utlysninger
