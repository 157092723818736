import { createClient } from 'contentful'

const space = process.env.REACT_APP_SPACE_ID
if (space === undefined || space === null)
  throw new Error('Missing contentful space id')
const accessToken = process.env.REACT_APP_ACCESS_TOKEN
if (accessToken === undefined || accessToken === null)
  throw new Error('Missing contentful access token')

const client = createClient({
  accessToken,
  space,
})

export default client
