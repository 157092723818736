import React from 'react'
import { makeStyles } from '@mui/styles'
import { Entry } from 'contentful'
import { IKandidatFields } from '../../schema/generated/contentful'
import { between7daysAnd14Days, lastSevenDays } from '../../utils/utils'

export interface JobApplicationInformationViewProps {
  deadlineDate?: string
  applicants: Entry<IKandidatFields>[] | undefined
}

// TODO: This method was used in Material UI v4 and should be updated to v5
const useStyles = makeStyles({
  jobApplicationInformationView: {
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },

  jobApplicationInformationViewLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },

  jobApplicationInformationViewRight: {
    display: 'flex',
  },

  jobApplicationInformationViewFont: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
    color: '#33373D',
  },

  percentagePositive: {
    color: '#00B87C',
  },

  percentageNegative: {
    color: '#FF0000',
  },
})

export const getNumberOfApplicantsLast7Days = (
  applicants?: { fields: { applicationDate: string } }[]
) => {
  return applicants
    ? applicants?.filter((cand) => lastSevenDays(cand.fields.applicationDate))
        .length
    : 0
}
export const getNumberOfApplicantsLast14Days = (
  applicants?: { fields: { applicationDate: string } }[]
) => {
  return applicants
    ? applicants?.filter((cand) =>
        between7daysAnd14Days(cand.fields.applicationDate)
      ).length
    : 0
}

export const getPercentageChangeFromLastWeek = (
  applicants?: { fields: { applicationDate: string } }[]
) => {
  const applicantsLast7Days = getNumberOfApplicantsLast7Days(applicants)
  const applicantsLast14Days = getNumberOfApplicantsLast14Days(applicants)
  if (applicantsLast14Days === 0) return 0
  return (applicantsLast7Days / applicantsLast14Days) * 100 - 100
}

/**
 * Information displayed at the bottom of each job posting overview
 * @param props - The component props, in this case how many active and total applicants the posting has, in addition to information about the posting's statistics
 * @returns A component displaying the information at the bottom of each job postings component
 */
const JobApplicationInformationView = ({
  deadlineDate,
  applicants,
}: JobApplicationInformationViewProps) => {
  const classes = useStyles()
  const applicantsLast7Days = getNumberOfApplicantsLast7Days(applicants)
  const percentageChangeFromLastWeek =
    getPercentageChangeFromLastWeek(applicants)
  const activeApplicants = applicants
    ? applicants.filter((candidate) => candidate.fields.stage !== 'Avslag')
        .length
    : 0
  const totalApplicants = applicants ? applicants.length : 0

  const deadlineDateStr =
    deadlineDate && deadlineDate.length
      ? new Date(deadlineDate).toLocaleDateString()
      : 'Ingen frist'
  const percentageStyling = () => {
    if (percentageChangeFromLastWeek === 0) return ''
    return percentageChangeFromLastWeek > 0
      ? classes.percentagePositive
      : classes.percentageNegative
  }
  return (
    <div className={classes.jobApplicationInformationView}>
      <div className={classes.jobApplicationInformationViewLeft}>
        <p className={classes.jobApplicationInformationViewFont}>
          Søkere siste 7 dagene: <strong>{applicantsLast7Days}</strong>
        </p>
        <p className={classes.jobApplicationInformationViewFont}>
          Endring siden forrige uke:{' '}
          <strong className={percentageStyling()}>
            {percentageChangeFromLastWeek.toFixed(0)}%
          </strong>
        </p>
        <p className={classes.jobApplicationInformationViewFont}>
          Aktive søkere: <strong>{activeApplicants}</strong>
        </p>
        <p className={classes.jobApplicationInformationViewFont}>
          Søkere totalt: <strong>{totalApplicants}</strong>
        </p>
      </div>
      <div className={classes.jobApplicationInformationViewRight}>
        <p className={classes.jobApplicationInformationViewFont} />
        &nbsp; &nbsp;
        <p className={classes.jobApplicationInformationViewFont}>
          <strong>Frist: </strong> {deadlineDateStr}
        </p>
      </div>
    </div>
  )
}

export default JobApplicationInformationView
