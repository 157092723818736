import { Entry } from 'contentful'
import * as React from 'react'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import DescriptionIcon from '@mui/icons-material/Description'

import FolderSpecialIcon from '@mui/icons-material/FolderSpecial'
import FilePresentIcon from '@mui/icons-material/FilePresent'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'

import { Box, Button, LinearProgress } from '@mui/material'
import { IKandidatFields } from '../../../schema/generated/contentful'
import { getAssetFromAPI } from '../../../api/api'
import './Application.css'

const addHTTPSToLinkIfNotThere = (url: string) => {
  if (url === undefined || url == null) return url
  if (url.includes('https://')) {
    return url
  }
  return `https://${url}`
}

interface ICurriculum {
  candidate: Entry<IKandidatFields>
  applicationTitle: string
}
function Curriculum(props: ICurriculum) {
  const { candidate, applicationTitle } = props

  const [loading, setLoading] = React.useState(true)
  const [selectedTab, setSelectedTab] = React.useState(0)
  const [fileURLCV, setFileURLCV] = React.useState<string | undefined>()
  const [fileURLOther, setFileURLOther] = React.useState<string | undefined>()
  const [fileURLTranscript, setFileURLTranscript] = React.useState<
    string | undefined
  >()
  const [fileURLPortfolio, setFileURLPortfolio] = React.useState<
    string | undefined
  >()

  const [fileURLAppLetter, setFileURLAppLetter] = React.useState<
    string | undefined
  >()

  React.useEffect(() => {
    const cv = candidate.fields.cv?.sys.id
    const applicationLetter = candidate.fields.applicationLetter?.sys.id
    const portfolio = candidate.fields.portfolio?.sys.id
    const other = candidate.fields.other?.sys.id
    const transcript = candidate.fields.transcript?.sys.id
    if (
      (cv || applicationLetter || portfolio || other || transcript) &&
      loading
    ) {
      const f$ = []
      if (cv) {
        f$.push(
          getAssetFromAPI(candidate.fields.cv.sys.id).then((res) => {
            return { type: 'CV', res }
          })
        )
      }
      if (applicationLetter) {
        f$.push(
          getAssetFromAPI(candidate.fields.applicationLetter.sys.id).then(
            (res) => {
              return { type: 'ApplicationLetter', res }
            }
          )
        )
      }
      if (portfolio) {
        f$.push(
          getAssetFromAPI(candidate.fields.portfolio.sys.id).then((res) => {
            return { type: 'Portfolio', res }
          })
        )
      }

      if (transcript) {
        f$.push(
          getAssetFromAPI(candidate.fields.transcript.sys.id).then((res) => {
            return { type: 'Transcript', res }
          })
        )
      }

      if (other) {
        f$.push(
          getAssetFromAPI(candidate.fields.other.sys.id).then((res) => {
            return { type: 'Other', res }
          })
        )
      }

      Promise.all(f$).then((result) => {
        result.forEach((response) => {
          switch (response.type) {
            case 'CV':
              setFileURLCV(response.res.fields.file.url)
              break
            case 'ApplicationLetter':
              setFileURLAppLetter(response.res.fields.file.url)
              break
            case 'Portfolio':
              setFileURLPortfolio(response.res.fields.file.url)
              break
            case 'Transcript':
              setFileURLTranscript(response.res.fields.file.url)
              break
            case 'Other':
              setFileURLOther(response.res.fields.file.url)
              break
            default:
              break
          }
        })
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [])

  if (loading) {
    return <LinearProgress />
  }

  let tabContent = (
    <embed
      src={fileURLAppLetter}
      style={{ height: 'calc(100% - 1px)', width: '100%' }}
      type="application/pdf"
    />
  )

  if (!fileURLAppLetter) {
    tabContent = (
      <div className="noContent">Bruker har ikke lastet opp søknadsbrev</div>
    )
  }

  if (selectedTab === 0) {
    tabContent = (
      <embed
        src={fileURLCV}
        style={{ height: 'calc(100% - 1px)', width: '100%' }}
        type="application/pdf"
      />
    )
    if (!fileURLCV) {
      tabContent = (
        <div className="noContent">Bruker har ikke lastet opp CV.</div>
      )
    }
  }

  if (selectedTab === 2) {
    tabContent = (
      <>
        {candidate.fields.portfolioLink && (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Bruker har lastet opp link til portefølje:
            <br />
            Link til portfolio:
            <a
              href={addHTTPSToLinkIfNotThere(candidate.fields.portfolioLink)}
              target="_blank"
              rel="noreferrer"
            >
              {addHTTPSToLinkIfNotThere(candidate.fields.portfolioLink)}
            </a>
            <br />
          </Box>
        )}

        {fileURLPortfolio && (
          <embed
            src={fileURLPortfolio}
            style={{ height: 'calc(100% - 1px)', width: '100%' }}
            type="application/pdf"
          />
        )}
      </>
    )
  }

  if (selectedTab === 3) {
    tabContent = (
      <>
        <embed
          src={fileURLTranscript}
          style={{ height: 'calc(100% - 1px)', width: '100%' }}
          type="application/pdf"
        />
      </>
    )
    if (!fileURLTranscript) {
      tabContent = (
        <div className="noContent">
          Bruker har ikke lastet opp karaketutskrift.
        </div>
      )
    }
  }

  if (selectedTab === 4) {
    tabContent = (
      <>
        <embed
          src={fileURLOther}
          style={{ height: 'calc(100% - 1px)', width: '100%' }}
          type="application/pdf"
        />
      </>
    )
    if (!fileURLOther) {
      tabContent = (
        <div className="noContent">
          Bruker har ikke lastet opp noen andre vedlegg.
        </div>
      )
    }
  }

  return (
    <>
      <div className="cstTabContainer">
        <div style={{ flex: '1', lineHeight: '48px' }}>
          <strong>Søknad - {applicationTitle}</strong> &nbsp;&nbsp;&nbsp;&nbsp;{' '}
          {/* <span style={{ fontSize: '.9em' }}>
            Mottatt for {receivedDaysAgo}.
          </span> */}
        </div>
        {candidate.fields.cv && (
          <div
            className={`cstmTab ${selectedTab !== 0 ? 'cstmTabDisabled' : ''}`}
          >
            <Button onClick={() => setSelectedTab(0)}>
              <AssignmentIndIcon />
              &nbsp; CV
            </Button>
          </div>
        )}
        {candidate.fields.applicationLetter && (
          <div
            className={`cstmTab ${selectedTab !== 1 ? 'cstmTabDisabled' : ''}`}
          >
            <Button onClick={() => setSelectedTab(1)}>
              <DriveFileRenameOutlineIcon />
              &nbsp; Søknadsbrev
            </Button>
          </div>
        )}
        {(candidate.fields.portfolio || candidate.fields.portfolioLink) && (
          <div
            className={`cstmTab ${selectedTab !== 2 ? 'cstmTabDisabled' : ''}`}
          >
            <Button onClick={() => setSelectedTab(2)}>
              <FolderSpecialIcon />
              &nbsp; Portfolio
            </Button>
          </div>
        )}

        {candidate.fields.transcript && (
          <div
            className={`cstmTab ${selectedTab !== 3 ? 'cstmTabDisabled' : ''}`}
          >
            <Button onClick={() => setSelectedTab(3)}>
              <DescriptionIcon />
              &nbsp; Karakterer
            </Button>
          </div>
        )}

        {candidate.fields.other && (
          <div
            className={`cstmTab ${selectedTab !== 4 ? 'cstmTabDisabled' : ''}`}
          >
            <Button onClick={() => setSelectedTab(4)}>
              <FilePresentIcon />
              &nbsp; Annet
            </Button>
          </div>
        )}
      </div>
      <div className="cstmTabContent">{tabContent}</div>
    </>
  )
}

export default Curriculum
