import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { Button, CircularProgress } from '@mui/material'
import { green, red } from '@mui/material/colors'
import { Box } from '@mui/system'
import { Entry } from 'contentful'
import * as React from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  sendCandidateToNextStage,
  sendRejection,
  setMailSent,
} from '../../api/api'

import { IJobapplicationContentFields } from '../../schema/generated/contentful'
import { useStore } from '../../store/store'
import AlertAction, { AlertTypes } from '../Alert/AlertAction'
import { AlertContext } from '../Alert/AlertContext'
import DarkTooltip from '../styledMisc/Tooltip'
import SendMailDialog, {
  SEND_MAIL_DLG_CONTEXT,
} from './sendMail/SendMailDialog'

/**
 * ListActions props
 */
type ListActionsProps = {
  // Whether or not the buttons are disabled
  disabled?: boolean
  // Which candidate the options will be applied to
  candidateIDs: string[]
  // Which job posting the candidate has applied to
  utlysning: Entry<IJobapplicationContentFields>
  // To reset selected candidates when moving candidates to other stages
  setSelected: React.Dispatch<React.SetStateAction<readonly string[]>>
}

/**
 *
 * @param param0 - the ListActions props
 * @returns Three buttons at the rightmost column of each candidate row, where a candidate can be sent to the next stage, sent an email, or a rejection
 */
export const ListActions: React.FunctionComponent<ListActionsProps> = (
  props: ListActionsProps
) => {
  const [, dispatch] = React.useContext(AlertContext)

  const queryClient = useQueryClient()
  const { disabled, candidateIDs, utlysning, setSelected } = props
  const currentStage = useStore((state) => state.currentStage)
  const [loadingNextStage, setLoadingNextStage] = React.useState(false)
  const [loadingRejection, setLoadingRejection] = React.useState(false)
  const [toggleMailDialog, setToggleMailDialog] = React.useState(false)
  const [mailDlgContext, setMailDlgContext] = React.useState(
    SEND_MAIL_DLG_CONTEXT.REJECTION
  )

  /**
   * Mutates the candidate to the next stage
   */
  const nextStageMutation = useMutation(
    (candidateID: string) => sendCandidateToNextStage(candidateID, utlysning),
    {
      onError: async (err, variables) => {
        dispatch(
          AlertAction.showAlert(
            `Det oppstod en feil under flytting av kandidaten: "${variables}" til neste steg.`,
            AlertTypes.error
          )
        )
      },
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(['utlysning', utlysning.sys.id])
        dispatch(
          AlertAction.showAlert(
            `Kandidaten: "${variables}" ble sendt til neste steg.`,
            AlertTypes.success
          )
        )
      },
    }
  )

  /**
   * Mutates the candidate to the 'rejection' stage
   */
  const rejectionMutation = useMutation(
    (candidateID: string) => sendRejection(candidateID),
    {
      onMutate: () => {
        queryClient.cancelQueries(['utlysning', utlysning.sys.id])
      },
      onError: (err, variables) => {
        dispatch(
          AlertAction.showAlert(
            `Det oppstod en feil under flytting av kandidaten: "${variables}" til Avslag.`,
            AlertTypes.error
          )
        )
      },
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(['utlysning', utlysning.sys.id])

        dispatch(
          AlertAction.showAlert(
            `Kandidaten: "${variables}" ble flyttet til Avslag.`,
            AlertTypes.success
          )
        )
      },
    }
  )

  /**
   * Mutates the candidate, set email sent
   */
  const emailSentMutation = useMutation(
    (candidateID: string) => setMailSent(candidateID),
    {
      onError: (err, variables) => {
        dispatch(
          AlertAction.showAlert(
            `Det oppstod en feil under sending av epost til kandidaten: "${variables}".`,
            AlertTypes.error
          )
        )
      },
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(['utlysning', utlysning.sys.id])
        dispatch(
          AlertAction.showAlert(
            `Email ble sendt til kandidaten: "${variables}".`,
            AlertTypes.success
          )
        )
      },
    }
  )

  /**
   * Sends the applicant to the next stage.
   * Sets loading to be true, and after the muation has settled, changes the loading to false again
   */
  const applyNextStage = () => {
    setLoadingNextStage(true)
    const p$ = candidateIDs.map((candidateID: string) => {
      return nextStageMutation.mutateAsync(candidateID)
    })
    Promise.all(p$).then(() => {
      setSelected([])
      setLoadingNextStage(false)
    })
  }

  /**
   * Sends the applicant to the 'rejection' stage.
   * Sets loading to be true, and after the muation has settled, changes the loading to false again
   */
  const applyRejection = () => {
    setLoadingRejection(true)
    const p$ = candidateIDs.map((candidateID: string) => {
      return rejectionMutation.mutateAsync(candidateID)
    })
    Promise.all(p$)
      .then(() => {
        setSelected([])
        setLoadingRejection(false)
        dispatch(
          AlertAction.showAlert(
            'Kandidatene ble sendt til avslag.',
            AlertTypes.success
          )
        )
      })
      .catch((err) => {
        console.log('Error sending people to avslag stage', err)
        dispatch(
          AlertAction.showAlert(
            'Det oppstod en feil under sending av kandidater til avslag',
            AlertTypes.error
          )
        )
      })
  }

  const handleSendMailClose = (
    res?:
      | undefined
      | {
          name: string
          candidateId: string
          emailSent: boolean
          email: string
        }[]
  ) => {
    setToggleMailDialog(false)
    if (res) {
      const mailSent$ = res
        ?.filter((r) => r.emailSent === true)
        .map((c) => {
          return emailSentMutation.mutateAsync(c.candidateId)
        })
      if (mailSent$) {
        Promise.all(mailSent$).then(() => {
          if (mailDlgContext === SEND_MAIL_DLG_CONTEXT.REJECTION) {
            applyRejection()
          }
        })
      }
    }
  }

  /**
   * Buttons for "Send to next step" and "Rejection/Avslag" is disabled if the candidate is in step "Avslag".
   * A candidate should not be able to be sent to the next stage or rejecton if they have already been sent a rejection.
   */
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          border: '1px solid #A6A6A6',
          borderRadius: '4px',
        }}
      >
        <DarkTooltip title="Send til neste steg" arrow>
          <>
            <Button
              disabled={
                disabled || loadingNextStage || currentStage === 'Avslag'
              }
              variant="listActionSuccess"
              sx={{
                borderRadius: '4px 0 0 4px',
              }}
              onClick={applyNextStage}
            >
              {loadingNextStage ? (
                <CircularProgress
                  size={12}
                  sx={{
                    color: green[500],
                  }}
                />
              ) : (
                <ArrowForwardIcon />
              )}
            </Button>
          </>
        </DarkTooltip>
        <DarkTooltip title="Send mail" arrow>
          <>
            <Button
              disabled={disabled}
              variant="listActionInfo"
              sx={{
                borderRight: '1px solid #A6A6A6',
                borderLeft: '1px solid #A6A6A6',
              }}
              onClick={() => {
                setMailDlgContext(SEND_MAIL_DLG_CONTEXT.SIMPLE)

                setToggleMailDialog(true)
              }}
            >
              <MailOutlineIcon sx={{ fontSize: 20, padding: '0.2rem' }} />
            </Button>
          </>
        </DarkTooltip>
        <DarkTooltip title="Send avslag" arrow>
          <>
            <Button
              disabled={
                disabled || loadingRejection || currentStage === 'Avslag'
              }
              variant="listActionError"
              sx={{
                borderRadius: '0 4px 4px 0',
              }}
              onClick={() => {
                setMailDlgContext(SEND_MAIL_DLG_CONTEXT.REJECTION)
                setToggleMailDialog(true)
              }}
            >
              {loadingRejection ? (
                <CircularProgress
                  size={12}
                  sx={{
                    color: red[500],
                  }}
                />
              ) : (
                <CloseIcon />
              )}
            </Button>
          </>
        </DarkTooltip>
      </Box>
      <SendMailDialog
        open={toggleMailDialog}
        candidatesIds={candidateIDs}
        handleSendMailClose={handleSendMailClose}
        context={mailDlgContext}
      />
    </>
  )
}
