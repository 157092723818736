/* eslint-disable no-param-reassign */
import axios from 'axios'
import { INote } from '../interfaces/note.interface'
import { checkIfTwoObjectsAreEqual } from '../utils/utils'
import { writableClient } from '../writableClient'
import { BASE_URL } from './api'

/**
 * Gets all notes from a specific candidate
 * @param id - The id of the candidate
 * @returns The notes written about the candidate
 */
export const getCandidateNotes = async (id: string): Promise<INote[]> => {
  const { data } = await axios.get(
    `${BASE_URL}/entries/${id}?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`
  )

  return data.fields.notes
}

/**
 * Add a new note to a candidate
 * @param candidateID - The candidate who a new note is written about
 * @param newNote - The new note to append to the candidate notes
 */
export async function newCandidateNote(
  candidateID: string,
  newNote: INote
): Promise<void> {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(candidateID))
    .then((entry) => {
      if (!entry.fields.notes) {
        entry.fields.notes = {}
      }
      const oldNotes = entry.fields.notes['en-US'] ?? []
      entry.fields.notes['en-US'] = [...oldNotes, newNote]
      return entry.update()
    })
    .then((entry) => entry.publish())
}

/**
 * Removes a note from a candidate
 * @param candidateID - The candidate who should have a note removed
 * @param noteToRemove - The note that should be removed
 */
export async function removeCandidateNote(
  candidateID: string,
  newNote: INote
): Promise<void> {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(candidateID))
    .then((entry) => {
      if (!entry.fields.notes) {
        entry.fields.notes = {}
      }
      const oldNotes = entry.fields.notes['en-US'] ?? []
      entry.fields.notes['en-US'] = oldNotes.filter(
        (note: INote) => !checkIfTwoObjectsAreEqual(note, newNote)
      )
      return entry.update()
    })
    .then((entry) => entry.publish())
}

/**
 * Removes a note from a candidate
 * @param candidateID - The candidate who should have a note removed
 * @param noteToRemove - The note that should be removed
 */
export async function editCandidateNote(
  candidateID: string,
  newNote: INote,
  oldNote: INote
): Promise<void> {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(candidateID))
    .then((entry) => {
      if (!entry.fields.notes) {
        throw new Error("Couldn't find any notes")
      }
      const oldNotes = entry.fields.notes['en-US'] ?? []
      const indexOfNote = oldNotes.findIndex((note: INote) =>
        checkIfTwoObjectsAreEqual(note, oldNote)
      )
      if (indexOfNote === -1) {
        throw new Error("Couldn't find the note to edit")
      }
      entry.fields.notes['en-US'][indexOfNote] = newNote
      return entry.update()
    })
    .then((entry) => entry.publish())
}

/**
 * Set to mail sent for candidate
 * @param candidateID - The id of the candidate
 * NOT DONE. Not able to create a new note, the update is not correct.
 */
export async function saveNote(
  candidateID: string,
  note: string
): Promise<void> {
  await writableClient
    .getSpace(`${process.env.REACT_APP_SPACE_ID}`)
    .then((space) => space.getEnvironment('master'))
    .then((environment) => environment.getEntry(candidateID))
    .then((entry) => {
      entry.fields.notes['en-US'].content[0].content[0].value = note
      return entry.update()
    })
    .then((entry) => entry.publish())
}
