import AlertAction from './AlertAction'
import { IAlertState } from './AlertContext'

const AlertReducer = (state: IAlertState, action: any) => {
  switch (action.type) {
    case AlertAction.SHOW_ALERT:
      return {
        ...state,
        alertShower: action.payload,
      }
    default:
      return state
  }
}

export default AlertReducer
