import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Autocomplete from '@mui/material/Autocomplete'
import { Entry } from 'contentful'
import { IKandidatFields } from '../../../schema/generated/contentful'
import { getResponsiblePersons } from '../../../api/employees'

function ResponsiblePersonDlg(props: {
  candidate: Entry<IKandidatFields>
  open: boolean
  close(): void
  save(args: { name: string | null; email: string | null }): void
}) {
  const { candidate, open, close, save } = props

  const [responsiblePerson, setResponsiblePerson] = React.useState('')
  const [somethingChanged, setSomethingChanged] = React.useState(false)
  const [value, setValue] = React.useState<{
    name: string
    email: string
  } | null>()

  const [data, setData] = React.useState<{ name: string; email: string }[]>([])

  const [loading, setLoading] = React.useState(true)

  // TODO: Remove useEffect and use querymutation?
  React.useEffect(() => {
    if (loading) {
      getResponsiblePersons().then((responsiblePersonsList) => {
        const none = {
          name: 'Ingen saksbehandler',
          email: 'none',
        }
        setData(responsiblePersonsList)
        if (candidate.fields?.responsiblePerson) {
          const resp = candidate.fields.responsiblePerson
          const currentValue = responsiblePersonsList.find(
            (r) => r.name === resp
          )
          setResponsiblePerson(resp)
          setValue(currentValue)
        }

        setLoading(false)
      })
    }
  }, [loading])

  if (loading) {
    return <div />
  }
  let autocomplete
  if (!responsiblePerson) {
    autocomplete = (
      <Autocomplete
        onChange={(event, newInputValue) => {
          setValue(newInputValue)
          setSomethingChanged(true)
        }}
        options={data}
        sx={{ width: '100%' }}
        renderInput={(params) => (
          /* eslint-disable react/jsx-props-no-spreading */
          <TextField {...params} label="Saksbehandler" />
        )}
      />
    )
  } else {
    autocomplete = (
      <Autocomplete
        value={value}
        onChange={(event, newInputValue) => {
          setValue(newInputValue)
          setSomethingChanged(true)
        }}
        options={data}
        sx={{ width: '100%' }}
        renderInput={(params) => (
          /* eslint-disable react/jsx-props-no-spreading */
          <TextField {...params} label="Saksbehandler" />
        )}
      />
    )
  }
  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>Sett saksbehandler</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <br />
            Her kan du sette saksbehandler. Saksbehandler er den som har
            ansvaret for kandidaten. Hvis du endrer saksbehandler så husk å gi
            beskjed til den nye personen. Det er dessverre ingen automatisk
            varsling på dette enda.
          </DialogContentText>
          <br />
          <br />
          {autocomplete}
        </DialogContent>
        <DialogActions>
          <Button variant="greyDefault" onClick={close}>
            Avbryt
          </Button>
          <Button
            disabled={!somethingChanged}
            variant="darkBlueRounded"
            onClick={() => {
              save({
                name: value?.name || null,
                email: value?.email || null,
              })
              setSomethingChanged(false)
            }}
          >
            Lagre
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ResponsiblePersonDlg
