import { TextField } from '@mui/material'
import { Entry } from 'contentful'
import { v4 as uuidv4 } from 'uuid'
import * as React from 'react'
import { IJobapplicationContentFields } from '../../../schema/generated/contentful'
import { Tag } from '../../tag/Tag'
import { ISearchFilter } from '../UtlysningView'
import './SearchBar.css'
import ExperienceSelector from './ExperienceSelector'
import TagSelector from './TagsSelector'

interface SearchBarProps {
  utlysning: Entry<IJobapplicationContentFields>
  handleFilter(filter: ISearchFilter): void
  filter: ISearchFilter
}

const SearchBar: React.FunctionComponent<SearchBarProps> = ({
  utlysning,
  handleFilter,
  filter,
}: SearchBarProps) => {
  const handleSelectedTags = (tags: string[]) => {
    handleFilter({
      ...filter,
      tags,
    })
  }

  const handleRemoveTag = (tag: string) => {
    const tags = filter.tags?.slice()
    const index = tags.findIndex((t) => t === tag)
    tags.splice(index, 1)
    handleFilter({
      ...filter,
      tags,
    })
  }

  const handleYearsExperience = (years: number) => {
    handleFilter({
      ...filter,
      minimumYearsExperience: years,
    })
  }

  const tags = filter.tags?.map((tag) => {
    return (
      <Tag
        tag={tag}
        handleRemoveTag={handleRemoveTag}
        key={`${tag}-${uuidv4}`}
      />
    )
  })

  return (
    <div style={{ width: '100%', paddingBottom: '32px' }}>
      <div style={{ position: 'relative' }}>
        <div className="tagSelectorWrapper">
          <TagSelector
            tags={utlysning.fields.tags}
            changed={handleSelectedTags}
            selectedTags={filter.tags}
          />
          <ExperienceSelector changed={handleYearsExperience} />
        </div>
        <TextField
          autoComplete="off"
          className="searchBar"
          id="searchBox"
          placeholder="Søk..."
          type="handleSearch"
          size="medium"
          onChange={(e) => {
            handleFilter({
              ...filter,
              searchValue: e.currentTarget.value,
            })
          }}
        />
      </div>
      <div style={{ padding: '8px 0', height: '16px' }}>{tags}</div>
    </div>
  )
}
export default SearchBar
